import React, { ReactElement } from 'react';
import ConfigInputProps from '~/components/ConfigInput/components/ConfigInputProps.type';
import ToggleInput from '~/components/ConfigInput/components/ToggleInput';
import SelectInput from '~/components/ConfigInput/components/SelectInput';

const renderItems = (props: ConfigInputProps): ReactElement => {
    const types = {
        select: <SelectInput {...props} />,
        toggle: <ToggleInput {...props} />,
        multiselect: <SelectInput {...props} multiple />,
        db: <SelectInput {...props} />
    };

    return types[props.type];
};

const ConfigInput: React.FC<ConfigInputProps> = (props): ReactElement =>
    renderItems(props);

export default ConfigInput;
