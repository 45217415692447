import React, { useContext, useState } from 'react';
import { Button, Typography, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import qs from 'query-string';
import { AlignType } from 'rc-table/lib/interface';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import { useTranslation } from 'react-i18next';
import { LabeledValue } from 'antd/es/select';
import { AxiosError } from 'axios';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import filterInput from '~/components/Table/components/filterInput';
import filterDateRange from '~/components/Table/components/filterDateRange';
import ContentService from '~/services/content-service';
import { Document } from '~/types/services/content-service';
import filterMasterData from '~/components/Table/components/filterMasterData';
import filterTaxonomy from '~/components/Table/components/filterTaxonomy';
import MasterDataEnums from '~/types/shared/master-data-enums';
import { ConfiguratorContext } from '~/shared/context/configurator';
import filterAjaxSelect from '~/components/Table/components/filterAjaxSelect';
import UserService from '~/services/user-service';
import BuilderFieldNameEnums from '~/types/shared/builder-field-name-enums';
import getSelectedDocumentGroup from '~/shared/helpers/document-group';
import { PermissionContext } from '~/shared/context/permission';
import PermissionGate from '~/containers/PermissionGate';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import PermissionKeysEnums from '~/types/shared/permission-keys-enums';
import ContentPermissionGate from '~/containers/PermissionGate/ContentPermissionGate';
import canAddNewButton, {
    canDeleteButton
} from '~/shared/helpers/action-buttons';
import Spinner from '~/components/Spinner';
import ToastrService from '~/services/toastr-service';

const contentService = new ContentService();
const userService = new UserService();

const DocumentListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const history = useHistory();
    const { promiseInProgress: contentLoading } = usePromiseTracker({
        area: ServiceEnums.Content
    });
    const { promiseInProgress: configLoading } = usePromiseTracker({
        area: ServiceEnums.Configurator
    });
    const [documents, setDocuments] = useState([]);
    const [total, setTotal] = useState(0);
    const queryParams = qs.parse(history.location.search);
    const configurations = useContext(ConfiguratorContext);
    const { permissions, userHasPermission, permissionLoading } = useContext(
        PermissionContext
    );
    if (permissionLoading) return <Spinner />;
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const selectedDocumentGroup = getSelectedDocumentGroup(
        history.location.pathname
    );

    const documentArchiveConfig = get(
        configurations.getConfig(ConfiguratorEnums.ContentArchiveConfigKey),
        'value[0].name'
    );

    let title;
    let typeFilterKeys = [];

    switch (selectedDocumentGroup) {
        case MasterDataEnums.ContentGroupSpecification:
            title = t('pages.content.list.specificationTitle');
            typeFilterKeys = ['document_specification_type'];
            break;
        case MasterDataEnums.ContentGroupOther:
            title = t('pages.content.list.otherTitle');
            typeFilterKeys = ['document_other_type'];
            break;
        default:
            title = t('pages.content.list.title');
            typeFilterKeys = [
                'document_specification_type',
                'document_other_type'
            ];
    }

    const fetchDocuments = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        contentService
            .fetchDocuments(
                queryParams.taxonomyFilterId
                    ? {
                          ...filters,
                          groupFilter:
                              selectedDocumentGroup === 'documents'
                                  ? null
                                  : selectedDocumentGroup,
                          taxonomy_id: [queryParams.taxonomyFilterId]
                      }
                    : {
                          ...filters,
                          groupFilter:
                              selectedDocumentGroup === 'documents'
                                  ? null
                                  : selectedDocumentGroup
                      },
                orders,
                page
            )
            .then(res => {
                setDocuments(res.data);
                setTotal(res.total);
            });
    };

    const exportDocuments = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        contentService.exportDocuments(
            queryParams.taxonomyFilterId
                ? {
                      ...filters,
                      taxonomy_id: [queryParams.taxonomyFilterId],
                      groupFilter:
                          selectedDocumentGroup === 'documents'
                              ? null
                              : selectedDocumentGroup
                  }
                : {
                      ...filters,
                      groupFilter:
                          selectedDocumentGroup === 'documents'
                              ? null
                              : selectedDocumentGroup
                  },
            orders
        );
    };

    const handleGroupDelete = (selectedRows): void => {
        contentService
            .deleteDocuments(selectedRows)
            .then(() => fetchDocuments())
            .catch((error: AxiosError) => {
                if (error.response.status === 403) {
                    ToastrService.error(
                        t('pages.content.edit.noDeletePermission')
                    );
                }
            });
    };

    const showDeleteConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'pages.content.list.confirmArchivesTitle'
                    : 'pages.content.list.confirmDeleteTitle'
            ),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.content.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const showArchivateConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t('pages.content.list.confirmArchivateTitle'),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const isDeletable = (contentStatus): boolean =>
        (contentStatus === MasterDataEnums.ContentStatusArchived &&
            documentArchiveConfig ===
                ConfiguratorEnums.ContentArchiveAndDelete) ||
        documentArchiveConfig === ConfiguratorEnums.ContentOnlyDeletable;

    const actions: ActionProps[] = [
        canDeleteButton(
            userPermissionNames,
            t,
            push,
            selectedDocumentGroup,
            documentArchiveConfig,
            showDeleteConfirm
        ),
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportDocuments
        },
        canAddNewButton(userPermissionNames, t, push, selectedDocumentGroup)
    ];

    const columns = [
        {
            title: t('pages.content.fields.group'),
            key: 'group_id',
            sorter: true,
            show: selectedDocumentGroup === 'documents',
            render: (data: Document): string => get(data, 'group.label'),
            ...filterMasterData(ServiceEnums.Content, 'document_group')
        },
        {
            title: t('pages.content.fields.type'),
            key: 'type_id',
            sorter: true,
            show: true,
            render: (data: Document): string => get(data, 'type.label'),
            ...filterMasterData(ServiceEnums.Content, typeFilterKeys)
        },
        {
            title: t('pages.content.fields.code'),
            key: 'code',
            sorter: true,
            show: true,
            render: (data: Document): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(BuilderFieldNameEnums.DocumentCode) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.title'),
            dataIndex: 'title',
            sorter: true,
            show: true,
            ...filterInput
        },
        {
            title: t('pages.content.fields.status'),
            key: 'status_id',
            sorter: true,
            show: true,
            render: (data: Document): string => get(data, 'status.label'),
            ...filterMasterData(ServiceEnums.Content, 'document_status')
        },
        {
            title: t('pages.content.fields.category'),
            key: 'taxonomy_id',
            sorter: true,
            show: true,
            render: (data: Document): string => get(data, 'taxonomy.name'),
            ...filterTaxonomy(
                ServiceEnums.Content,
                'document_taxonomy',
                queryParams.taxonomyFilterName
                    ? queryParams.taxonomyFilterName
                    : null,
                !!queryParams.taxonomyFilterName
            )
        },
        {
            title: t('pages.content.fields.owners'),
            key: 'owners',
            sorter: false,
            show: true,
            render: (data: Document): string =>
                data.owners.length
                    ? data.owners
                          .map(
                              owner => `${owner.first_name} ${owner.last_name}`
                          )
                          .join(', ')
                    : '-',
            ...filterAjaxSelect(
                (value): Promise<LabeledValue[]> =>
                    userService
                        .fetchUsers({ first_name: value }, {}, 0)
                        .then(res =>
                            res.data.map(user => ({
                                value: user.id,
                                label: `${user.first_name} ${user.last_name}`
                            }))
                        )
            )
        },
        {
            title: t('pages.content.fields.startOfValidity'),
            dataIndex: 'valid_from',
            sorter: true,
            show: true,
            render: (text): string =>
                text
                    ? moment(text)
                          .format('L LT')
                          .toString()
                    : '-',
            ...filterDateRange
        },
        {
            title: t('pages.content.fields.endOfValidity'),
            dataIndex: 'valid_to',
            sorter: true,
            show: true,
            render: (text): string =>
                text
                    ? moment(text)
                          .format('L LT')
                          .toString()
                    : '-',
            ...filterDateRange
        },
        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            show: true,
            render: (text, record): React.ReactNode => (
                <ActionButtonWrapper>
                    <PermissionGate
                        permission={userPermissionNames}
                        permissionRoute={[
                            PermissionKeysEnums.DocumentFull,
                            PermissionKeysEnums.Egyeb,
                            PermissionKeysEnums.Eloiras,
                            PermissionKeysEnums.Mubizonylat
                        ]}
                    >
                        {isDeletable(record.status.key) && (
                            <ContentPermissionGate
                                permission={userPermissionNames}
                                permissionRoute={[
                                    PermissionKeysEnums.DocumentFull,
                                    PermissionKeysEnums.Egyeb,
                                    PermissionKeysEnums.Eloiras,
                                    PermissionKeysEnums.Mubizonylat
                                ]}
                                data={record}
                            >
                                <Button
                                    danger
                                    onClick={(): void =>
                                        showDeleteConfirm([record.id])
                                    }
                                >
                                    {t('basic.deleteButton')}
                                </Button>
                            </ContentPermissionGate>
                        )}
                        {record.status.key ===
                            MasterDataEnums.ContentStatusActive &&
                            documentArchiveConfig !==
                                ConfiguratorEnums.ContentOnlyDeletable && (
                                <ContentPermissionGate
                                    permission={userPermissionNames}
                                    permissionRoute={[
                                        PermissionKeysEnums.DocumentFull,
                                        PermissionKeysEnums.Egyeb,
                                        PermissionKeysEnums.Eloiras,
                                        PermissionKeysEnums.Mubizonylat
                                    ]}
                                    data={record}
                                >
                                    <Button
                                        danger
                                        onClick={(): void =>
                                            showArchivateConfirm([record.id])
                                        }
                                    >
                                        {t(
                                            'pages.content.list.archivateButton'
                                        )}
                                    </Button>
                                </ContentPermissionGate>
                            )}
                        <ContentPermissionGate
                            permission={userPermissionNames}
                            permissionRoute={[
                                PermissionKeysEnums.DocumentFull,
                                PermissionKeysEnums.Egyeb,
                                PermissionKeysEnums.Eloiras,
                                PermissionKeysEnums.Mubizonylat
                            ]}
                            data={record}
                        >
                            <Link
                                to={`/content/${selectedDocumentGroup}/${record.id}`}
                            >
                                <Button>{t('basic.editButton')}</Button>
                            </Link>
                        </ContentPermissionGate>
                    </PermissionGate>
                    <Link to={`/content/documents/view/${record.id}`}>
                        <Button>{t('basic.viewButton')}</Button>
                    </Link>
                </ActionButtonWrapper>
            )
        }
    ];

    return (
        <div>
            <Typography.Title level={1}>{title}</Typography.Title>
            <Table
                rowKey="id"
                loading={contentLoading || configLoading}
                columns={columns.filter(column => column.show)}
                dataSource={documents}
                actions={
                    userHasPermission(userPermissionNames, [
                        PermissionKeysEnums.DocumentFull,
                        PermissionKeysEnums.Mubizonylat,
                        PermissionKeysEnums.Egyeb,
                        PermissionKeysEnums.Eloiras
                    ])
                        ? actions
                        : []
                }
                fetchSource={fetchDocuments}
                total={total}
                globalSearchable
            />
        </div>
    );
};

export default DocumentListPage;
