import huHU from 'antd/lib/locale/hu_HU';
import enUS from 'antd/lib/locale/en_US';
import { Locale } from 'antd/lib/locale-provider';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import datePickerHu from 'antd/lib/date-picker/locale/hu_HU';
import datePickerEn from 'antd/lib/date-picker/locale/en_US';

import i18nHuLocal from '~/shared/translations/hu/translation';
import i18nEnLocal from '~/shared/translations/en/translation';

export interface Language {
    key: string;
    label: string;
    antd: Locale;
    datePicker: PickerLocale;
    i18n: object;
}

const languages: Language[] = [
    {
        key: 'hu-HU',
        label: 'HU',
        antd: huHU,
        i18n: i18nHuLocal,
        datePicker: datePickerHu
    },
    {
        key: 'en-US',
        label: 'EN',
        antd: enUS,
        i18n: i18nEnLocal,
        datePicker: datePickerEn
    }
];

export default languages;
