import { Form } from 'formik-antd';
import React from 'react';
import { LabeledValue, SelectValue } from 'antd/es/select';
import AjaxSelect from '~/components/AjaxSelect';
import UserService from '~/services/user-service';
import i18n from '~/shared/utils/i18n';

const userService = new UserService();

const onSearch = (value): Promise<LabeledValue[]> =>
    userService.fetchUsers({ first_name: value }, {}, 0).then(res =>
        res.data.map(user => ({
            value: user.id,
            label: `${user.last_name} ${user.first_name}`
        }))
    );

interface OwnerSelectorProps {
    name: string;
    label: string;
    setFieldValue: Function;
    data: LabeledValue[];
    initialValues: SelectValue;
    disabled?: boolean;
}

const OwnerSelector: React.FC<OwnerSelectorProps> = ({
    name,
    label,
    setFieldValue,
    data,
    initialValues,
    disabled
}) => (
    <Form.Item name={name} label={label}>
        <AjaxSelect
            id={name}
            placeholder={i18n.t('basic.selectWithLabel', { label })}
            initialValues={initialValues}
            onSearch={onSearch}
            onSelect={(res): void => {
                setFieldValue(name, [...data, res.value]);
            }}
            onDeselect={(res): void => {
                setFieldValue(
                    name,
                    data.filter(role => role !== res.value)
                );
            }}
            disabled={disabled}
        />
    </Form.Item>
);

export default OwnerSelector;
