import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { AlignType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import PermissionService from '~/services/permission-service';
import filterInput from '~/components/Table/components/filterInput';
import filterDateRange from '~/components/Table/components/filterDateRange';
import Title from '~/components/Title';

const PermissionServiceInstance = new PermissionService();

const GroupListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Permission
    });
    const [groups, setGroups] = useState([]);
    const [total, setTotal] = useState(0);

    const fetchGroups = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        PermissionServiceInstance.fetchGroups(filters, orders, page).then(
            res => {
                setGroups(res.data);
                setTotal(res.total);
            }
        );
    };

    const exportGroups = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        PermissionServiceInstance.exportGroups(filters, orders);
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    const handleGroupDelete = (selectedRows): void => {
        PermissionServiceInstance.deleteGroups(selectedRows).then(() =>
            fetchGroups()
        );
    };

    const showDeleteConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t('pages.permissions.groups.list.confirmDeleteTitle'),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.permissions.groups.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const actions: ActionProps[] = [
        {
            multiple: true,
            label: t('basic.deleteSelectedButton'),
            danger: true,
            onClick: showDeleteConfirm
        },
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportGroups
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/admin/groups/new')
        }
    ];

    const columns = [
        {
            title: t('pages.permissions.groups.fields.name'),
            dataIndex: 'name',
            sorter: true,
            ...filterInput
        },
        {
            title: t('basic.creationDate'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('L LT')
                    .toString(),
            ...filterDateRange
        },
        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            render: (text, record): React.ReactNode => (
                <ActionButtonWrapper>
                    <Button
                        danger
                        onClick={(): void => showDeleteConfirm([record.id])}
                    >
                        {t('basic.deleteButton')}
                    </Button>
                    <Link to={`/admin/groups/${record.id}`}>
                        <Button>{t('basic.editButton')}</Button>
                    </Link>
                </ActionButtonWrapper>
            )
        }
    ];

    return (
        <div>
            <Title type="primary">
                {t('pages.permissions.groups.list.title')}
            </Title>
            <Table
                rowKey="id"
                loading={promiseInProgress}
                columns={columns}
                dataSource={groups}
                actions={actions}
                fetchSource={fetchGroups}
                total={total}
            />
        </div>
    );
};

export default GroupListPage;
