const resources = {
    menu: {
        dashboard: 'Dashboard',
        content: 'Content',
        documents: 'Documents',
        editDocument: 'Edit document',
        viewDocument: 'View document',
        administration: 'Administration',
        taxonomies: 'Taxonomy',
        masterDatas: 'Master data',
        groups: 'Groups',
        editGroup: 'Edit group',
        roles: 'Roles',
        editRole: 'Edit role',
        users: 'Users',
        editUser: 'Edit user',
        features: 'Features',
        settings: 'Settings',
        profile: 'Profile',
        myProfile: 'My profile',
        preferences: 'Preferences',
        logout: 'Logout'
    },
    notifications: {
        title: 'Notifications'
    },
    pages: {
        noPermissions: {
            content:
                'You currently do not have access to view this page. Please contact our colleague.'
        },
        authentication: {
            cardTitle: 'ECM',
            login: {
                loginFailed: 'Login failed',
                inactiveAccount: 'This account has been set inactive.',
                incorrectLogin: 'Credentials are incorrect.',
                login: 'Log in',
                backToLogin: 'Back to login'
            },
            registration: {
                successTitle: 'Registration successful',
                successMessage: 'You can now log in with your credentials',
                successSendEmailMessage:
                    'Check your inbox to confirm your email address!',
                registrationFailed: 'Registration failed',
                verificationSuccess: 'Email has been successfully verified',
                verificationError:
                    'This email verification link has expired or is invalid',
                register: 'Register',
                firstName: 'First name',
                lastName: 'Last name',
                phoneNumber: 'Phone number',
                gdpr: 'I have read and accept the GDPR agreement',
                termsAndCondition:
                    'I have read and accept the Terms of Service agreement',
                validations: {
                    requiredGdpr: 'You must accept the GDPR statement',
                    requiredTermsAndCondition:
                        'You must accept the Terms and Conditions'
                }
            },
            lostPassword: {
                successTitle: 'Password reset requested',
                successMessage:
                    'Check your email inbox for further instructions',
                successSetTitle: 'Your password was successfully reset',
                successSetMessage:
                    'You can now log in with your new credentials',
                forgotPassword: 'Forgot password',
                resetMyPassword: 'Reset my password',
                validations: {}
            },
            revert: {
                revertSuccess: 'Your account has been reverted',
                revertExpired: 'This revert link has expired or is invalid'
            },
            lostUsername: {
                forgotUsername: 'Forgot username',
                sendMyUsername: 'Send my username',
                successTitle: 'Lost username requested',
                successMessage: 'Check your email inbox'
            }
        },
        content: {
            fields: {
                type: 'Type',
                title: 'Title',
                category: 'Category',
                status: 'Status',
                owners: 'Owners',
                viewers: 'Viewers',
                endOfValidity: 'End of validity',
                startOfValidity: 'Start of validity'
            },
            list: {
                title: 'Documents',
                confirmDeleteTitle: 'Please confirm documents deletion',
                confirmArchivesTitle: 'Please confirm documents archivation',
                confirmDeleteMessage: 'This change is permanent',
                confirmArchivateTitle: 'Please confirm document archivation',
                archivateButton: 'Archivate'
            },
            edit: {
                notFound: 'Document not found',
                successSave: 'Document was successfully updated',
                failedSave: 'An error occurred while updating document',
                editTitle: 'Edit document',
                viewTitle: 'View document',
                createTitle: 'Create document',
                selectType: 'Select type',
                selectStatus: 'Select status'
            },
            validations: {
                requiredTitle: 'Title is required',
                requiredTaxonomy: 'Taxonomy is required',
                requiredType: 'Type is required',
                requiredStatus: 'Status is required',
                requiredValidFrom: 'Start of validity is required'
            }
        },
        features: {
            title: 'Features'
        },
        masterData: {
            fields: {
                label: 'Label',
                key: 'Key',
                value: 'Value',
                description: 'Description'
            },
            list: {
                title: 'Master Data'
            },
            edit: {
                title: 'Edit master data',
                notFound: 'Master data not found',
                failedSave: 'An error occurred while updating master data',
                successSave: 'MasterData was successfully updated'
            },
            validations: {
                requiredLabel: 'Label is required',
                requiredKey: 'Key is required',
                requiredValue: 'Value is required'
            }
        },
        permissions: {
            groups: {
                fields: {
                    name: 'Group Name',
                    description: 'Description',
                    owners: 'Owners',
                    members: 'Members',
                    roles: 'Roles'
                },
                edit: {
                    notFound: 'Group not found',
                    successSave: 'Group was successfully updated',
                    failedSave: 'An error occurred while updating group',
                    editTitle: 'Edit group',
                    createTitle: 'Create group'
                },
                list: {
                    title: 'Permission groups',
                    confirmDeleteTitle: 'Please confirm group deletion',
                    confirmDeleteMessage: 'This change is permanent'
                },
                validations: {
                    requiredName: 'Group name is required'
                }
            },
            roles: {
                fields: {
                    name: 'Role Name',
                    description: 'Description',
                    permissions: 'Permission'
                },
                edit: {
                    notFound: 'Role not found',
                    successSave: 'Role was successfully updated',
                    failedSave: 'An error occurred while updating role',
                    editTitle: 'Edit role',
                    createTitle: 'Create role'
                },
                list: {
                    title: 'Permission roles',
                    confirmDeleteTitle: 'Please confirm role deletion',
                    confirmDeleteMessage: 'This change is permanent'
                },
                validations: {
                    requiredName: 'Role name is required'
                }
            }
        },
        settings: {
            successSave: 'Configuration successfully updated!',
            failedSave: 'An error occurred while updating configuration!',
            title: 'Settings',
            preferencesTitle: 'Preferences'
        },
        taxonomy: {
            fields: {
                parent: 'Parent Category',
                name: 'Name',
                identifier: 'Identifier',
                description: 'Description'
            },
            edit: {
                notFound: 'Taxonomy not found',
                successSave: 'Taxonomy was successfully updated',
                failedSave: 'An error occurred while updating taxonomy'
            },
            list: {
                addNewBtn: 'Add new taxonomy',
                editBtn: 'Edit taxonomy',
                deleteBtn: 'Delete taxonomy',
                filterBtn: 'Filter documents',
                confirmDeleteTitle: 'Please confirm taxonomy deletion',
                confirmDeleteMessage: 'This change is permanent',
                createTitle: 'Create taxonomy',
                editTitle: 'Edit taxonomy',
                title: 'Taxonomies'
            },
            validations: {
                requiredName: 'Name is required'
            }
        },
        users: {
            fields: {
                status: 'Status',
                createdAt: 'Registration date',
                name: 'Name',
                firstName: 'First name',
                lastName: 'Last name',
                phone: 'Phone number',
                group: 'Group',
                newEmail: 'New email',
                currentPassword: 'Current password',
                newPassword: 'New password',
                newPasswordConfirm: 'New password confirmation'
            },
            invite: {
                title: 'Invite User',
                successSave: 'User was successfully invited',
                failedSaveTitle: 'Invite failed',
                failedSaveMessage: 'An error occurred while invite user'
            },
            edit: {
                notFound: 'User not found',
                successSave: 'User was successfully updated',
                failedSave: 'An error occurred while updating user',
                title: 'Edit user',
                inviteBtn: 'Send invite'
            },
            list: {
                title: 'Users',
                revokeBnt: 'Revoke',
                statusActive: 'Active',
                statusInactive: 'Inactive',
                statusWaiting: 'Waiting email verify',
                statusPending: 'Pending',
                statusNew: 'New',
                inviteBtn: 'Invite',
                confirmDeleteTitle: 'Please confirm user deletion',
                confirmDeleteMessage: 'This change is permanent',
                mySelfDeleteTitle: 'You cannot delete yourself!',
                mySelfDeleteMessage:
                    'Please deselect your own user from the list'
            },
            profile: {
                successSave: 'Your data was successfully updated',
                failedSave: 'An error occurred while updating',
                emailSendSuccess: 'Your new email has been sent',
                emailSendFailed: 'An error occurred while updating',
                emailChangeSuccess: 'Email has been successfully verified',
                emailChangeFailed:
                    'This email verification link has expired or is invalid',
                passwordChangeSuccess: 'Your password was successfully updated',
                passwordChangeFailed: 'An error occurred while updating',
                profileDeleteSuccess: 'Your profile has been deleted',
                profileDeleteFailed: 'An error occurred while deleting',
                profileDeleteTitle: 'Profile delete',
                pageTitle: 'Change my profile',
                emailChangeTitle: 'Change my email',
                passwordChangeTitle: 'Change my password',
                changeEmailBtn: 'Change email',
                changePasswordBtn: 'Change password',
                deleteConfirmPlaceholder: 'Password for delete confirm'
            },
            validations: {
                firstNameRequired: 'First name is required',
                lastNameRequired: 'Last name is required',
                currentPasswordRequired: 'Current password is required'
            }
        }
    },
    taxonomy: {
        selectTaxonomy: 'Select taxonomy'
    },
    upload: {
        successUpload: 'File was uploaded',
        failedUpload: 'An error occurred while file uploading',
        tooLargeFile: 'The file is too large (Max: {{size}} MB)',
        uploadButton: 'Upload'
    },
    basic: {
        title: 'ECM',
        search: 'Search',
        pleaseSelect: 'Please select',
        selectedXCount: 'Selected {{count}} item(s)',
        xhrError: 'An error occurred.',
        okText: 'Yes',
        cancelText: 'No',
        confirmText: 'Confirm',
        deleteSelectedButton: 'Delete selected',
        archiveSelectedButton: 'Archive selected',
        exportButton: 'Export',
        addNewButton: 'Add new',
        removeFilterButton: 'Remove filters',
        deleteButton: 'Delete',
        editButton: 'Edit',
        viewButton: 'View',
        creationDate: 'Creation date',
        action: 'Action',
        selectWithLabel: 'Select {{label}}',
        saveButton: 'Save changes',
        view: 'Megtekintés',
        fields: {
            username: 'Username',
            email: 'Email',
            password: 'Password',
            passwordConfirmation: 'Password confirmation'
        },
        validations: {
            requiredEmail: 'Please input your email!',
            requiredUsername: 'Please input your username!',
            requiredPassword: 'Please input your password!',
            passwordFormat:
                'Must Contain 8 characters, one uppercase, one lowercase letter and one number',
            passwordMustMatch: 'Passwords must match'
        }
    },
    validations: {
        mixed: {
            default: 'Field is invalid',
            required: 'Field is required',
            oneOf: 'Field must be one of the following values: {{param}}',
            notOneOf:
                'Field must not be one of the following values: {{param}}',
            defined: 'Field must be defined'
        },
        string: {
            length: 'Field must be exactly {{param}} characters',
            min: 'Field  must be at least {{param}} characters',
            max: 'Field must be at most {{param}} characters',
            matches: 'Field must match the following: {{param}}',
            email: 'Field must be a valid email',
            url: 'Field must be a valid URL',
            uuid: 'Field must be a valid UUID',
            trim: 'Field must be a trimmed string',
            lowercase: 'Field must be a lowercase string',
            uppercase: 'Field must be a upper case string'
        },
        number: {
            min: 'Field must be greater than or equal to {{param}}',
            max: 'Field must be less than or equal to {{param}}',
            lessThan: 'Field must be less than {{param}}',
            moreThan: 'Field must be greater than {{param}}',
            notEqual: 'Field must be not equal to {{param}}',
            positive: 'Field must be a positive number',
            negative: 'Field must be a negative number',
            integer: 'Field must be an integer'
        },
        date: {
            min: 'Field must be later than {{param}}',
            max: 'Field must be at earlier than {{param}}'
        },
        object: {
            noUnknown: 'Field has unspecified keys: {{param}}'
        },
        array: {
            min: 'Field must have at least {{param}} items',
            max: 'Field must have less than or equal to {{param}} items'
        }
    }
};

export default resources;
