import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import EditForm from '~/pages/Taxonomy/Edit/components/EditForm';
import ToastrService from '~/services/toastr-service';
import TaxonomyService from '~/services/taxonomy-service';
import { Taxonomy } from '~/types/services/taxonomy-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';

interface TaxonomyEditPage {
    handleClose: () => void;
    isEditing: boolean;
    group: {
        module: string;
        type: string;
        id: number;
    };
    parentTaxonomy: {} | null;
    taxonomyId: number | null;
}

const TaxonomyServiceInstance = new TaxonomyService();

const TaxonomyEditPage: React.FC<TaxonomyEditPage> = ({
    isEditing,
    group,
    taxonomyId,
    handleClose,
    parentTaxonomy
}) => {
    const { t } = useTranslation();
    const [taxonomy, setTaxonomy] = useState<Taxonomy>();
    const area = 'taxonomy-item';
    const { promiseInProgress } = usePromiseTracker({
        area
    });

    useEffect(() => {
        if (isEditing) {
            TaxonomyServiceInstance.fetchTaxonomy(taxonomyId, area)
                .then(res => setTaxonomy(res.data))
                .catch(() => {
                    ToastrService.error(t('pages.taxonomy.edit.notFound'));
                    handleClose();
                });
        }
    }, [taxonomyId, isEditing, handleClose]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        const promise = (): Promise<unknown> =>
            isEditing
                ? TaxonomyServiceInstance.updateTaxonomy(
                      taxonomyId,
                      values,
                      area
                  )
                : TaxonomyServiceInstance.createTaxonomy(values, area);
        promise()
            .then(() => {
                ToastrService.success(t('pages.taxonomy.edit.successSave'));
                handleClose();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(t('pages.taxonomy.edit.failedSave'));
                }
            });
    };

    return (
        <EditForm
            onSubmit={handleSubmit}
            taxonomy={taxonomy}
            loading={promiseInProgress}
            isEditing={isEditing}
            group={group}
            parentTaxonomy={parentTaxonomy}
        />
    );
};

export default TaxonomyEditPage;
