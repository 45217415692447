import React, { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { Form, Input } from 'formik-antd';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import DeleteProfileFormSchema from './DeleteProfileForm.validation';
import EditForm from '~/components/EditForm';

interface DeleteProfileFormProps {
    onSubmit: (values) => void;
    loading: boolean;
}

const FormReference = ({ saveFormRef }): null => {
    const formikContext = useFormikContext();

    if (saveFormRef) {
        saveFormRef(formikContext);
    }

    return null;
};

const DeleteProfileForm: React.FC<DeleteProfileFormProps> = ({
    onSubmit,
    loading
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const formRef = useRef(null);
    const saveFormRef = (ref): void => {
        formRef.current = ref;
    };

    const handleDeleteClick = (): void => {
        setShowModal(true);
    };

    return (
        <>
            <Button
                type="primary"
                danger
                loading={loading}
                onClick={(): void => {
                    handleDeleteClick();
                }}
                id="delete_confirm"
            >
                {t('basic.deleteButton')}
            </Button>
            <Modal
                title={t('pages.users.profile.profileDeleteTitle')}
                visible={showModal}
                onOk={(): void => {
                    if (formRef.current.values.password) {
                        formRef.current.submitForm().then((): void => {
                            setShowModal(false);
                        });
                    }
                }}
                onCancel={(): void => setShowModal(false)}
                okText={t('basic.confirmText')}
                forceRender
            >
                <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validationSchema={DeleteProfileFormSchema}
                    validateOnBlur
                >
                    <EditForm disableformItemLayout>
                        <FormReference saveFormRef={saveFormRef} />
                        <Form.Item
                            name="password"
                            label={t('basic.fields.password')}
                        >
                            <Input.Password
                                required
                                name="password"
                                placeholder={t(
                                    'pages.users.profile.deleteConfirmPlaceholder'
                                )}
                            />
                        </Form.Item>
                    </EditForm>
                </Formik>
            </Modal>
        </>
    );
};

export default DeleteProfileForm;
