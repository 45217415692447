import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import {
    Taxonomy,
    TaxonomyList,
    TaxonomySelectItem
} from '~/types/services/taxonomy-service';
import { ApiResponse } from '~/types/services/common';

export default class TaxonomyService extends BaseService {
    constructor() {
        super(ServiceEnums.Taxonomy);
    }

    fetchTaxonomies(): Promise<ApiResponse<TaxonomyList>> {
        return this.getRequest();
    }

    fetchTaxonomy(
        taxonomyId: number,
        area?: string
    ): Promise<ApiResponse<Taxonomy>> {
        return this.getRequest(`${taxonomyId}`, null, area);
    }

    deleteTaxonomies<T>(taxonomyIds: string[] | number[]): Promise<T> {
        return this.deleteRequest('', {
            taxonomies: taxonomyIds
        });
    }

    fetchTaxonomiesByType(
        module: string,
        type: string,
        area?: string
    ): Promise<ApiResponse<Taxonomy[]>> {
        return this.getRequest(`types/${module}/${type}`, null, area);
    }

    fetchTaxonomyByKey(
        key: string,
        module: string,
        type: string,
        area?: string
    ): Promise<ApiResponse<TaxonomySelectItem>> {
        return this.getRequest(`key/${module}/${type}/${key}`, null, area);
    }

    updateTaxonomy<T>(
        id: string | number,
        data: Taxonomy,
        area?: string
    ): Promise<T> {
        return this.putRequest(`${id}`, data, area);
    }

    createTaxonomy<T>(data: Taxonomy, area?: string): Promise<T> {
        return this.postRequest(``, data, area);
    }

    exportTaxonomies(): void {
        return this.download('export');
    }
}
