import React from 'react';
import { Form } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { ConfigurationValue } from '~/types/services/configurator-service';
import ConfigInputProps from '~/components/ConfigInput/components/ConfigInputProps.type';
import AjaxSelect from '~/components/AjaxSelect';

interface SelectInputProps extends ConfigInputProps {
    multiple?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
    name,
    configId,
    loading,
    value,
    onChange,
    onFetchOptions,
    defaultOptions = [],
    multiple = false
}) => {
    const defaultValue: LabeledValue[] = defaultOptions.map(option => ({
        label: option.name,
        value: option.id
    }));

    const handleChange = (selectedValue): void => {
        const optionValue = multiple
            ? selectedValue.reduce(
                  (optionValues, current) => [
                      parseInt(current.value, 10),
                      ...optionValues
                  ],
                  []
              )
            : parseInt(selectedValue.value, 10);
        return onChange(configId, optionValue);
    };

    const getSelectedValue = (): LabeledValue | LabeledValue[] => {
        if (!value) return null;

        if (multiple) {
            const values = value as ConfigurationValue[];
            if (!values.length) return null;
            return values.map(val => ({
                label: val.name,
                value: val.id
            }));
        }

        const data = value as ConfigurationValue;
        return { label: data[0].name, value: data[0].id };
    };
    return (
        <Form.Item name={name} label={name}>
            <AjaxSelect
                loading={loading}
                multiple={multiple}
                onSearch={onFetchOptions}
                onChange={handleChange}
                initialValues={getSelectedValue() || defaultValue}
            />
        </Form.Item>
    );
};

export default SelectInput;
