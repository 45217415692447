import React, { createContext, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import FeatureGateService from '~/services/feature-service';

interface ContextProps {
    features: object;
    isFeatureEnabled: (featureName: string) => boolean;
}

const Service = new FeatureGateService();

export const FeatureContext = createContext<ContextProps>({
    features: {},
    isFeatureEnabled: () => false
} as ContextProps);

const FeatureStoreProvider: React.FC = ({ children }) => {
    const [features, setFeatures] = useState<object>();
    const location = useLocation().pathname;

    useEffect(() => {
        Service.fetchFeatures().then(res => setFeatures(res.data));
    }, [location]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isFeatureEnabled = (featureName): boolean =>
        get(features, featureName) as boolean;

    const store = useMemo<ContextProps>(
        () => ({ features, isFeatureEnabled }),
        [features, isFeatureEnabled]
    );

    return (
        <FeatureContext.Provider value={store}>
            {children}
        </FeatureContext.Provider>
    );
};

export default FeatureStoreProvider;
