import React from 'react';
import { DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

const filterDateRange = {
    filterDropdown: ({ setSelectedKeys, confirm }): React.ReactNode => (
        <div style={{ padding: 8 }}>
            <DatePicker.RangePicker
                onChange={(e): void => {
                    setSelectedKeys(
                        e
                            ? [
                                  e[0].format('YYYY.MM.DD') || null,
                                  e[1].format('YYYY.MM.DD') || null
                              ]
                            : null
                    );
                    confirm();
                }}
                allowClear
            />
        </div>
    ),
    filterIcon: (filtered): React.ReactNode => (
        <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    )
};

export default filterDateRange;
