import React, { useState } from 'react';
import { Form, Switch } from 'antd';
import ConfigInputProps from '~/components/ConfigInput/components/ConfigInputProps.type';

type ToggleInputProps = 'name' | 'configId' | 'onChange' | 'value' | 'loading';

const ToggleInput: React.FC<Pick<ConfigInputProps, ToggleInputProps>> = ({
    name,
    configId,
    onChange,
    value,
    loading
}) => {
    const [isChecked, toggleChecked] = useState(!!value);

    const handleChange = (checked): void => {
        toggleChecked(checked);
        return onChange(configId, checked);
    };

    return (
        <Form.Item name={name} label={name}>
            <Switch
                data-testid={name}
                loading={loading}
                checked={isChecked}
                onChange={handleChange}
            />
        </Form.Item>
    );
};

export default ToggleInput;
