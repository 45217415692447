import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import GroupEditForm from '~/pages/Permission/Groups/Edit/components/GroupEditForm';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import {
    Group,
    GroupMember,
    GroupRole
} from '~/types/services/permission-service';
import PermissionService from '~/services/permission-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';

const permissionService = new PermissionService();

const GroupEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: groupId } = useParams();
    const [group, setGroup] = useState<Group>();
    const [roles, setRoles] = useState<GroupRole[]>();
    const [members, setMembers] = useState<GroupMember[]>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Permission
    });

    const isEditing = groupId !== 'new';

    useEffect(() => {
        if (!isEditing) return;
        permissionService
            .fetchGroup(groupId)
            .then(res => setGroup(res.data))
            .then(() =>
                permissionService
                    .fetchGroupRoles(groupId)
                    .then(res => setRoles(res.data))
            )
            .then(() =>
                permissionService
                    .fetchGroupMembers(groupId)
                    .then(res => setMembers(res.data))
            )
            .catch(() => {
                ToastrService.error(
                    t('pages.permissions.groups.edit.notFound')
                );
                history.push('/admin/groups');
            });
    }, [history, groupId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        const promise = (): Promise<unknown> =>
            isEditing
                ? permissionService.updateGroup(groupId, values)
                : permissionService.createGroup(values);

        promise()
            .then(() => {
                ToastrService.success(
                    t('pages.permissions.groups.edit.successSave')
                );
                history.push('/admin/groups');
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(
                        t('pages.permissions.groups.edit.failedSave')
                    );
                }
            });
    };

    const title = isEditing
        ? t('pages.permissions.groups.edit.editTitle')
        : t('pages.permissions.groups.edit.createTitle');
    return (
        <>
            <h1>{title}</h1>
            <GroupEditForm
                isEditing={isEditing}
                onSubmit={handleSubmit}
                group={group}
                roles={roles}
                members={members}
                loading={promiseInProgress}
            />
        </>
    );
};

export default GroupEditPage;
