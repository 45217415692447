import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

interface SpinnerWithIconProps {
    className: string;
}

const SpinnerWithIcon: React.FC<SpinnerWithIconProps> = ({ className }) => (
    <Spin className={className} />
);

const Spinner = styled(SpinnerWithIcon)`
    display: block;
    margin: 0 auto;
`;

export default Spinner;
