import React from 'react';
import styled from 'styled-components';

const HeaderText = styled.h1`
    display: inline-block;
    color: white;
`;

const Logo: React.FC = () => {
    return <HeaderText>Hálózati tudástár - Sztenderdnyilvántartó</HeaderText>;
};

export default Logo;
