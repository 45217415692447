import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditFormSchema from './EditForm.validation';
import { Permission, Role } from '~/types/services/permission-service';
import EditForm from '~/components/EditForm';
import PermissionTreeSelect from '~/pages/Permission/Roles/Edit/components/PermissionTreeSelect';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    role: Role;
    permissions: Permission[];
    isEditing: boolean;
    loading: boolean;
}

const RoleEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    role,
    permissions,
    loading,
    isEditing
}) => {
    const { t } = useTranslation();
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };
    if ((!role || !permissions) && isEditing) return <Spinner />;
    const permissionsIsExists = permissions || [];
    const initialPermissions = permissionsIsExists.map(permission => ({
        value: permission.id,
        label: permission.description
    }));
    const initialPermissionIds = permissionsIsExists.map(
        permission => permission.id
    );

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                name: get(role, 'name', ''),
                description: get(role, 'description', ''),
                permissions: initialPermissionIds
            }}
        >
            {({ values, setFieldValue }): ReactElement => (
                <EditForm>
                    <Form.Item
                        name="name"
                        label={t('pages.permissions.roles.fields.name')}
                        required
                    >
                        <Input
                            name="name"
                            placeholder={t(
                                'pages.permissions.roles.fields.name'
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={t('pages.permissions.roles.fields.description')}
                    >
                        <Input.TextArea
                            name="description"
                            placeholder={t(
                                'pages.permissions.roles.fields.description'
                            )}
                        />
                    </Form.Item>
                    <PermissionTreeSelect
                        name="permissions"
                        label={t('pages.permissions.roles.fields.permissions')}
                        initialValues={initialPermissions}
                        setFieldValue={setFieldValue}
                        data={values.permissions}
                    />
                    <Form.Item name="submit" {...buttonItemLayout}>
                        <SubmitButton type="primary" loading={loading}>
                            {t('basic.saveButton')}
                        </SubmitButton>
                    </Form.Item>
                </EditForm>
            )}
        </Formik>
    );
};

export default RoleEditForm;
