import { Form } from 'formik-antd';
import React from 'react';
import { LabeledValue, SelectValue } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import AjaxSelect from '~/components/AjaxSelect';
import PermissionService from '~/services/permission-service';

const permissionService = new PermissionService();

const onSearch = (value): Promise<LabeledValue[]> =>
    permissionService.listPermission({ description: value }).then(res =>
        res.data.map(permission => ({
            value: permission.id,
            label: permission.description
        }))
    );

interface PermissionTreeSelectProps {
    name: string;
    label: string;
    setFieldValue: Function;
    data: LabeledValue[];
    initialValues: SelectValue;
}

const PermissionTreeSelect: React.FC<PermissionTreeSelectProps> = ({
    name,
    label,
    setFieldValue,
    data,
    initialValues
}) => {
    const { t } = useTranslation();

    return (
        <Form.Item name={name} label={label}>
            <AjaxSelect
                multiple
                id={name}
                placeholder={t('basic.selectWithLabel', { label })}
                initialValues={initialValues}
                onSearch={onSearch}
                onSelect={(res): void => {
                    setFieldValue(name, [...data, res.value]);
                }}
                onDeselect={(res): void => {
                    setFieldValue(
                        name,
                        data.filter(role => role !== res.value)
                    );
                }}
            />
        </Form.Item>
    );
};

export default PermissionTreeSelect;
