/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';
import PasswordRules from '~/shared/validation/password-rules';

export default Yup.object().shape({
    current_password: PasswordRules.required(
        'pages.users.validations.currentPasswordRequired'
    ),
    password: PasswordRules.required('basic.validations.requiredPassword'),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'basic.validations.passwordMustMatch'
    )
});
