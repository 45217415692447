import React, { useContext, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styled from 'styled-components';
import ServiceEnums from '~/types/shared/service-enums';
import ChangeEmailForm from './components/ChangeEmailForm';
import ChangePasswordForm from './components/ChangePasswordForm';
import UserService from '~/services/user-service';
import ToastrService from '~/services/toastr-service';
import DeleteProfileForm from './components/DeleteProfileForm';
import { AuthContext } from '~/shared/context/auth';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { Group } from '~/types/services/permission-service';
import BasicProfileEditForm from '~/pages/User/Profile/components/BasicProfileEditForm';
import { User } from '~/types/services/user-service';
import FileUploadService from '~/services/file-upload-service';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';

const DownloadButtons = styled(ActionButtonWrapper)`
    margin-top: 1rem;
`;

const UserServiceInstance = new UserService();
const fileService = new FileUploadService();

const UserProfile: React.FC = () => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });
    const { promiseInProgress: privacyLoading } = usePromiseTracker({
        area: ServiceEnums.FileUpload
    });
    const history = useHistory();
    const [groups, setGroups] = useState<Group[]>([]);
    const [user, setUser] = useState<User>();
    const { changeToken } = useContext(AuthContext);
    const queryParams = qs.parse(history.location.search);

    useEffect(() => {
        if (user) {
            return;
        }
        UserServiceInstance.fetchMe().then(res => setUser(res.data));
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!user) {
            return;
        }
        UserServiceInstance.fetchUserGroups(user.id).then(groupsRes =>
            setGroups(groupsRes)
        );
    }, [user]);

    if (history.location.pathname === '/change-email/verify') {
        const { success } = queryParams;

        if (success) {
            ToastrService.success(t('pages.users.profile.emailChangeSuccess'));
        } else {
            ToastrService.error(t('pages.users.profile.emailChangeFailed'));
        }
        history.push('/my-profile');
    }

    const updateUser = (values, helpers): void => {
        UserServiceInstance.updateMe(values)
            .then(() => {
                ToastrService.success(t('pages.users.profile.successSave'));
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                    ToastrService.error(
                        t('pages.users.profile.validationError')
                    );
                } else {
                    ToastrService.error(t('pages.users.profile.failedSave'));
                }
            });
    };

    const changeEmail = (values, helpers): void => {
        UserServiceInstance.changeEmail(values)
            .then(() => {
                ToastrService.success(
                    t('pages.users.profile.emailSendSuccess')
                );
                helpers.resetForm();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(
                        t('pages.users.profile.emailSendFailed')
                    );
                }
            });
    };

    const changePassword = (values, helpers): void => {
        UserServiceInstance.updatePassword(values)
            .then(() => {
                ToastrService.success(
                    t('pages.users.profile.passwordChangeSuccess')
                );
                helpers.resetForm();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(
                        t('pages.users.profile.passwordChangeFailed')
                    );
                }
            });
    };

    const handleDelete = (values): void => {
        UserServiceInstance.deleteProfile(values)
            .then(() => {
                ToastrService.success(
                    t('pages.users.profile.profileDeleteSuccess')
                );
                changeToken();
                history.push('/auth/login');
            })
            .catch((error: AxiosError): void => {
                let errorMessage = t('pages.users.profile.profileDeleteFailed');
                if (error.response.data.errors.password) {
                    errorMessage = error.response.data.message;
                }
                ToastrService.error(
                    t('pages.users.profile.profileDeleteTitle'),
                    errorMessage
                );
            });
    };

    const downloadPrivacy = (type): void => {
        fileService.privacyDownload(type);
    };

    return (
        <>
            <h1>{t('pages.users.profile.pageTitle')}</h1>
            <BasicProfileEditForm
                onSubmit={updateUser}
                user={user}
                groups={groups}
                loading={promiseInProgress}
                myProfile
            />
            <h1>{t('pages.users.profile.emailChangeTitle')}</h1>
            <ChangeEmailForm
                onSubmit={changeEmail}
                loading={promiseInProgress}
            />
            <h1>{t('pages.users.profile.passwordChangeTitle')}</h1>
            <ChangePasswordForm
                onSubmit={changePassword}
                loading={promiseInProgress}
            />
            <h1>{t('pages.users.profile.profileDeleteTitle')}</h1>
            <DeleteProfileForm
                onSubmit={handleDelete}
                loading={promiseInProgress}
            />
            <DownloadButtons>
                <Button
                    onClick={(): void => downloadPrivacy('adatkezeles')}
                    loading={privacyLoading}
                >
                    Adatkezelési tájékoztató letöltése
                </Button>
                <Button
                    onClick={(): void => downloadPrivacy('titoktartas')}
                    loading={privacyLoading}
                >
                    Titoktartási nyilatkozat letöltése
                </Button>
            </DownloadButtons>
        </>
    );
};

export default UserProfile;
