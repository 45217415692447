enum ServiceEnums {
    Auth = 'auth',
    Account = 'accounts',
    FeatureGate = 'features',
    Notification = 'notification',
    User = 'user',
    Permission = 'permission',
    Configurator = 'configurator',
    MasterData = 'master-data',
    Content = 'content',
    Taxonomy = 'taxonomy',
    FileUpload = 'files',
    FormBuilder = 'forms'
}

export default ServiceEnums;
