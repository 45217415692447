/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    label: Yup.string()
        .max(50)
        .required('pages.masterData.validations.requiredLabel'),
    description: Yup.string()
        .max(255)
        .nullable()
});
