import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import UserService from '~/services/user-service';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import InviteUserEditForm from '~/pages/User/Invite/components/InviteUserEditForm';

interface UserInviteProps {
    handleClose: () => void;
}

const UserServiceInstance = new UserService();

const UserInvite: React.FC<UserInviteProps> = ({ handleClose }) => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    const inviteUser = (values, helpers): void => {
        UserServiceInstance.inviteUser(values)
            .then(() => {
                ToastrService.success(t('pages.users.invite.successSave'));
                handleClose();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(
                        t('pages.users.invite.failedSaveTitle'),
                        t('pages.users.invite.failedSaveMessage')
                    );
                }
            });
    };

    return (
        <Drawer
            title={t('pages.users.invite.title')}
            closable
            onClose={(): void => handleClose()}
            placement="right"
            visible
            width={420}
        >
            <InviteUserEditForm
                onSubmit={inviteUser}
                loading={promiseInProgress}
            />
        </Drawer>
    );
};

export default UserInvite;
