import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Tooltip, Typography, Modal, Button } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import {
    DeleteOutlined,
    FormOutlined,
    PlusCircleOutlined,
    ExclamationCircleOutlined,
    TableOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TaxonomyService from '~/services/taxonomy-service';
import ServiceEnums from '~/types/shared/service-enums';
import SearchableTree, { TreeInterface } from '~/components/SearchableTree';
import Spinner from '~/components/Spinner';
import { Taxonomy, TaxonomyList } from '~/types/services/taxonomy-service';
import TaxonomyEditPage from '~/pages/Taxonomy/Edit';
import i18n from '~/shared/utils/i18n';

interface TaxonomyGroup {
    module: string;
    module_label: string;
    type: string;
    id: number;
}

interface TaxonomyEditProps {
    group: TaxonomyGroup;
    parent?: {};
    taxonomyId?: number;
    isEdit: boolean;
}

const taxonomyService = new TaxonomyService();

const treeViewAction = (
    title: string,
    code: string | null,
    taxonomyId: number | null,
    group: TaxonomyGroup,
    deletable: boolean,
    editable: boolean,
    handleNew: (group: TaxonomyGroup, parent: {} | null) => void,
    handleEdit?: (group: TaxonomyGroup, taxonomyId: number) => void,
    handleDelete?: (taxonomyId: number) => void
): React.ReactNode => {
    return (
        <span>
            <span>{title}</span>
            <Tooltip title={i18n.t('pages.taxonomy.list.addNewBtn')}>
                <PlusCircleOutlined
                    style={{ cursor: 'pointer', padding: '0px 5px' }}
                    onClick={(): void =>
                        handleNew(
                            group,
                            taxonomyId
                                ? {
                                      id: taxonomyId,
                                      name: title + (code ? ` (${code})` : '')
                                  }
                                : null
                        )
                    }
                />
            </Tooltip>
            {editable && (
                <Tooltip title={i18n.t('pages.taxonomy.list.editBtn')}>
                    <FormOutlined
                        style={{ cursor: 'pointer', padding: '0px 5px' }}
                        onClick={(): void => handleEdit(group, taxonomyId)}
                    />
                </Tooltip>
            )}
            {deletable && (
                <Tooltip title={i18n.t('pages.taxonomy.list.deleteBtn')}>
                    <DeleteOutlined
                        style={{ cursor: 'pointer', padding: '0px 5px' }}
                        onClick={(): void => handleDelete(taxonomyId)}
                    />
                </Tooltip>
            )}
            {group.module === 'Content' &&
                group.type === 'document_taxonomy' &&
                taxonomyId && (
                    <Tooltip title={i18n.t('pages.taxonomy.list.filterBtn')}>
                        <Link
                            to={`/content/documents?taxonomyFilterName=${encodeURIComponent(
                                title
                            )}&taxonomyFilterId=${taxonomyId}`}
                        >
                            <TableOutlined
                                style={{
                                    cursor: 'pointer',
                                    padding: '0px 5px'
                                }}
                            />
                        </Link>
                    </Tooltip>
                )}
        </span>
    );
};

const getChildTaxonomies = (
    taxonomies: Taxonomy[],
    parentId: number,
    group: TaxonomyGroup,
    handleNew: (group: TaxonomyGroup, parent: {} | null) => void,
    handleEdit: (group: TaxonomyGroup, taxonomyId: number | null) => void,
    handleDelete: (taxonomyId: number) => void
): TreeInterface[] => {
    const tree: TreeInterface[] = [];
    taxonomies
        .filter((item: Taxonomy) => item.taxonomy_id === parentId)
        // eslint-disable-next-line array-callback-return
        .forEach((taxonomy: Taxonomy) => {
            tree.push({
                title: treeViewAction(
                    taxonomy.name,
                    taxonomy.key,
                    taxonomy.id,
                    group,
                    taxonomy.taxonomy_children_count === 0,
                    true,
                    handleNew,
                    handleEdit,
                    handleDelete
                ),
                planTitle: taxonomy.name,
                key: taxonomy.id,
                children:
                    taxonomy.taxonomy_children_count !== 0
                        ? getChildTaxonomies(
                              taxonomies,
                              taxonomy.id,
                              group,
                              handleNew,
                              handleEdit,
                              handleDelete
                          )
                        : []
            });
        });
    return tree;
};

const transformTaxonomyForTree = (
    data: TaxonomyList,
    handleNew: (group: TaxonomyGroup, parent: {} | null) => void,
    handleEdit: (group: TaxonomyGroup, taxonomyId: number | null) => void,
    handleDelete: (taxonomyId: number) => void
): TreeInterface[] => {
    const tree: TreeInterface[] = [];

    // eslint-disable-next-line array-callback-return
    data.groups.map(group => {
        if (
            tree[tree.length - 1] &&
            tree[tree.length - 1].key === group.module
        ) {
            tree[tree.length - 1].children.push({
                title: group.label,
                planTitle: group.label,
                key: group.type,
                children: []
            });
        } else {
            const treeItem = {
                title: group.module_label,
                planTitle: group.module_label,
                key: group.module_label,
                children: [
                    {
                        title: treeViewAction(
                            group.label,
                            null,
                            null,
                            group,
                            false,
                            false,
                            handleNew
                        ),
                        planTitle: group.label,
                        key: group.type,
                        children: []
                    }
                ]
            };
            tree.push(treeItem);
        }
        data.data
            .filter(
                (item: Taxonomy) =>
                    item.taxonomy_group_id === group.id &&
                    item.taxonomy_id === null
            )
            // eslint-disable-next-line array-callback-return
            .forEach((taxonomy: Taxonomy) => {
                const lastTreeItem = tree[tree.length - 1];
                lastTreeItem.children[
                    lastTreeItem.children.length - 1
                ].children.push({
                    title: treeViewAction(
                        taxonomy.name,
                        taxonomy.key,
                        taxonomy.id,
                        group,
                        taxonomy.taxonomy_children_count === 0,
                        true,
                        handleNew,
                        handleEdit,
                        handleDelete
                    ),
                    planTitle: taxonomy.name,
                    key: taxonomy.id,
                    children:
                        taxonomy.taxonomy_children_count !== 0
                            ? getChildTaxonomies(
                                  data.data,
                                  taxonomy.id,
                                  group,
                                  handleNew,
                                  handleEdit,
                                  handleDelete
                              )
                            : []
                });
            });
    });
    return tree;
};

const TaxonomyListPage: React.FC = () => {
    const { t } = useTranslation();

    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Taxonomy
    });

    const [
        showTaxonomyForm,
        setShowTaxonomyForm
    ] = useState<TaxonomyEditProps | null>(null);

    const [taxonomies, setTaxomomies] = useState([]);

    const deleteTaxonomy = (taxonomyIds: number[]): void => {
        taxonomyService
            .deleteTaxonomies(taxonomyIds)
            .then(() => setTaxomomies([]));
    };

    const handleEdit = (group: TaxonomyGroup, taxonomyId: number): void => {
        setShowTaxonomyForm({
            group,
            taxonomyId,
            isEdit: true
        });
    };

    const handleNew = (group: TaxonomyGroup, parent: {} | null): void => {
        setShowTaxonomyForm({
            group,
            parent,
            isEdit: false
        });
    };

    const handleDelete = (taxonomyId: number): void => {
        Modal.confirm({
            title: t('pages.taxonomy.list.confirmDeleteTitle'),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.taxonomy.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                deleteTaxonomy([taxonomyId]);
            }
        });
    };

    const fetchTaxonomies = (): void => {
        taxonomyService.fetchTaxonomies().then(res => {
            setTaxomomies(
                transformTaxonomyForTree(
                    res.data,
                    handleNew,
                    handleEdit,
                    handleDelete
                )
            );
        });
    };

    const handleTaxonomyFormClose = (): void => {
        setShowTaxonomyForm(null);
        setTaxomomies([]);
    };

    useEffect(() => {
        if (taxonomies.length === 0) {
            fetchTaxonomies();
        }
    }, [taxonomies]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.taxonomy.list.title')}
            </Typography.Title>
            <Button
                type="ghost"
                style={{ marginBottom: 16 }}
                onClick={(): void => taxonomyService.exportTaxonomies()}
                loading={promiseInProgress}
            >
                {t('basic.exportButton')}
            </Button>
            {promiseInProgress && <Spinner />}
            {!promiseInProgress && taxonomies.length !== 0 && (
                <SearchableTree treeData={taxonomies} />
            )}
            <Drawer
                title={
                    showTaxonomyForm && showTaxonomyForm.isEdit
                        ? t('pages.taxonomy.list.editTitle')
                        : t('pages.taxonomy.list.createTitle')
                }
                placement="right"
                closable
                onClose={(): void => setShowTaxonomyForm(null)}
                visible={!!showTaxonomyForm}
                width={320}
            >
                {!!showTaxonomyForm && (
                    <TaxonomyEditPage
                        handleClose={handleTaxonomyFormClose}
                        isEditing={showTaxonomyForm.isEdit}
                        group={showTaxonomyForm.group}
                        parentTaxonomy={showTaxonomyForm.parent}
                        taxonomyId={showTaxonomyForm.taxonomyId}
                    />
                )}
            </Drawer>
        </div>
    );
};

export default TaxonomyListPage;
