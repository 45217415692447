/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    name: Yup.string()
        .min(2)
        .max(50)
        .required('pages.permissions.groups.validations.requiredName'),
    description: Yup.string()
        .min(2)
        .max(255)
        .nullable()
});
