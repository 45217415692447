enum PermissionKeysEnums {
    FullAccess = 'access.full',
    DocumentFull = 'documents.manage',
    Egyeb = 'egyeb.manage',
    Eloiras = 'eloiras.manage',
    Mubizonylat = 'mubizonylat.manage',
    ViewFull = 'documents.view',
    EloirasMegtekinto = 'eloiras.view',
    KulsoTervezo = 'kulso.tervezo.view',
    Beszallitok = 'beszallitok.view',
    TmaView = 'tma.view',
    EgyebView = 'egyeb.view'
}

export default PermissionKeysEnums;
