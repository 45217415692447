import MasterDataEnums from '~/types/shared/master-data-enums';

const getSelectedDocumentGroup = (path: string): string => {
    switch (true) {
        case path.indexOf(MasterDataEnums.ContentGroupSpecification) !== -1:
            return MasterDataEnums.ContentGroupSpecification;
        case path.indexOf(MasterDataEnums.ContentGroupOther) !== -1:
            return MasterDataEnums.ContentGroupOther;
        default:
            return 'documents';
    }
};

export default getSelectedDocumentGroup;
