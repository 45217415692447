import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import {
    MasterData,
    MasterDataList
} from '~/types/services/masterdata-service';
import { ApiResponse } from '~/types/services/common';

export default class MasterDataService extends BaseService {
    constructor() {
        super(ServiceEnums.MasterData);
    }

    fetchMasterDatas(): Promise<ApiResponse<MasterDataList>> {
        return this.getRequest();
    }

    fetchMasterData(
        masterDataId: number,
        area?: string
    ): Promise<ApiResponse<MasterData>> {
        return this.getRequest(`${masterDataId}`, null, area);
    }

    fetchMasterDatasByModule(
        module: string
    ): Promise<ApiResponse<MasterData[]>> {
        return this.getRequest(`modules/${module}`);
    }

    fetchMasterDatasByType(
        module: string,
        type: string,
        area?: string
    ): Promise<ApiResponse<MasterData[]>> {
        return this.getRequest(`types/${module}/${type}`, null, area);
    }

    updateMasterData<T>(masterDataId: number, data: MasterData): Promise<T> {
        return this.putRequest(`${masterDataId}`, data);
    }

    masterDataExport(filter: object): void {
        return this.download('export', { filter });
    }
}
