import React, { useState } from 'react';
import { Form, Select } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { Spin } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import {
    FormBuilderField,
    DBSelectMeta,
    DBSelectData
} from '~/types/services/form-service';
import ServiceEnums from '~/types/shared/service-enums';
import FormService from '~/services/form-service';
import getDbSelectLink from '~/shared/helpers/dbselect-link';
import { getFieldName } from '~/shared/utils/form-builder';

const formService = new FormService();

interface BuilderDBSelectProps {
    field: FormBuilderField;
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderDBSelect: React.FC<BuilderDBSelectProps> = ({
    field,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const { t } = useTranslation();
    const meta = field.meta as DBSelectMeta;
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const [dbSelect, setDbSelect] = useState<DBSelectData[]>();

    const area = ServiceEnums.FormBuilder + meta.entity;

    const { promiseInProgress } = usePromiseTracker({
        area
    });

    const value = get(getFieldMeta(fieldName), 'value') || undefined;

    const handleOpen = (): void => {
        if (!dbSelect) {
            formService
                .fetchDbSelectValues(
                    meta.entity,
                    meta.labelColumn,
                    meta.valueColumn,
                    area
                )
                .then(res => {
                    if (res.data.length !== 0) {
                        setDbSelect(res.data);
                    }
                });
        }
    };

    return (
        <Form.Item
            htmlFor={fieldName}
            name={getFieldName(field.id)}
            label={field.label}
            key={field.id}
            required={field.required}
        >
            <Select
                id={fieldName}
                mode={meta.multiSelect ? 'multiple' : null}
                labelInValue
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={value}
                onBlur={onBlur}
                options={dbSelect}
                loading={promiseInProgress}
                onClick={handleOpen}
                disabled={disabled}
                notFoundContent={
                    promiseInProgress ? <Spin size="small" /> : null
                }
            />
            {meta.showLink && value && value.value && (
                <a
                    href={getDbSelectLink(meta.entity, value.value)}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('basic.view')}
                </a>
            )}
        </Form.Item>
    );
};

export default BuilderDBSelect;
