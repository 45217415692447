import React from 'react';
import FeatureStoreProvider from '~/shared/context/feature';
import UserStoreProvider from '~/shared/context/user';
import AuthStoreProvider from '~/shared/context/auth';
import PermissionStoreProvider from '~/shared/context/permission';
import LanguageStoreProvider from '~/shared/context/language';
import ConfiguratorStoreProvider from '~/shared/context/configurator';

const CombinedContext: React.FC = ({ children }) => (
    <AuthStoreProvider>
        <UserStoreProvider>
            <FeatureStoreProvider>
                <PermissionStoreProvider>
                    <ConfiguratorStoreProvider>
                        <LanguageStoreProvider>
                            {children}
                        </LanguageStoreProvider>
                    </ConfiguratorStoreProvider>
                </PermissionStoreProvider>
            </FeatureStoreProvider>
        </UserStoreProvider>
    </AuthStoreProvider>
);

export default CombinedContext;
