import React, { useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Form, TreeSelect as FormikTreeSelect } from 'formik-antd';
import { TreeSelect, Spin } from 'antd';
import TaxonomyService from '~/services/taxonomy-service';
import {
    Taxonomy,
    TaxonomySelectType
} from '~/types/services/taxonomy-service';

import 'antd/dist/antd.less';

const taxonomyService = new TaxonomyService();

interface TaxonomySelectProps {
    required?: boolean;
    value?: object;
    module: string;
    multiple?: boolean;
    type: string;
    isFormik: boolean;
    name?: string;
    label?: string;
    placeholder?: string;
    onSelect?: (value: string | object) => void;
    onChange?: (data: object) => void;
    style?: object;
    defaultValue?: string | string[] | null;
    disabled?: boolean;
    allowClear?: boolean;
    showCodeInName?: boolean;
}

const getTaxonomyChildren = (
    taxonomies: TaxonomySelectType[],
    taxonomy_id: string | number | null,
    showCodeInName?: boolean
): React.ReactNode => {
    return taxonomies
        .filter(
            (taxonomy: TaxonomySelectType) =>
                taxonomy.taxonomy_id === taxonomy_id
        )
        .map((taxonomy: TaxonomySelectType) => {
            if (taxonomy.taxonomy_children_count !== 0) {
                return (
                    <TreeSelect.TreeNode
                        value={taxonomy.value}
                        title={
                            taxonomy.label +
                            (taxonomy.key && showCodeInName
                                ? ` (${taxonomy.key}) `
                                : '')
                        }
                        key={taxonomy.value}
                    >
                        {taxonomy.taxonomy_children_count !== 0 &&
                            getTaxonomyChildren(
                                taxonomies,
                                taxonomy.value,
                                showCodeInName
                            )}
                    </TreeSelect.TreeNode>
                );
            }

            return (
                <TreeSelect.TreeNode
                    key={taxonomy.value}
                    value={taxonomy.value}
                    title={
                        taxonomy.label +
                        (taxonomy.key && showCodeInName
                            ? ` (${taxonomy.key}) `
                            : '')
                    }
                />
            );
        });
};

const TaxonomySelect: React.FC<TaxonomySelectProps> = ({
    required,
    module,
    multiple,
    type,
    name,
    label,
    placeholder,
    isFormik,
    onSelect,
    onChange,
    style,
    disabled,
    defaultValue,
    allowClear,
    showCodeInName
}) => {
    const { promiseInProgress } = usePromiseTracker({
        area: module + type
    });

    const [taxonomy, setTaxonomy] = useState<TaxonomySelectType[]>();

    const handleOpen = (): void => {
        if (!taxonomy) {
            taxonomyService
                .fetchTaxonomiesByType(module, type, module + type)
                .then(res => {
                    const data = res.data.map((tData: Taxonomy) => {
                        return {
                            value: tData.id,
                            label: tData.name,
                            key: tData.key,
                            taxonomy_id: tData.taxonomy_id,
                            taxonomy_children_count:
                                tData.taxonomy_children_count
                        };
                    });
                    setTaxonomy(data);
                });
        }
    };

    const selectProps = {
        labelInValue: true,
        name,
        placeholder: defaultValue || placeholder,
        loading: promiseInProgress,
        onClick: handleOpen,
        notFoundContent: promiseInProgress ? <Spin size="small" /> : null,
        dropdownStyle: { maxHeight: 400, overflow: 'auto' },
        disabled,
        allowClear: !!allowClear
    };

    return (
        <>
            {isFormik && (
                <Form.Item name={name} label={label} required={required}>
                    <FormikTreeSelect
                        style={{ width: '100%' }}
                        data-testid={name}
                        multiple={multiple}
                        {...selectProps}
                    >
                        {taxonomy &&
                            getTaxonomyChildren(taxonomy, null, showCodeInName)}
                    </FormikTreeSelect>
                </Form.Item>
            )}
            {!isFormik && (
                <TreeSelect
                    style={style}
                    data-testid="taxonomy_filter"
                    onChange={(data: object): void => {
                        if (onChange) {
                            onChange(data);
                        }
                    }}
                    onSelect={onSelect}
                    {...selectProps}
                >
                    {taxonomy &&
                        getTaxonomyChildren(taxonomy, null, showCodeInName)}
                </TreeSelect>
            )}
        </>
    );
};

export default TaxonomySelect;
