import React from 'react';
import { useTranslation } from 'react-i18next';

const NoPermission: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div>
            <p>{t('pages.noPermissions.content')}</p>
        </div>
    );
};

export default NoPermission;
