import { notification } from 'antd';

export default class ToastrService {
    static success(
        title: string,
        description?: string,
        duration?: number,
        config?: object
    ): void {
        this.openNotification('success', title, description, duration, config);
    }

    static error(
        title: string,
        description?: string,
        duration?: number,
        config?: object
    ): void {
        this.openNotification('error', title, description, duration, config);
    }

    static warning(
        title: string,
        description?: string,
        duration?: number,
        config?: object
    ): void {
        this.openNotification('warning', title, description, duration, config);
    }

    static info(
        title: string,
        description?: string,
        duration?: number,
        config?: object
    ): void {
        this.openNotification('info', title, description, duration, config);
    }

    static close(key: string): void {
        notification.close(key);
    }

    static clear(): void {
        notification.destroy();
    }

    private static openNotification(
        type: string,
        message: string,
        description: string,
        duration?: number,
        config?: object
    ): void {
        notification[type]({
            message,
            description,
            duration: duration || 10,
            placement: 'bottomRight',
            ...config
        });
    }
}
