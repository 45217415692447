import baseAxios from 'axios';

const getAuthorization = (): string => `Bearer ${localStorage.getItem('jwt')}`;
const getAcceptLanguage = (): string =>
    localStorage.getItem('lang') || process.env.DEFAULT_LANGUAGE;

const axios = baseAxios.create({
    baseURL: `${process.env.API_URL}/`,
    timeout: 30000,
    headers: {
        Authorization: getAuthorization(),
        'Accept-language': getAcceptLanguage()
    }
});

axios.interceptors.request.use(config => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = getAuthorization();
    // eslint-disable-next-line no-param-reassign
    config.headers['Accept-language'] = getAcceptLanguage();
    return config;
});

export default axios;
