import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { LabeledValue } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import ServiceEnums from '~/types/shared/service-enums';
import Spinner from '~/components/Spinner';
import ConfiguratorService from '~/services/configurator-service';
import { Configuration } from '~/types/services/configurator-service';
import ToastrService from '~/services/toastr-service';
import ModuleConfigForm from '~/pages/SettingsPage/components/ModuleConfigForm';
import Title from '~/components/Title';

const configuratorService = new ConfiguratorService();

const SettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Configurator
    });
    const [configs, setConfigs] = useState<Configuration[]>([]);
    useEffect(() => {
        configuratorService
            .fetchConfigurationDetailedList()
            .then(res => setConfigs(res.data));
    }, []);

    const handleChange = (configurationId, value): void => {
        configuratorService
            .setConfiguration(configurationId, value)
            .then(() => ToastrService.success(t('pages.settings.successSave')))
            .catch(() => ToastrService.error(t('pages.settings.failedSave')));
    };

    const fetchOptions = (id, search): Promise<LabeledValue[]> => {
        return configuratorService
            .fetchConfigurationOptions(id, search)
            .then(res =>
                res.data.map(option => ({
                    value: option.id,
                    label: option.name
                }))
            );
    };

    const configsByModule = groupBy(configs, 'module_name');
    const moduleNames = Object.keys(configsByModule);

    return (
        <>
            <Title type="primary">{t('pages.settings.title')}</Title>
            {!configs.length && promiseInProgress && <Spinner />}
            {moduleNames.map(moduleName => (
                <ModuleConfigForm
                    key={moduleName}
                    moduleName={moduleName}
                    configs={configsByModule[moduleName]}
                    fetchOptions={fetchOptions}
                    onSubmit={handleChange}
                    loading={promiseInProgress}
                />
            ))}
        </>
    );
};

export default SettingsPage;
