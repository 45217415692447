import React, { ReactNode } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { getRouteArrayByPath } from '~/shared/utils/route';
import routes from '~/routes';
import i18n from '~/shared/utils/i18n';

const OuterContent = styled(Layout.Content)`
    margin: 0 16px 0;
`;
const InnerContent = styled.div`
    background-color: white;
    padding: 16px;
    min-height: 360px;
`;

const Content: React.FC<{ content: ReactNode }> = ({ content }) => {
    const location = useLocation();
    const routePath = getRouteArrayByPath(routes, location.pathname);

    const getBreadcrumbContent = (route): ReactNode => {
        if (!route.isIndexPage && route.subRoutes) {
            return i18n.t(route.label);
        }
        return <Link to={route.path}>{i18n.t(route.label)}</Link>;
    };

    return (
        <OuterContent>
            {routePath.length && (
                <Helmet>
                    <title>
                        {i18n.t(routePath[routePath.length - 1].label)}
                    </title>
                </Helmet>
            )}
            <Breadcrumb style={{ margin: '16px 0' }}>
                {routePath.map(route => (
                    <Breadcrumb.Item key={route.path}>
                        {getBreadcrumbContent(route)}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
            <InnerContent>{content}</InnerContent>
        </OuterContent>
    );
};

export default Content;
