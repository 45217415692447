import Home from '~/pages/Home';
import Authentication from '~/pages/Authentication';
import FeaturePage from '~/pages/FeaturePage';
import { RouteInterface } from '~/types/shared/route-interface';
import RoleListPage from '~/pages/Permission/Roles/List';
import RoleEditPage from '~/pages/Permission/Roles/Edit';
import GroupListPage from '~/pages/Permission/Groups/List';
import GroupEditPage from '~/pages/Permission/Groups/Edit';
import SettingsPage from '~/pages/SettingsPage';
import ProfilePage from '~/pages/User/Profile';
import UserListPage from '~/pages/User/List';
import UserEditPage from '~/pages/User/Edit';
import DocumentListPage from '~/pages/Content/List';
import DocumentEditPage from '~/pages/Content/Edit';
import MasterDataPage from '~/pages/MasterData/List';
import TaxonomyListPage from '~/pages/Taxonomy/List';
import MasterDataEnums from '~/types/shared/master-data-enums';
import NoPermissionPage from '~/pages/NoPermission';
import AuthService from '~/services/auth-service';
import PermissionKeysEnums from '~/types/shared/permission-keys-enums';

const routes: RouteInterface[] = [
    {
        exact: true,
        path: '/',
        component: Home,
        label: 'menu.dashboard',
        area: 'left',
        isProtected: true
    },
    {
        exact: true,
        path: '/content',
        component: null,
        label: 'menu.content',
        area: 'left',
        subRoutes: [
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: `/content/${MasterDataEnums.ContentGroupSpecification}`,
                component: DocumentListPage,
                label: 'menu.specificationDocument',
                area: 'left',
                routePermissionName: [
                    PermissionKeysEnums.DocumentFull,
                    PermissionKeysEnums.Eloiras,
                    PermissionKeysEnums.Mubizonylat,
                    PermissionKeysEnums.EloirasMegtekinto,
                    PermissionKeysEnums.KulsoTervezo,
                    PermissionKeysEnums.Beszallitok,
                    PermissionKeysEnums.ViewFull
                ],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: `/content/${MasterDataEnums.ContentGroupSpecification}/:id`,
                        component: DocumentEditPage,
                        label: 'menu.editSpecificationDocument',
                        routePermissionName: [
                            PermissionKeysEnums.DocumentFull,
                            PermissionKeysEnums.Eloiras
                        ]
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: `/content/${MasterDataEnums.ContentGroupOther}`,
                component: DocumentListPage,
                label: 'menu.otherDocument',
                area: 'left',
                routePermissionName: [
                    PermissionKeysEnums.DocumentFull,
                    PermissionKeysEnums.Egyeb,
                    PermissionKeysEnums.Mubizonylat,
                    PermissionKeysEnums.TmaView,
                    PermissionKeysEnums.EgyebView,
                    PermissionKeysEnums.KulsoTervezo,
                    PermissionKeysEnums.ViewFull
                ],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: `/content/${MasterDataEnums.ContentGroupOther}/:id`,
                        component: DocumentEditPage,
                        label: 'menu.editOtherDocument',
                        routePermissionName: [
                            PermissionKeysEnums.DocumentFull,
                            PermissionKeysEnums.Egyeb,
                            PermissionKeysEnums.Mubizonylat
                        ]
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/documents',
                component: DocumentListPage,
                label: 'menu.documents',
                area: 'left',
                routePermissionName: [
                    PermissionKeysEnums.DocumentFull,
                    PermissionKeysEnums.ViewFull,
                    PermissionKeysEnums.Mubizonylat
                ],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/documents/:id',
                        component: DocumentEditPage,
                        label: 'menu.editDocument',
                        routePermissionName: [
                            PermissionKeysEnums.DocumentFull,
                            PermissionKeysEnums.ViewFull,
                            PermissionKeysEnums.Egyeb,
                            PermissionKeysEnums.Eloiras,
                            PermissionKeysEnums.Mubizonylat
                        ]
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/documents/view/:id',
                        component: DocumentEditPage,
                        label: 'menu.viewDocument',
                        routePermissionName: [
                            PermissionKeysEnums.DocumentFull,
                            PermissionKeysEnums.ViewFull,
                            PermissionKeysEnums.Egyeb,
                            PermissionKeysEnums.Eloiras,
                            PermissionKeysEnums.Mubizonylat,
                            PermissionKeysEnums.EloirasMegtekinto,
                            PermissionKeysEnums.KulsoTervezo,
                            PermissionKeysEnums.Beszallitok,
                            PermissionKeysEnums.TmaView,
                            PermissionKeysEnums.EgyebView
                        ]
                    }
                ]
            }
        ]
    },
    {
        exact: true,
        path: '/admin',
        component: null,
        label: 'menu.administration',
        area: 'left',
        subRoutes: [
            {
                exact: true,
                isProtected: true,
                path: '/admin/taxonomy',
                component: TaxonomyListPage,
                label: 'menu.taxonomies',
                routePermissionName: 'taxonomy.manage',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                path: '/admin/master-data',
                component: MasterDataPage,
                label: 'menu.masterDatas',
                routePermissionName: 'masterdata.manage',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/groups',
                component: GroupListPage,
                label: 'menu.groups',
                routePermissionName: 'groups.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/groups/:id',
                        component: GroupEditPage,
                        label: 'menu.editGroup',
                        routePermissionName: 'groups.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/roles',
                component: RoleListPage,
                label: 'menu.roles',
                routePermissionName: 'roles.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/roles/:id',
                        component: RoleEditPage,
                        label: 'menu.editRole',
                        routePermissionName: 'roles.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/users',
                component: UserListPage,
                label: 'menu.users',
                routePermissionName: 'users.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/users/:id',
                        component: UserEditPage,
                        label: 'menu.editUser',
                        routePermissionName: 'users.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                path: '/admin/features',
                component: FeaturePage,
                label: 'menu.features',
                routePermissionName: 'features.manage',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                path: '/admin/settings',
                component: SettingsPage,
                label: 'menu.settings',
                routePermissionName: 'settings.manage',
                area: 'left'
            }
        ]
    },
    {
        exact: true,
        path: '/user',
        component: Home,
        label: 'menu.profile',
        area: 'top',
        subRoutes: [
            {
                exact: true,
                path: '/my-profile',
                isProtected: true,
                component: ProfilePage,
                label: 'menu.myProfile',
                area: 'top'
            },
            {
                exact: true,
                path: '/logout',
                isProtected: true,
                component: ({ history }): null => {
                    // TODO temporary logout
                    const authService = new AuthService();
                    authService
                        .logout()
                        .then(data => {
                            localStorage.removeItem('jwt');
                            if (data.data.url) {
                                window.location.href = data.data.url;
                            } else {
                                history.push('/auth/login');
                            }
                        })
                        .catch(() => {
                            localStorage.removeItem('jwt');
                            history.push('/auth/login');
                        });
                    return null;
                },
                label: 'menu.logout',
                area: 'top'
            }
        ]
    },
    {
        exact: true,
        path: '/no-permission',
        isProtected: true,
        component: NoPermissionPage
    },
    {
        exact: true,
        path: '/auth/:action',
        isPublicOnly: true,
        component: Authentication
    },
    {
        exact: true,
        path: '/change-email/verify',
        component: ProfilePage
    }
];

export default routes;
