const getDbSelectLink = (entity: string, id: string | number): string => {
    switch (entity) {
        case 'document':
        case 'specification':
            return `/content/documents/view/${id}`;
        default:
            return '#';
    }
};

export default getDbSelectLink;
