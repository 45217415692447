import React, { ReactNode, useContext } from 'react';
import { Route as RRoute, Redirect } from 'react-router-dom';
import AuthService from '~/services/auth-service';
import { RouteInterface } from '~/types/shared/route-interface';
import { PermissionContext } from '~/shared/context/permission';
import Spinner from '~/components/Spinner';
import { AuthContext } from '~/shared/context/auth';

const Route: React.FC<RouteInterface> = ({
    isProtected = false,
    isPublicOnly = false,
    component: Component,
    routePermissionName,
    ...rest
}) => {
    const { permissions, userHasPermission } = useContext(PermissionContext);
    const { jwt } = useContext(AuthContext);

    return (
        <RRoute
            {...rest}
            render={(props): ReactNode => {
                if (jwt && !permissions) {
                    return <Spinner />;
                }

                if (!isPublicOnly && !isProtected) {
                    return <Component {...props} />;
                }

                if (!AuthService.isAuthenticated() && !isProtected) {
                    return <Component {...props} />;
                }

                if (AuthService.isAuthenticated() && isPublicOnly) {
                    return <Redirect to="/" />;
                }

                if (
                    AuthService.isAuthenticated() &&
                    isProtected &&
                    routePermissionName
                ) {
                    const userPermissionNames = permissions.map(
                        permission => permission.name
                    );
                    const isAllowed = userHasPermission(
                        userPermissionNames,
                        routePermissionName
                    );

                    if (isAllowed) {
                        return <Component {...props} />;
                    }
                    return <Redirect to="/no-permission" />;
                }

                if (AuthService.isAuthenticated() && isProtected) {
                    return <Component {...props} />;
                }

                return <Redirect to="/auth/login" />;
            }}
        />
    );
};
export default Route;
