import React from 'react';
import get from 'lodash/get';
import TaxonomySelect from '~/components/TaxonomySelect';
import i18n from '~/shared/utils/i18n';

const filterTaxonomy = (
    module: string,
    key: string,
    defaultValue?: string | string[] | null,
    disabled?: boolean
): object => {
    return {
        filterDropdown: ({ setSelectedKeys, confirm }): React.ReactNode => (
            <div style={{ padding: 8 }}>
                <TaxonomySelect
                    placeholder={i18n.t('basic.pleaseSelect')}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    type={key}
                    module={module}
                    onSelect={(value): void => {
                        setSelectedKeys([value] || null);
                        confirm();
                    }}
                    onChange={(data): void => {
                        if (!get(data, 'value', false)) {
                            setSelectedKeys(null);
                            confirm();
                        }
                    }}
                    isFormik={false}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    allowClear
                />
            </div>
        )
    };
};

export default filterTaxonomy;
