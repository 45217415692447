import React from 'react';
import { Typography } from 'antd';

interface TitleProps {
    children: string;
    type: 'header' | 'primary' | 'secondary';
}

const Title: React.FC<TitleProps> = ({ type, children }) => {
    switch (type) {
        case 'primary':
            return <Typography.Title level={2}>{children}</Typography.Title>;
        case 'secondary':
            return <Typography.Title level={3}>{children}</Typography.Title>;
        case 'header':
        default:
            return <Typography.Title level={1}>{children}</Typography.Title>;
    }
};

export default Title;
