import { FormikHelpers } from 'formik';
import forIn from 'lodash/forIn';
import findKey from 'lodash/findKey';

const setFieldBackendError = (data, helpers: FormikHelpers<[]>): void => {
    forIn(data.errors, (errorMessages, fieldKey: string): void => {
        helpers.setFieldError(fieldKey, errorMessages[0]);
    });
};

export const getBuilderKeyByErrors = (errors, errorMessage): string => {
    const codeKey = findKey(errors, field => {
        return field[0] === errorMessage;
    });
    return codeKey.split('.')[1];
};

export const isSearchedError = (errors, errorMessage): unknown | undefined =>
    Object.values(errors).find(error => {
        return error[0] === errorMessage;
    });

export default setFieldBackendError;
