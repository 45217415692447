import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '~/shared/utils/axios';

interface ContextProps {
    jwt: string;
    changeToken: (token?: string) => void;
}

export const AuthContext = createContext<ContextProps>({
    jwt: null,
    changeToken: () => {
        return null;
    }
} as ContextProps);

const AuthStoreProvider: React.FC = ({ children }) => {
    const initialState = (): string => localStorage.getItem('jwt') || null;
    const history = useHistory();

    const [jwt, setJwt] = useState(initialState);

    useEffect(() => {
        if (jwt) {
            localStorage.setItem('jwt', jwt);
        } else {
            localStorage.removeItem('jwt');
        }
    }, [jwt]);

    const changeToken = (token?: string): void => setJwt(token);

    const store = useMemo<ContextProps>(() => ({ jwt, changeToken }), [jwt]);

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                changeToken();
                history.push('/auth/login');
            }

            return Promise.reject(error);
        }
    );

    return (
        <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
    );
};

export default AuthStoreProvider;
