import React, { createContext, useEffect, useMemo, useState } from 'react';
import find from 'lodash/find';
import { ConfigProvider } from 'antd';
import i18n from '~/shared/utils/i18n';
import languages, { Language } from '~/types/shared/languages';

interface ContextProps {
    language: string;
    selectedLanguage: Language;
    changeLanguage: (lang: string) => void;
}

export const LanguageContext = createContext<ContextProps>({
    selectedLanguage: null,
    language: null,
    changeLanguage: () => {
        return null;
    }
} as ContextProps);

const LanguageStoreProvider: React.FC = ({ children }) => {
    const initialState = (): string =>
        localStorage.getItem('lang') || process.env.DEFAULT_LANGUAGE;

    const [language, setLanguage] = useState(initialState);
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(
        find(languages, {
            key: language
        })
    );

    useEffect(() => {
        if (language) {
            localStorage.setItem('lang', language);
            setSelectedLanguage(
                find(languages, {
                    key: language
                })
            );
        }
    }, [language]);

    useEffect(() => {
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage.key);
        }
    }, [selectedLanguage]);

    const changeLanguage = (lang: string): void => setLanguage(lang);

    const store = useMemo<ContextProps>(
        () => ({ language, changeLanguage, selectedLanguage }),
        [language, selectedLanguage]
    );

    return (
        <LanguageContext.Provider value={store}>
            <ConfigProvider locale={selectedLanguage.antd}>
                {children}
            </ConfigProvider>
        </LanguageContext.Provider>
    );
};

export default LanguageStoreProvider;
