import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Taxonomy } from 'src/types/services/taxonomy-service';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditFormSchema from './EditForm.validation';
import TaxonomySelect from '~/components/TaxonomySelect';
import EditFormWrapper from '~/components/EditForm';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    taxonomy?: Taxonomy;
    loading: boolean;
    isEditing: boolean;
    group: {
        module: string;
        type: string;
        id: number;
    };
    parentTaxonomy: {} | null;
}

const EditForm: React.FC<EditFormProps> = ({
    onSubmit,
    taxonomy,
    loading,
    isEditing,
    group,
    parentTaxonomy
}) => {
    const { t } = useTranslation();

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (isEditing && !taxonomy) return <Spinner />;

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                taxonomy_group_id: group.id,
                status: 1,
                name: get(taxonomy, 'name', ''),
                taxonomy:
                    get(taxonomy, 'taxonomy') || parentTaxonomy
                        ? {
                              value: get(
                                  taxonomy,
                                  'taxonomy.id',
                                  get(parentTaxonomy, 'id')
                              ),
                              label: get(
                                  taxonomy,
                                  'taxonomy.name',
                                  get(parentTaxonomy, 'name')
                              )
                          }
                        : null,
                key: get(taxonomy, 'key', ''),
                description: get(taxonomy, 'description', '')
            }}
        >
            <EditFormWrapper disableformItemLayout>
                <TaxonomySelect
                    module={group.module}
                    type={group.type}
                    placeholder={t('pages.taxonomy.fields.parent')}
                    name="taxonomy"
                    label={t('pages.taxonomy.fields.parent')}
                    isFormik
                    allowClear
                    showCodeInName
                />
                <Form.Item
                    name="name"
                    label={t('pages.taxonomy.fields.name')}
                    required
                >
                    <Input
                        name="name"
                        placeholder={t('pages.taxonomy.fields.name')}
                    />
                </Form.Item>
                <Form.Item
                    name="key"
                    label={t('pages.taxonomy.fields.identifier')}
                >
                    <Input
                        name="key"
                        placeholder={t('pages.taxonomy.fields.identifier')}
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t('pages.taxonomy.fields.description')}
                >
                    <Input.TextArea
                        name="description"
                        placeholder={t('pages.taxonomy.fields.description')}
                    />
                </Form.Item>
                <Form.Item name="submit" {...buttonItemLayout}>
                    <SubmitButton type="primary" loading={loading}>
                        {t('basic.saveButton')}
                    </SubmitButton>
                </Form.Item>
            </EditFormWrapper>
        </Formik>
    );
};

export default EditForm;
