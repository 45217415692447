/* eslint-disable @typescript-eslint/camelcase */
import { useContext } from 'react';
import { AxiosPromise } from 'axios';
import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { AuthContext } from '~/shared/context/auth';
import { AdfsUrl, RegisterData } from '~/types/services/auth-service';

export default class AuthService extends BaseService {
    constructor() {
        super(ServiceEnums.Auth);
    }

    static isAuthenticated(): boolean {
        return !!localStorage.getItem('jwt');
    }

    static logout(): void {
        const { changeToken } = useContext(AuthContext);
        changeToken();
    }

    login(
        email: string,
        username: string,
        password: string,
        area?: ServiceEnums
    ): AxiosPromise {
        return this.postRequest(
            'login',
            {
                email,
                username,
                password
            },
            area
        );
    }

    register(data: RegisterData): AxiosPromise {
        return this.postRequest(
            `register${data.inviteToken ? '/invited' : ''}`,
            data
        );
    }

    requestPasswordReset(email: string): AxiosPromise {
        return this.getRequest('reset-password', {
            email
        });
    }

    requestLostUsername(email: string): AxiosPromise {
        return this.postRequest('lost-username', {
            email
        });
    }

    resetPassword(
        email: string,
        token: string,
        password: string,
        password_confirmation: string
    ): AxiosPromise {
        return this.postRequest('reset-password', {
            email,
            token,
            password,
            password_confirmation
        });
    }

    adfsLogin(): AxiosPromise<AdfsUrl> {
        return this.getRequest('adfsLogin');
    }

    logout(): AxiosPromise<AdfsUrl> {
        return this.getRequest('logout');
    }
}
