import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Upload, Button } from 'antd';
import { Form } from 'formik-antd';
import remove from 'lodash/remove';
import get from 'lodash/get';
import { UploadOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { FormBuilderField, FileMeta } from '~/types/services/form-service';
import ServiceEnums from '~/types/shared/service-enums';
import FileUploadService from '~/services/file-upload-service';
import ToastrService from '~/services/toastr-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderFileProps {
    field: FormBuilderField;
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const FileUploadServiceInstance = new FileUploadService();

const BuilderFile: React.FC<BuilderFileProps> = ({
    field,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const meta = field.meta as FileMeta;
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);

    const value = get(getFieldMeta(fieldName), 'value', undefined);

    const addFiles = (fileList): void => {
        setFiles(fileList);
    };

    useEffect(() => {
        if (value && value.length) {
            const loadedFiles = value.map(file => {
                return {
                    uid: file.id,
                    name: file.label,
                    status: 'done'
                };
            });
            addFiles(loadedFiles);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFieldValue(`${fieldName}`, null);
        files
            .filter(file => file.status === 'done')
            .forEach((file, index) => {
                setFieldValue(`${fieldName}[${index}]`, file.uid);
            });
    }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.FileUpload
    });

    const removeFileByUid = (uid: string | number): void => {
        remove(files, {
            uid
        });
    };

    const handleUpload = (file): void => {
        const fileData = {
            uid: file.uid,
            name: file.name,
            status: 'uploading'
        };
        FileUploadServiceInstance.fileUpload(
            file,
            meta.groupKey,
            field.id.toString(),
            field.name
        )
            .then(res => {
                ToastrService.success(t('upload.successUpload'));
                removeFileByUid(file.uid);
                fileData.status = 'done';
                fileData.uid = res.data.id;
                addFiles(files.concat(fileData));
            })
            .catch(e => {
                if (get(e, 'response.status') === 422) {
                    ToastrService.error(
                        t('upload.failedUpload'),
                        e.response.data.errors.file[0]
                    );
                } else {
                    ToastrService.error(t('upload.failedUpload'));
                }
                removeFileByUid(file.uid);
                fileData.status = 'error';
                addFiles(files.concat(fileData));
            });
        addFiles(files.concat(fileData));
    };

    const onDelete = (file): void => {
        const fileData = {
            uid: file.uid,
            name: file.name,
            status: 'removed'
        };
        removeFileByUid(file.uid);
        addFiles(files.concat(fileData));
    };

    const onDownload = (file): void => {
        FileUploadServiceInstance.fileDownload(file.uid);
    };

    const onPreview = (): void => {
        // TODO: create previews
    };

    const beforeUpload = (file: File): boolean => {
        if (
            file.size / 1024 / 1024 >
            parseInt(process.env.MAX_UPLOAD_SIZE_MB, 10)
        ) {
            ToastrService.error(
                t('upload.tooLargeFile', {
                    size: process.env.MAX_UPLOAD_SIZE_MB
                })
            );
            return false;
        }
        return true;
    };

    return (
        <Form.Item
            htmlFor={fieldName}
            name={fieldName}
            label={field.label}
            key={field.id}
            required={field.required}
        >
            <Upload
                id={fieldName}
                accept={meta.accept}
                multiple={meta.multiple}
                beforeUpload={beforeUpload}
                customRequest={(data): void => handleUpload(data.file)}
                fileList={
                    files.length &&
                    files.filter(file => file.status !== 'removed')
                }
                listType="text"
                onRemove={!disabled ? onDelete : null}
                onPreview={onPreview}
                onDownload={onDownload}
                showUploadList={{
                    showPreviewIcon: true,
                    showDownloadIcon: true,
                    showRemoveIcon: !disabled
                }}
            >
                {!disabled && (
                    <Button
                        data-testid={fieldName}
                        loading={promiseInProgress}
                        disabled={promiseInProgress}
                    >
                        <UploadOutlined />
                        {t('upload.uploadButton')}
                    </Button>
                )}
            </Upload>
        </Form.Item>
    );
};

export default BuilderFile;
