import React, { useContext } from 'react';
import { Layout } from 'antd';
import Logo from '~/components/Logo';
import Content from './components/Content';
import CollapsibleMenu from '~/components/Layout/components/Menu';
import routes from '~/routes';
import { AuthContext } from '~/shared/context/auth';
import Meta from '~/components/Layout/components/Meta';
import { PermissionContext } from '~/shared/context/permission';
import Spinner from '~/components/Spinner';
import { UserContext } from '~/shared/context/user';

const { Sider, Header } = Layout;

const AppLayout: React.FC = ({ children }) => {
    const { jwt } = useContext(AuthContext);
    const { user } = useContext(UserContext);
    const { permissions } = useContext(PermissionContext);

    const isReady = user && permissions && jwt;

    return (
        <>
            {jwt && (
                <Layout>
                    <Meta />
                    <Header className="header">
                        <Logo />
                        {isReady && (
                            <>
                                <CollapsibleMenu
                                    routes={routes}
                                    mode="horizontal"
                                    area="top"
                                />
                            </>
                        )}
                    </Header>
                    <Layout>
                        <Sider theme="light" breakpoint="lg" collapsedWidth="0">
                            {isReady && (
                                <CollapsibleMenu
                                    routes={routes}
                                    mode="inline"
                                    area="left"
                                />
                            )}
                        </Sider>
                        <Layout>
                            {isReady ? (
                                <Content content={children} />
                            ) : (
                                <Spinner />
                            )}
                        </Layout>
                    </Layout>
                </Layout>
            )}
            {!jwt && <>{children}</>}
        </>
    );
};

export default AppLayout;
