import PermissionKeysEnums from '~/types/shared/permission-keys-enums';
import MasterDataEnums from '~/types/shared/master-data-enums';
import { ActionProps } from '~/components/Table/types/ActionProps';
import ConfiguratorEnums from '~/types/shared/configurator-enums';

const hasMainPermission = (userPermissionNames): boolean => {
    return (
        userPermissionNames.indexOf(PermissionKeysEnums.FullAccess) !== -1 ||
        userPermissionNames.indexOf(PermissionKeysEnums.DocumentFull) !== -1
    );
};

const canAddNewButton = (
    userPermissionNames,
    t,
    push,
    selectedDocumentGroup
): ActionProps => {
    if (hasMainPermission(userPermissionNames)) {
        return {
            label: t('basic.addNewButton'),
            onClick: (): void => push(`/content/${selectedDocumentGroup}/new`)
        };
    }

    if (
        (userPermissionNames.indexOf(PermissionKeysEnums.Mubizonylat) !== -1 &&
            selectedDocumentGroup === MasterDataEnums.ContentGroupOther) ||
        (userPermissionNames.indexOf(PermissionKeysEnums.Eloiras) !== -1 &&
            selectedDocumentGroup ===
                MasterDataEnums.ContentGroupSpecification) ||
        (userPermissionNames.indexOf(PermissionKeysEnums.Egyeb) !== -1 &&
            selectedDocumentGroup === MasterDataEnums.ContentGroupOther)
    ) {
        return {
            label: t('basic.addNewButton'),
            onClick: (): void => push(`/content/${selectedDocumentGroup}/new`)
        };
    }
    return { label: '' };
};

export const canDeleteButton = (
    userPermissionNames,
    t,
    push,
    selectedDocumentGroup,
    documentArchiveConfig,
    showDeleteConfirm
): ActionProps => {
    if (hasMainPermission(userPermissionNames)) {
        return {
            multiple: true,
            label: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'basic.archiveSelectedButton'
                    : 'basic.deleteSelectedButton'
            ),
            danger: true,
            onClick: showDeleteConfirm
        };
    }

    if (
        (userPermissionNames.indexOf(PermissionKeysEnums.Mubizonylat) !== -1 &&
            selectedDocumentGroup === MasterDataEnums.ContentGroupOther) ||
        (userPermissionNames.indexOf(PermissionKeysEnums.Eloiras) !== -1 &&
            selectedDocumentGroup ===
                MasterDataEnums.ContentGroupSpecification) ||
        (userPermissionNames.indexOf(PermissionKeysEnums.Egyeb) !== -1 &&
            selectedDocumentGroup === MasterDataEnums.ContentGroupOther)
    ) {
        return {
            multiple: true,
            label: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'basic.archiveSelectedButton'
                    : 'basic.deleteSelectedButton'
            ),
            danger: true,
            onClick: showDeleteConfirm
        };
    }
    return { label: '' };
};

export default canAddNewButton;
