import React, { ReactNode, useContext } from 'react';
import { Row, Col, Card } from 'antd';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { usePromiseTracker } from 'react-promise-tracker';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import AuthService from '~/services/auth-service';
import LoginForm from '~/pages/Authentication/components/LoginForm';
import { AuthContext } from '~/shared/context/auth';
import { LanguageContext } from '~/shared/context/language';
import RegisterForm from '~/pages/Authentication/components/RegisterForm';
import { FeatureContext } from '~/shared/context/feature';
import ToastrService from '~/services/toastr-service';
import PasswordResetForm from '~/pages/Authentication/components/PasswordResetForm';
import ServiceEnums from '~/types/shared/service-enums';
import Spinner from '~/components/Spinner';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import LostUsernameForm from '~/pages/Authentication/components/LostUsernameForm';
import AdfsLogin from '~/pages/Authentication/components/AdfsLogin';

const Style = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.2);
    padding: 50px 10px 20px;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Authentication: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { action } = useParams();
    const { changeToken } = useContext(AuthContext);
    const { changeLanguage } = useContext(LanguageContext);
    const { isFeatureEnabled } = useContext(FeatureContext);
    const authService = new AuthService();
    const queryParams = qs.parse(history.location.search);
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.FeatureGate
    });

    const handleLogin = async (values): Promise<void> => {
        await authService
            .login(values.email, values.username, values.password)
            .then(data => {
                changeToken(data.data.access_token);
                changeLanguage(data.data.lang);
                history.push('/');
            })
            .catch((error: AxiosError) => {
                ToastrService.error(
                    t('pages.authentication.login.loginFailed'),
                    error.response.status === 400
                        ? t('pages.authentication.login.inactiveAccount')
                        : t('pages.authentication.login.incorrectLogin')
                );
            });
    };

    const handleRegister = async (values, helpers): Promise<void> => {
        await authService
            .register(values)
            .then(() => {
                ToastrService.success(
                    t('pages.authentication.registration.successTitle'),
                    values.inviteToken
                        ? t('pages.authentication.registration.successMessage')
                        : t(
                              'pages.authentication.registration.successSendEmailMessage'
                          )
                );
                history.push('/');
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                    ToastrService.error(
                        t('pages.authentication.registration.validations.error')
                    );
                } else {
                    ToastrService.error(
                        t(
                            'pages.authentication.registration.registrationFailed'
                        ),
                        t('basic.xhrError')
                    );
                }
            });
    };

    const requestPasswordReset = async ({ email }, helpers): Promise<void> => {
        await authService
            .requestPasswordReset(email)
            .then(() => {
                ToastrService.success(
                    t('pages.authentication.lostPassword.successTitle'),
                    t('pages.authentication.lostPassword.successMessage')
                );
                history.push('/');
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(t('basic.xhrError'));
                }
            });
    };

    const requestLostUsername = async ({ email }, helpers): Promise<void> => {
        await authService
            .requestLostUsername(email)
            .then(() => {
                ToastrService.success(
                    t('pages.authentication.lostUsername.successTitle'),
                    t('pages.authentication.lostUsername.successMessage')
                );
                history.push('/');
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(t('basic.xhrError'));
                }
            });
    };

    const handlePasswordReset = async ({
        email,
        token,
        password,
        password_confirmation // eslint-disable-line @typescript-eslint/camelcase
    }): Promise<void> => {
        await authService
            .resetPassword(email, token, password, password_confirmation)
            .then(() => {
                ToastrService.success(
                    t('pages.authentication.lostPassword.successSetTitle'),
                    t('pages.authentication.lostPassword.successSetMessage')
                );
                history.push('/');
            })
            .catch(() => {
                ToastrService.error(t('basic.xhrError'));
            });
    };

    const handleVerification = (): ReactNode => {
        const { success } = queryParams;

        if (success) {
            ToastrService.success(
                t('pages.authentication.registration.verificationSuccess')
            );
        } else {
            ToastrService.error(
                t('pages.authentication.registration.verificationError')
            );
        }

        return <Redirect to="/auth/login" />;
    };

    const handleRevert = (): ReactNode => {
        const { success } = queryParams;

        if (success) {
            ToastrService.success(
                t('pages.authentication.revert.revertSuccess')
            );
        } else {
            ToastrService.error(t('pages.authentication.revert.revertExpired'));
        }

        return <Redirect to="/auth/login" />;
    };

    const handleAdfsLogin = (): void => {
        authService
            .adfsLogin()
            .then(data => {
                window.location.href = data.data.url;
            })
            .catch(() => {
                ToastrService.error(
                    t('pages.authentication.login.loginFailed')
                );
            });
    };

    const adfsHandlerLogin = (): ReactNode => {
        const params = qs.parse(history.location.search);
        if (get(params, 'token')) {
            changeToken(get(params, 'token'));
            return <Redirect to="/" />;
        }
        return <Redirect to="/auth/login" />;
    };

    const getComponent = (): ReactNode => {
        switch (action) {
            case 'adfshandler':
                return adfsHandlerLogin();
            case 'verify':
                return handleVerification();
            case 'revert':
                return handleRevert();
            case 'register':
                return (
                    <RegisterForm
                        onSubmit={handleRegister}
                        inviteOnly={isFeatureEnabled('auth.invite-only')}
                    />
                );
            case 'forgot-password':
                return (
                    <PasswordResetForm
                        onResetRequest={requestPasswordReset}
                        onResetPassword={handlePasswordReset}
                        email={queryParams.email}
                        token={queryParams.token}
                    />
                );
            case 'forgot-username':
                return <LostUsernameForm onSubmit={requestLostUsername} />;
            case 'login':
            default:
                return (
                    <>
                        <LoginForm onSubmit={handleLogin} />
                        <AdfsLogin onLogin={handleAdfsLogin} />
                    </>
                );
        }
    };

    return (
        <Style>
            <Row justify="center" align="top">
                <Col xs={20} sm={15} md={10} lg={8} xl={5}>
                    {promiseInProgress ? (
                        <Spinner />
                    ) : (
                        <Card title={t('pages.authentication.cardTitle')}>
                            {getComponent()}
                        </Card>
                    )}
                </Col>
            </Row>
        </Style>
    );
};

export default Authentication;
