import * as Yup from 'yup';

Yup.setLocale({
    mixed: {
        default: 'validations.mixed.invalid',
        required: 'validations.mixed.required',
        oneOf: ({ values }): string => `validations.mixed.oneOf|${values}`,
        notOneOf: ({ values }): string =>
            `validations.mixed.notOneOf|${values}`,
        defined: 'validations.mixed.defined'
    },
    string: {
        length: ({ length }): string => `validations.string.length|${length}`,
        min: ({ min }): string => `validations.string.length|${min}`,
        max: ({ max }): string => `validations.string.max|${max}`,
        matches: ({ regex }): string => `validations.string.matches|${regex}`,
        email: 'validations.string.email',
        url: 'validations.string.url',
        uuid: 'validations.string.uuid',
        trim: 'validations.string.trim',
        lowercase: 'validations.string.lowercase',
        uppercase: 'validations.string.uppercase'
    },
    number: {
        min: ({ min }): string => `validations.number.length|${min}`,
        max: ({ max }): string => `validations.number.length|${max}`,
        lessThan: ({ less }): string => `validations.number.length|${less}`,
        moreThan: ({ more }): string => `validations.number.length|${more}`,
        notEqual: ({ notEqual }): string =>
            `validations.number.length|${notEqual}`,
        positive: 'validations.number.positive',
        negative: 'validations.number.negative',
        integer: 'validations.number.integer'
    },
    date: {
        min: ({ min }): string => `validations.date.length|${min}`,
        max: ({ max }): string => `validations.date.length|${max}`
    },
    object: {
        noUnknown: ({ unknown }): string =>
            `validations.object.noUnknown|${unknown}`
    },
    array: {
        min: ({ min }): string => `validations.array.length|${min}`,
        max: ({ max }): string => `validations.array.length|${max}`
    }
});

export default Yup;
