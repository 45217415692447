/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';
import PasswordRules from '~/shared/validation/password-rules';

export default Yup.object().shape({
    email: Yup.string()
        .email()
        .required('basic.validations.requiredEmail'),
    token: Yup.mixed().notRequired(),
    password: PasswordRules.when('token', {
        is: val => val !== false,
        then: Yup.string().required('basic.validations.requiredPassword'),
        otherwise: Yup.string().notRequired()
    }),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'basic.validations.passwordMustMatch'
    )
});
