import React from 'react';
import { Button } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';

import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';

interface AdfsLoginProps {
    onLogin: () => void;
}

const AdfsLogin: React.FC<AdfsLoginProps> = ({ onLogin }) => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    return (
        <div>
            <Button
                loading={promiseInProgress}
                type="primary"
                block
                onClick={onLogin}
            >
                {t('pages.authentication.login.adfsLogin')}
            </Button>
        </div>
    );
};

export default AdfsLogin;
