import React, { useContext } from 'react';
import { PermissionContext } from '~/shared/context/permission';
import { Document } from '~/types/services/content-service';
import PermissionKeysEnums from '~/types/shared/permission-keys-enums';
import MasterDataEnums from '~/types/shared/master-data-enums';

interface FeatureGateProps {
    permission: string | string[];
    permissionRoute: string | string[];
    inverse?: boolean;
    data?: Document;
}

const ContentPermissionGate: React.FC<FeatureGateProps> = ({
    permission,
    permissionRoute,
    inverse,
    data,
    children
}) => {
    const { userHasPermission } = useContext(PermissionContext);

    if (!userHasPermission(permission, permissionRoute) && !inverse) {
        return null;
    }

    if (
        data &&
        Array.isArray(permission) &&
        permission.includes(PermissionKeysEnums.Mubizonylat) &&
        permission.length === 1 &&
        data.type.key !== MasterDataEnums.Mubizonylatok
    ) {
        return null;
    }

    if (
        data &&
        Array.isArray(permission) &&
        !permission.includes(PermissionKeysEnums.Eloiras) &&
        permission.length > 1 &&
        data.group.key === 'specifikacio'
    ) {
        return null;
    }

    if (
        data &&
        Array.isArray(permission) &&
        (permission.includes(PermissionKeysEnums.Mubizonylat) ||
            permission.includes(PermissionKeysEnums.Egyeb)) &&
        data.group.key === 'egyeb'
    ) {
        return <>{children}</>;
    }

    if (
        data &&
        Array.isArray(permission) &&
        permission.includes(PermissionKeysEnums.Eloiras) &&
        data.group.key === 'specifikacio'
    ) {
        return <>{children}</>;
    }

    return <>{children}</>;
};

export default ContentPermissionGate;
