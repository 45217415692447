import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { User } from '~/types/services/user-service';
import UserService from '~/services/user-service';
import { AuthContext } from '~/shared/context/auth';
import { LanguageContext } from '~/shared/context/language';

interface ContextProps {
    user: User;
}

const userService = new UserService();

export const UserContext = createContext<ContextProps>({
    user: {}
} as ContextProps);

const UserStoreProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<User>();
    const { changeLanguage } = useContext(LanguageContext);
    const { jwt } = useContext(AuthContext);

    useEffect(() => {
        if (jwt) {
            userService.fetchMe().then(res => {
                changeLanguage(res.data.lang);
                return setUser(res.data);
            });
        }
    }, [changeLanguage, jwt]);

    const store = useMemo<ContextProps>(() => ({ user }), [user]);

    return (
        <UserContext.Provider value={store}>{children}</UserContext.Provider>
    );
};

export default UserStoreProvider;
