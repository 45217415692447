import React from 'react';
import { Card } from 'antd';
import { LabeledValue } from 'antd/es/select';
import EditForm from '~/components/EditForm';
import { Configuration } from '~/types/services/configurator-service';
import ConfigInput from '~/components/ConfigInput';

interface ModuleConfigFormProps {
    fetchOptions: Function;
    onSubmit: Function;
    loading: boolean;
    moduleName: string;
    configs: Configuration[];
}

const ModuleConfigForm: React.FC<ModuleConfigFormProps> = ({
    fetchOptions,
    moduleName,
    configs,
    onSubmit,
    loading
}) => {
    return (
        <EditForm noFormik>
            <Card title={moduleName} key={moduleName}>
                {configs.map(config => (
                    <ConfigInput
                        key={config.id}
                        loading={loading}
                        onChange={onSubmit}
                        onFetchOptions={(search): Promise<LabeledValue[]> =>
                            fetchOptions(config.id, search)
                        }
                        configId={config.id}
                        type={config.type}
                        name={config.name}
                        value={config.value}
                        defaultOptions={config.default_options}
                    />
                ))}
            </Card>
        </EditForm>
    );
};

export default ModuleConfigForm;
