import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfiguratorService from '~/services/configurator-service';
import { Configuration } from '~/types/services/configurator-service';

interface ContextProps {
    configurations: Configuration[];
    getConfig: (key: string) => Configuration | DefaultConfigValue;
}

interface DefaultConfigValue {
    value: false;
}

const configuratorService = new ConfiguratorService();

export const ConfiguratorContext = createContext<ContextProps>({
    configurations: [],
    getConfig: () => {
        return null;
    }
} as ContextProps);

const ConfiguratorStoreProvider: React.FC = ({ children }) => {
    const [configurations, setConfigurations] = useState([]);
    const location = useLocation().pathname;

    useEffect(() => {
        configuratorService.fetchConfigurationList().then(res => {
            setConfigurations(res.data);
        });
    }, [location]);

    const getConfig = (key: string): Configuration | DefaultConfigValue =>
        configurations.find(config => {
            return config.key === key;
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const store = useMemo<ContextProps>(() => ({ configurations, getConfig }), [
        configurations
    ]);
    return (
        <ConfiguratorContext.Provider value={store}>
            {children}
        </ConfiguratorContext.Provider>
    );
};

export default ConfiguratorStoreProvider;
