/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import styled from 'styled-components';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import LostUsernameSchema from '~/pages/Authentication/components/LostUsernameForm.validation';
import EditForm from '~/components/EditForm';

const Text = styled.div`
    width: 100%;
    text-align: right;
    padding: 5px 5px;
`;

interface LostUsernameFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
}

const LostUsernameForm: React.FC<LostUsernameFormProps> = ({ onSubmit }) => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    return (
        <Formik
            validationSchema={LostUsernameSchema}
            onSubmit={onSubmit}
            initialValues={{
                email: ''
            }}
        >
            <EditForm disableformItemLayout>
                <Form.Item name="email" required>
                    <Input
                        type="email"
                        name="email"
                        prefix={<MailOutlined />}
                        placeholder={t('basic.fields.email')}
                    />
                </Form.Item>
                <SubmitButton loading={promiseInProgress} type="primary" block>
                    {t('pages.authentication.lostUsername.sendMyUsername')}
                </SubmitButton>
                <Text>
                    <Link to="/auth/login">
                        {t('pages.authentication.login.backToLogin')}
                    </Link>
                </Text>
            </EditForm>
        </Formik>
    );
};

export default LostUsernameForm;
