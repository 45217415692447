import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { MasterData } from '~/types/services/masterdata-service';
import Spinner from '~/components/Spinner';
import EditFormSchema from './EditForm.validation';
import EditFormWrapper from '~/components/EditForm';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    masterData?: MasterData;
    loading: boolean;
}

const EditForm: React.FC<EditFormProps> = ({
    onSubmit,
    masterData,
    loading
}) => {
    const { t } = useTranslation();

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (!masterData) return <Spinner />;

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={masterData}
        >
            <EditFormWrapper disableformItemLayout>
                <Form.Item
                    name="label"
                    label={t('pages.masterData.fields.label')}
                    required
                >
                    <Input
                        name="label"
                        placeholder={t('pages.masterData.fields.label')}
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t('pages.masterData.fields.description')}
                >
                    <Input.TextArea
                        name="description"
                        placeholder={t('pages.masterData.fields.description')}
                    />
                </Form.Item>
                <Form.Item name="submit" {...buttonItemLayout}>
                    <SubmitButton type="primary" loading={loading}>
                        {t('basic.saveButton')}
                    </SubmitButton>
                </Form.Item>
            </EditFormWrapper>
        </Formik>
    );
};

export default EditForm;
