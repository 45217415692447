import React from 'react';
import { Form, Switch } from 'formik-antd';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderSwitchProps {
    field: FormBuilderField;
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderSwitch: React.FC<BuilderSwitchProps> = ({
    field,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);

    return (
        <Form.Item
            name={fieldName}
            label={field.label}
            key={field.id}
            required={field.required}
        >
            <Switch
                name={fieldName}
                data-testid={fieldName}
                checked={get(getFieldMeta(fieldName), 'value', false)}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default BuilderSwitch;
