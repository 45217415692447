import React, { ReactNode } from 'react';
import SentryService from '~/services/sentry-service';

interface SentryProps {
    children: ReactNode;
}

interface SentryStates {
    hasError: boolean;
}

class Sentry extends React.Component<SentryProps, SentryStates> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
        SentryService.sentryInit();
    }

    static getDerivedStateFromError(): object {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        SentryService.sentryCaptureException(error, errorInfo);
    }

    render(): ReactNode {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError && process.env.SENTRY_DSN !== 'false') {
            return <h1>Looks like something went wrong.</h1>;
        }

        return children;
    }
}

export default Sentry;
