import React from 'react';
import * as Sentry from '@sentry/browser';

export default class SentryService {
    static sentryInit(): void {
        if (
            process.env.NODE_ENV === 'production' &&
            process.env.SENTRY_DSN !== 'false'
        ) {
            Sentry.init({
                dsn: process.env.SENTRY_DSN,
                environment: process.env.NODE_ENV
            });
        }
    }

    static sentryCaptureException(
        error: Error,
        errorInfo: React.ErrorInfo
    ): void {
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }
}
