import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { Features, DetailedFeatures } from '~/types/services/feature-service';
import { ApiResponse } from '~/types/services/common';

export default class FeatureGateService extends BaseService {
    constructor() {
        super(ServiceEnums.FeatureGate);
    }

    fetchFeatures(): Promise<Features> {
        return this.getRequest();
    }

    fetchDetailedFeatures(): Promise<ApiResponse<DetailedFeatures>> {
        return this.getRequest('details');
    }

    enableFeature(id: number, area: string): Promise<void> {
        return this.postRequest(
            `${id}`,
            {
                enable: true
            },
            area
        );
    }

    disableFeature(id: number, area: string): Promise<void> {
        return this.postRequest(
            `${id}`,
            {
                enable: false
            },
            area
        );
    }
}
