import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import EditForm from '~/components/EditForm';
import ChangePasswordFormSchema from './ChangePasswordForm.validation';

interface ChangePasswordFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    loading: boolean;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
    onSubmit,
    loading
}) => {
    const { t } = useTranslation();
    const initialValue = {};

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return (
        <Formik
            validationSchema={ChangePasswordFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={initialValue}
        >
            <EditForm>
                <Form.Item
                    name="current_password"
                    label={t('pages.users.fields.currentPassword')}
                    required
                >
                    <Input.Password
                        name="current_password"
                        placeholder={t('pages.users.fields.currentPassword')}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('pages.users.fields.newPassword')}
                >
                    <Input.Password
                        name="password"
                        placeholder={t('pages.users.fields.newPassword')}
                    />
                </Form.Item>
                <Form.Item
                    name="password_confirmation"
                    label={t('pages.users.fields.newPasswordConfirm')}
                >
                    <Input.Password
                        name="password_confirmation"
                        placeholder={t('pages.users.fields.newPasswordConfirm')}
                    />
                </Form.Item>
                <Form.Item name="submit" {...buttonItemLayout}>
                    <SubmitButton type="primary" loading={loading}>
                        {t('pages.users.profile.changePasswordBtn')}
                    </SubmitButton>
                </Form.Item>
            </EditForm>
        </Formik>
    );
};

export default ChangePasswordForm;
