import React, { useContext } from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import styled from 'styled-components';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import LoginSchema from '~/pages/Authentication/components/LoginForm.validation';
import FeatureGate from '~/containers/FeatureGate';
import { ConfiguratorContext } from '~/shared/context/configurator';
import EditForm from '~/components/EditForm';

const Text = styled.div`
    width: 100%;
    text-align: right;
    padding: 5px 5px;
`;

interface LoginFormProps {
    onSubmit: (values) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    const configurations = useContext(ConfiguratorContext);

    const configuration = configurations.getConfig('login.credentials');
    const isEmailLogin = get(configuration, 'value.0.value', true);

    const renderLoginInput = (): JSX.Element =>
        +isEmailLogin ? (
            <Form.Item name="email">
                <Input
                    type="email"
                    name="email"
                    prefix={<MailOutlined />}
                    placeholder={t('basic.fields.email')}
                />
            </Form.Item>
        ) : (
            <Form.Item name="username">
                <Input
                    type="text"
                    name="username"
                    placeholder={t('basic.fields.username')}
                    prefix={<UserOutlined />}
                />
            </Form.Item>
        );

    return (
        <Formik
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
            initialValues={{
                email: '',
                username: '',
                password: '',
                isEmailLogin: isEmailLogin || 1
            }}
        >
            <EditForm disableformItemLayout>
                <Input
                    name="isEmailLogin"
                    type="hidden"
                    placeholder="isEmailLogin"
                />
                {renderLoginInput()}
                <Form.Item name="password" required>
                    <Input.Password
                        name="password"
                        prefix={<LockOutlined />}
                        placeholder={t('basic.fields.password')}
                    />
                </Form.Item>
                <Text>
                    <Link to="/auth/forgot-password">
                        {t('pages.authentication.lostPassword.forgotPassword')}
                    </Link>
                </Text>
                {isEmailLogin === '0' && (
                    <Text>
                        <Link to="/auth/forgot-username">
                            {t(
                                'pages.authentication.lostUsername.forgotUsername'
                            )}
                        </Link>
                    </Text>
                )}
                <SubmitButton loading={promiseInProgress} type="primary" block>
                    {t('pages.authentication.login.login')}
                </SubmitButton>
                <FeatureGate feature="auth.invite-only" inverse>
                    <Text>
                        <Link to="/auth/register">
                            {t('pages.authentication.registration.register')}
                        </Link>
                    </Text>
                </FeatureGate>
            </EditForm>
        </Formik>
    );
};

export default LoginForm;
