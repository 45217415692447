import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import DocumentEditForm from './components/DocumentEditForm';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import ContentService from '~/services/content-service';
import { Document } from '~/types/services/content-service';
import setFieldBackendError, {
    getBuilderKeyByErrors,
    isSearchedError
} from '~/shared/helpers/backend-responses';
import { ApiResponse } from '~/types/services/common';
import getSelectedDocumentGroup from '~/shared/helpers/document-group';
import ApiErrorMessageEnums from '~/types/shared/api-error-message-enums';

const contentService = new ContentService();

const DocumentEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: documentId } = useParams();
    const [document, setDocument] = useState<Document>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Content
    });

    const selectedDocumentGroup = getSelectedDocumentGroup(
        history.location.pathname
    );

    const isEditing = documentId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Document>> =>
            isViewing
                ? contentService.viewDocument(documentId)
                : contentService.fetchDocument(documentId);

        promise()
            .then(res => setDocument(res.data))
            .catch((error: AxiosError) => {
                ToastrService.error(
                    t(
                        error.response.status === 403
                            ? 'pages.content.edit.noPermission'
                            : 'pages.content.edit.notFound'
                    )
                );
                history.push(`/content/${selectedDocumentGroup}`);
            });
    }, [history, documentId, isEditing, isViewing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDocumentArchive = (
        code,
        values,
        helpers,
        submitCallback
    ): void => {
        contentService
            .archiveDocumentByCode(code)
            .then(() => submitCallback(values, helpers));
    };

    const handleCodeError = (errors, values, helpers, submitCallback): void => {
        const codeBuilderKey = getBuilderKeyByErrors(
            errors,
            ApiErrorMessageEnums.DocumentCode
        );
        Modal.confirm({
            title: ApiErrorMessageEnums.DocumentCode,
            icon: <ExclamationCircleOutlined />,
            content: t('basic.archiveDocumentConfirmText'),
            okText: t('basic.archiveDocumentOkText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleDocumentArchive(
                    values.builder[codeBuilderKey],
                    values,
                    helpers,
                    submitCallback
                );
            }
        });
    };

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? contentService.updateDocument(documentId, values)
                    : contentService.createDocument(values);

            promise()
                .then(() => {
                    ToastrService.success(t('pages.content.edit.successSave'));
                    history.push(`/content/${selectedDocumentGroup}`);
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        if (
                            isSearchedError(
                                e.response.data.errors,
                                ApiErrorMessageEnums.DocumentCode
                            ) &&
                            Object.keys(e.response.data.errors).length === 1
                        ) {
                            handleCodeError(
                                e.response.data.errors,
                                values,
                                helpers,
                                handleSubmit
                            );
                        }
                        setFieldBackendError(e.response.data, helpers);
                        ToastrService.error(
                            t('pages.content.edit.validationError')
                        );
                    } else {
                        ToastrService.error(t('pages.content.edit.failedSave'));
                    }
                });
        }
    };

    const action = isViewing
        ? t('pages.content.edit.viewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.editTitle'
                  : 'pages.content.edit.createTitle'
          );
    return (
        <>
            <h1>{action}</h1>
            <DocumentEditForm
                isEditing={isEditing}
                isViewing={isViewing}
                onSubmit={handleSubmit}
                document={document}
                loading={promiseInProgress}
            />
        </>
    );
};

export default DocumentEditPage;
