import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Document, DocumentSelect } from '~/types/services/content-service';

export default class ContentService extends BaseService {
    constructor() {
        super(ServiceEnums.Content);
    }

    fetchDocuments(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Document>> {
        return this.getRequest('documents', {
            filter,
            sort,
            page
        });
    }

    fetchDocument(id: string | number): Promise<ApiResponse<Document>> {
        return this.getRequest(`documents/${id}`);
    }

    viewDocument(id: string | number): Promise<ApiResponse<Document>> {
        return this.getRequest(`documents/view/${id}`);
    }

    exportDocuments(filter: object, sort: object): void {
        this.download('documents/export', {
            filter,
            sort
        });
    }

    updateDocument<T>(id: string | number, data: Document): Promise<T> {
        return this.putRequest(`documents/${id}`, data);
    }

    createDocument<T>(data: Document): Promise<T> {
        return this.postRequest(`documents`, data);
    }

    deleteDocuments<T>(documentIds: string[] | number[]): Promise<T> {
        return this.deleteRequest('documents', {
            documents: documentIds
        });
    }

    fetchContentByCode(
        code: string,
        area?: string
    ): Promise<ApiResponse<DocumentSelect>> {
        return this.getRequest(`documents/code/${code}`, null, area);
    }

    archiveDocumentByCode<T>(code: string): Promise<T> {
        return this.putRequest(`documents/code/archive`, {
            documentCode: code
        });
    }
}
