/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import styled from 'styled-components';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import PasswordResetSchema from '~/pages/Authentication/components/PasswordResetForm.validation';
import FeatureGate from '~/containers/FeatureGate';
import EditForm from '~/components/EditForm';

const Text = styled.div`
    width: 100%;
    text-align: right;
    padding: 5px 5px;
`;

interface PasswordResetFormProps {
    onResetRequest: (values, helpers: FormikHelpers<[]>) => void;
    onResetPassword: (values) => void;
    email?: string | string[];
    token?: string | string[];
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
    onResetPassword,
    onResetRequest,
    email = '',
    token = false
}) => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    return (
        <Formik
            validationSchema={PasswordResetSchema}
            onSubmit={token ? onResetPassword : onResetRequest}
            initialValues={{
                email,
                password: '',
                password_confirmation: '',
                token
            }}
        >
            <EditForm disableformItemLayout>
                <Form.Item name="email" required>
                    <Input
                        disabled={!!token}
                        type="email"
                        name="email"
                        prefix={<MailOutlined />}
                        placeholder={t('basic.fields.email')}
                    />
                </Form.Item>
                {token && (
                    <>
                        <Form.Item name="password" required>
                            <Input.Password
                                name="password"
                                prefix={<LockOutlined />}
                                placeholder={t('basic.fields.password')}
                            />
                        </Form.Item>
                        <Form.Item name="password_confirmation" required>
                            <Input.Password
                                name="password_confirmation"
                                prefix={<LockOutlined />}
                                placeholder={t(
                                    'basic.fields.passwordConfirmation'
                                )}
                            />
                        </Form.Item>
                    </>
                )}
                <SubmitButton loading={promiseInProgress} type="primary" block>
                    {t('pages.authentication.lostPassword.resetMyPassword')}
                </SubmitButton>
                <FeatureGate feature="auth.invite-only" inverse>
                    <Text>
                        <Link to="/auth/login">
                            {t('pages.authentication.login.backToLogin')}
                        </Link>
                    </Text>
                </FeatureGate>
            </EditForm>
        </Formik>
    );
};

export default PasswordResetForm;
