import React, { useContext, useEffect } from 'react';
import { DatePicker } from 'formik-antd';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import { ConfiguratorContext } from '~/shared/context/configurator';
import DateFormatEnums from '~/types/shared/date-format-enums';

interface DatePickerProps {
    name: string;
    placeholder: string;
    locale: PickerLocale;
    disabled?: boolean;
    disabledDate?: (current: Moment) => boolean;
    setFieldValue: (field: string, value) => void;
    initialValue?: string;
}

const DatePickerConfigFormat: React.FC<DatePickerProps> = ({
    name,
    placeholder,
    locale,
    disabled,
    disabledDate,
    setFieldValue,
    initialValue
}) => {
    const configurations = useContext(ConfiguratorContext);
    const configuration = configurations.getConfig('content.datepicker');
    const onlyDate = get(configuration, 'value');

    useEffect(() => {
        const formatDate =
            initialValue &&
            moment(initialValue).format(DateFormatEnums.DateTimeFormat);
        setFieldValue(name, formatDate);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (date, dateString): void => {
        if (!dateString) {
            setFieldValue(name, '');
            return;
        }
        const formatDate = moment(dateString).format(
            onlyDate
                ? DateFormatEnums.DateFormat
                : DateFormatEnums.DateTimeFormat
        );
        setFieldValue(name, formatDate);
    };

    return (
        <DatePicker
            onChange={onChange}
            disabledDate={disabledDate}
            showTime={!onlyDate && { format: 'HH-mm' }}
            format={
                onlyDate
                    ? DateFormatEnums.DateFormat
                    : DateFormatEnums.DateTimeFormat
            }
            name={name}
            placeholder={placeholder}
            locale={locale}
            disabled={disabled}
        />
    );
};

export default DatePickerConfigFormat;
