import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import {
    Configuration,
    ConfigurationValue
} from '~/types/services/configurator-service';
import { ApiResponse } from '~/types/services/common';

export default class ConfiguratorService extends BaseService {
    constructor() {
        super(ServiceEnums.Configurator);
    }

    fetchConfigurationsByModule(module): Promise<Configuration[]> {
        return this.getRequest('', {
            module
        });
    }

    fetchConfigurationList(): Promise<ApiResponse<Configuration[]>> {
        return this.getRequest('');
    }

    fetchConfigurationDetailedList(): Promise<ApiResponse<Configuration[]>> {
        return this.getRequest('details');
    }

    setConfiguration<T>(id, value): Promise<T> {
        return this.putRequest(`${id}`, {
            value
        });
    }

    fetchConfiguration(key: string): Promise<ApiResponse<Configuration>> {
        return this.getRequest(`${key}`);
    }

    fetchConfigurationOptions(
        id: number,
        search: string = null
    ): Promise<ApiResponse<ConfigurationValue[]>> {
        return this.getRequest(`${id}/options`, {
            search
        });
    }
}
