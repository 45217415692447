import React from 'react';
import { Form, Select } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderTagProps {
    field: FormBuilderField;
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderTag: React.FC<BuilderTagProps> = ({
    field,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const value = get(getFieldMeta(fieldName), 'value', []) || [];

    return (
        <Form.Item
            htmlFor={fieldName}
            name={getFieldName(field.id)}
            label={field.label}
            key={field.id}
            required={field.required}
        >
            <Select
                id={fieldName}
                labelInValue
                mode="tags"
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={value}
                onBlur={onBlur}
                notFoundContent={null}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default BuilderTag;
