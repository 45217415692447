import React from 'react';
import { Form } from 'formik-antd';
import { InputNumber } from 'antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField, NumberMeta } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderTextProps {
    field: FormBuilderField;
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderNumber: React.FC<BuilderTextProps> = ({
    field,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const meta = field.meta as NumberMeta;
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);

    return (
        <Form.Item
            htmlFor={fieldName}
            name={fieldName}
            label={field.label}
            required={field.required}
            key={field.id}
        >
            <InputNumber
                id={fieldName}
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={
                    get(getFieldMeta(fieldName), 'value') ||
                    get(field, 'values.0')
                }
                onChange={(value): void => setFieldValue(fieldName, value)}
                onBlur={onBlur}
                min={meta.min}
                max={meta.max || undefined}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default BuilderNumber;
