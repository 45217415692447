import React from 'react';
import AjaxSelect from '~/components/AjaxSelect';

const filterAjaxSelect = (onSearch): object => {
    return {
        filterDropdown: ({ setSelectedKeys, confirm }): React.ReactNode => (
            <div style={{ padding: 8 }}>
                <AjaxSelect
                    multiple={false}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    onSearch={onSearch}
                    onSelect={(value): void => {
                        setSelectedKeys([value.value] || null);
                        confirm();
                    }}
                    onDeselect={(value): void => {
                        setSelectedKeys([value.value] || null);
                        confirm();
                    }}
                    onChange={(value): void => {
                        if (!value) {
                            setSelectedKeys(null);
                            confirm();
                        }
                    }}
                    allowClear
                />
            </div>
        )
    };
};

export default filterAjaxSelect;
