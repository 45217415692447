import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import UserService from '~/services/user-service';
import ToastrService from '~/services/toastr-service';
import { UserContext } from '~/shared/context/user';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import filterInput from '~/components/Table/components/filterInput';
import filterDateRange from '~/components/Table/components/filterDateRange';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import { FeatureContext } from '~/shared/context/feature';
import {
    FilterDataInterface,
    filterDropdown
} from '~/components/Table/components/filterDropdown';
import UserInvite from '~/pages/User/Invite';
import UserStatusEnums from '~/types/shared/user-status-enums';
import Title from '~/components/Title';

const UserServiceInstance = new UserService();

const UserListPage: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useContext(FeatureContext);
    const { user } = useContext(UserContext);
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [showInviteForm, setShowInviteForm] = useState(false);

    const fetchUsers = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        UserServiceInstance.fetchUsers(filters, orders, page).then(res => {
            setUsers(res.data);
            setTotal(res.total);
        });
    };

    const exportUsers = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        UserServiceInstance.exportUsers(filters, orders);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleUserDelete = (selectedRows): void => {
        if (selectedRows.indexOf(user.id) >= 0) {
            ToastrService.error(
                t('pages.users.list.mySelfDeleteTitle'),
                t('pages.users.list.mySelfDeleteMessage')
            );
            return;
        }

        UserServiceInstance.deleteUsers(selectedRows).then(() => fetchUsers());
    };

    const handleInviteWindowClose = (): void => {
        setShowInviteForm(false);
        fetchUsers();
    };

    const showUserDeleteConfirm = (selectedRows: number[]): void => {
        Modal.confirm({
            title: t('pages.users.list.confirmDeleteTitle'),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.users.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleUserDelete(selectedRows);
            }
        });
    };

    const actions: ActionProps[] = [
        {
            label: t('basic.deleteSelectedButton'),
            type: 'primary',
            danger: true,
            multiple: true,
            onClick: showUserDeleteConfirm
        },
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportUsers
        }
    ];

    const statusFilters: FilterDataInterface[] = [
        {
            text: '',
            value: ''
        },
        {
            text: t('pages.users.list.statusActive'),
            value: UserStatusEnums.Active
        },
        {
            text: t('pages.users.list.statusInactive'),
            value: UserStatusEnums.Inactive
        }
    ];
    if (!isFeatureEnabled('auth.invite-only')) {
        statusFilters.push({
            text: t('pages.users.list.statusNew'),
            value: UserStatusEnums.New
        });
        statusFilters.push({
            text: t('pages.users.list.statusWaiting'),
            value: UserStatusEnums.Waiting
        });
    } else {
        statusFilters.push({
            text: t('pages.users.list.statusPending'),
            value: UserStatusEnums.Pending
        });
        actions.push({
            label: t('pages.users.list.inviteBtn'),
            onClick: () => {
                setShowInviteForm(true);
            }
        });
    }

    const columns = [
        {
            title: t('pages.users.fields.name'),
            dataIndex: 'name',
            sorter: true,
            render: (text, record): string =>
                `${record.last_name} ${record.first_name}`,
            ...filterInput
        },
        {
            title: t('basic.fields.email'),
            dataIndex: 'email',
            sorter: true,
            ...filterInput
        },
        {
            title: t('pages.users.fields.createdAt'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('L LT')
                    .toString(),
            ...filterDateRange
        },
        {
            title: t('pages.users.fields.status'),
            dataIndex: 'status',
            sorter: true,
            render: (text): string => {
                let status = '';
                switch (text) {
                    case UserStatusEnums.Active:
                        status = t('pages.users.list.statusActive');
                        break;
                    case UserStatusEnums.Inactive:
                        status = t('pages.users.list.statusInactive');
                        break;
                    case UserStatusEnums.Waiting:
                        status = t('pages.users.list.statusWaiting');
                        break;
                    case UserStatusEnums.Pending:
                        status = t('pages.users.list.statusPending');
                        break;
                    case UserStatusEnums.New:
                    default:
                        status = t('pages.users.list.statusNew');
                }
                return status;
            },
            ...filterDropdown(statusFilters)
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record): React.ReactNode => (
                <ActionButtonWrapper>
                    <Button
                        danger
                        onClick={(): void => showUserDeleteConfirm([record.id])}
                    >
                        {record.status === UserStatusEnums.Pending
                            ? t('pages.users.list.revokeBnt')
                            : t('basic.deleteButton')}
                    </Button>
                    {record.status !== UserStatusEnums.Pending && (
                        <Link to={`/admin/users/${record.id}`}>
                            <Button>{t('basic.editButton')}</Button>
                        </Link>
                    )}
                </ActionButtonWrapper>
            )
        }
    ];

    return (
        <div>
            <Title type="primary">{t('pages.users.list.title')}</Title>
            <Table
                rowKey="id"
                loading={promiseInProgress}
                columns={columns}
                dataSource={users}
                actions={actions}
                fetchSource={fetchUsers}
                total={total}
                pageSize={50}
            />
            {showInviteForm && (
                <UserInvite handleClose={handleInviteWindowClose} />
            )}
        </div>
    );
};

export default UserListPage;
