import React, { useEffect } from 'react';
import { Form as FormikForm } from 'formik-antd';
import { Form as AntdForm } from 'antd';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import forIn from 'lodash/forIn';

interface EditFormProps {
    noFormik?: boolean;
    children: React.ReactNode;
    disableformItemLayout?: boolean;
}

const EditForm: React.FC<EditFormProps> = ({
    noFormik,
    children,
    disableformItemLayout
}) => {
    const { t } = useTranslation();
    const { errors, setFieldError } = noFormik
        ? { errors: null, setFieldError: null }
        : useFormikContext(); // eslint-disable-line react-hooks/rules-of-hooks
    const formItemLayout = disableformItemLayout
        ? {}
        : {
              labelCol: { md: { span: 10 }, sm: { span: 24 } },
              wrapperCol: { md: { span: 10 }, sm: { span: 24 } }
          };
    const Form = noFormik ? AntdForm : FormikForm;
    useEffect(() => {
        if (!noFormik) {
            forIn(errors, (value, key) => {
                let translatedError;
                if (value instanceof Object) {
                    setFieldError(key, value);
                    return;
                }

                if (value.indexOf('|') > -1) {
                    const splittedErrorMsg = value.split('|');
                    translatedError = t(splittedErrorMsg[0], {
                        param: splittedErrorMsg[1]
                    });
                } else {
                    translatedError = t(value);
                }
                if (value !== translatedError) {
                    setFieldError(key, translatedError);
                }
            });
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form {...formItemLayout} layout="vertical">
            {children}
        </Form>
    );
};

export default EditForm;
