import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import {
    Group,
    GroupMember,
    GroupRole,
    Permission,
    Role
} from '~/types/services/permission-service';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';

export default class PermissionService extends BaseService {
    constructor() {
        super(ServiceEnums.Permission);
    }

    fetchRoles(
        filter: object = {},
        sort: object = {},
        page: number = null
    ): Promise<PaginatedResponse<Role>> {
        return this.getRequest(
            'roles',
            {
                filter,
                sort,
                page
            },
            ServiceEnums.Permission
        );
    }

    fetchRole(id: string | number): Promise<ApiResponse<Role>> {
        return this.getRequest(`roles/${id}`, ServiceEnums.Permission);
    }

    updateRole<T>(id: string | number, data: Role): Promise<T> {
        return this.putRequest(`roles/${id}`, data);
    }

    createRole<T>(data: Role): Promise<T> {
        return this.postRequest(`roles`, data);
    }

    deleteRoles<T>(roleIds: string[] | number[]): Promise<T> {
        return this.deleteRequest('roles', {
            roles: roleIds
        });
    }

    fetchGroups(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Group>> {
        return this.getRequest(
            'groups',
            {
                filter,
                sort,
                page
            },
            ServiceEnums.Permission
        );
    }

    exportGroups(filter: object, sort: object): void {
        this.download(
            'groups/export',
            {
                filter,
                sort
            },
            ServiceEnums.Permission
        );
    }

    fetchGroup(id: string | number): Promise<ApiResponse<Group>> {
        return this.getRequest(`groups/${id}`, ServiceEnums.Permission);
    }

    fetchGroupRoles(id: string | number): Promise<ApiResponse<GroupRole[]>> {
        return this.getRequest(`groups/${id}/roles`, ServiceEnums.Permission);
    }

    fetchGroupMembers(
        id: string | number
    ): Promise<ApiResponse<GroupMember[]>> {
        return this.getRequest(`groups/${id}/members`, ServiceEnums.Permission);
    }

    updateGroup<T>(id: string | number, data: Group): Promise<T> {
        return this.putRequest(`groups/${id}`, data);
    }

    createGroup<T>(data: Group): Promise<T> {
        return this.postRequest(`groups`, data);
    }

    fetchUserPermissions(): Promise<ApiResponse<Permission[]>> {
        return this.getRequest('available');
    }

    deleteGroups<T>(groupIds: string[] | number[]): Promise<T> {
        return this.deleteRequest('groups', {
            groups: groupIds
        });
    }

    listPermission(filter: object): Promise<ApiResponse<Permission[]>> {
        return this.getRequest('', {
            filter
        });
    }

    getRolePermissions(roleId: number): Promise<ApiResponse<Permission[]>> {
        return this.getRequest(`roles/${roleId}/permissions`);
    }
}
