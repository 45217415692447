import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import { Input, Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '~/components/Table/types/ActionProps';
import MassActions from '~/components/Table/components/MassActions';

interface TableProps {
    rowKey: string;
    loading: boolean;
    total: number;
    columns: ColumnsType<never>;
    dataSource: object[];
    actions: ActionProps[];
    fetchSource: (filters: object, sort: object, page: number) => void;
    globalSearchable?: boolean;
    pageSize?: number;
}

const Table: React.FC<TableProps> = ({
    rowKey,
    loading,
    total,
    columns,
    dataSource,
    actions,
    fetchSource,
    globalSearchable,
    pageSize = 10
}) => {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({});
    const [filters, setFilters] = useState([]);
    const [search, setSearch] = useState();
    const [keyCounter, setKeyCounter] = useState(1);

    useEffect(() => {
        setSelectedRows([]);
    }, [dataSource]);

    useEffect(() => {
        fetchSource(
            search
                ? {
                      ...filters,
                      search
                  }
                : filters,
            sort,
            page
        );
    }, [filters, sort, page, search]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectChange = (selectedRowKeys: []): void => {
        setSelectedRows(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: onSelectChange
    };

    const onChange = (pagination, filter, sorter): void => {
        const newSort = {
            0: {
                field:
                    (sorter.order && (sorter.field || sorter.column.key)) ||
                    null,
                order:
                    (sorter.order &&
                        (sorter.order === 'ascend' ? 'ASC' : 'DESC')) ||
                    null
            }
        };
        if (pagination.current !== page) {
            setPage(pagination.current);
            return;
        }
        if (!isEqual(sort, newSort)) {
            setSort(newSort);
            setPage(1);
        }
        if (!isEqual(filters, filter)) {
            setFilters(filter);
            setPage(1);
        }
    };

    const tableActions = concat(
        [
            {
                label: t('basic.removeFilterButton'),
                type: 'dashed',
                onClick: (): void => {
                    onChange({ current: 1 }, [], {});
                    setKeyCounter(keyCounter + 1);
                },
                disabled: filters.length === 0 && Object.keys(sort).length === 0
            }
        ],
        actions
    );

    const searching = (value): void => {
        setSearch(value);
        setPage(1);
    };

    return (
        <>
            <MassActions
                selectedRows={selectedRows}
                filters={filters}
                sort={sort}
                loading={loading}
                actions={tableActions}
            />
            {globalSearchable && (
                <Input.Search
                    placeholder={t('basic.search')}
                    style={{ marginBottom: 16 }}
                    onBlur={(value): void => searching(value.target.value)}
                    enterButton
                    onSearch={(value): void => searching(value)}
                />
            )}
            <AntTable
                key={`table${keyCounter}`}
                scroll={{ x: 400 }}
                rowSelection={rowSelection}
                rowKey={rowKey}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                onChange={onChange}
                pagination={{
                    pageSize,
                    total,
                    current: page
                }}
            />
        </>
    );
};

export default Table;
