import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import EditFormSchema from './EditForm.validation';
import { Document } from '~/types/services/content-service';
import MasterDataSelect from '~/components/MasterDataSelect';
import ServiceEnums from '~/types/shared/service-enums';
import TaxonomySelect from '~/components/TaxonomySelect';
import OwnerSelector from '~/pages/Content/Edit/components/OwnerSelector';
import ViewerSelector from '~/pages/Content/Edit/components/ViewerSelector';
import {
    MasterData,
    MasterDataSelectType,
    MasterDataSelectTypeWithKey
} from '~/types/services/masterdata-service';
import FormBuilder from '~/containers/FormBuilder';
import { LanguageContext } from '~/shared/context/language';
import DatePickerConfigFormat from '~/components/DatePicker';
import MasterDataService from '~/services/masterdata-service';
import MasterDataEnums from '~/types/shared/master-data-enums';
import getSelectedDocumentGroup from '~/shared/helpers/document-group';
import TaxonomyService from '~/services/taxonomy-service';
import ContentService from '~/services/content-service';
import FormBuilderArray from '~/containers/FormBuilder/FormBuilderArray';

const masterDataService = new MasterDataService();
const taxonomyService = new TaxonomyService();
const contentService = new ContentService();

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    document: Document;
    isEditing: boolean;
    isViewing: boolean;
    loading: boolean;
}

const DocumentEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    document,
    loading,
    isEditing,
    isViewing
}) => {
    const [groups, setGroups] = useState<MasterDataSelectTypeWithKey[]>();
    const [selectedTypeKey, setSelectedTypeKey] = useState<
        string | number | null
    >();
    const [pathSelectedGroup, setPathSelectedGroup] = useState<
        MasterDataSelectTypeWithKey
    >();
    const [documentBuilderName, setDocumentBuilderName] = useState<
        string | null
    >(null);
    const { t } = useTranslation();
    const history = useHistory();
    const { selectedLanguage } = useContext(LanguageContext);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    const selectedDocumentGroup = getSelectedDocumentGroup(
        history.location.pathname
    );

    useEffect(() => {
        if (!groups) {
            masterDataService
                .fetchMasterDatasByType('content', 'document_group')
                .then(res => {
                    const data = res.data.map((mData: MasterData) => {
                        if (mData.key === selectedDocumentGroup) {
                            setPathSelectedGroup({
                                id: mData.id,
                                value: mData.id,
                                label: mData.label,
                                key: mData.key
                            });
                        }
                        return {
                            value: mData.id,
                            label: mData.label,
                            key: mData.key
                        };
                    });
                    setGroups(data);
                });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (document) {
            setSelectedTypeKey(get(document, 'type.key', null));
        }
    }, [document]);

    if ((!document && isEditing) || !groups) return <Spinner />;

    const initialOwners = get(document, 'owners', []).map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`
    }));
    const initialOwnerIds = initialOwners.map(owner => owner.value);

    const initialViewers = get(document, 'viewers', []).map(group => ({
        value: group.id,
        label: group.name
    }));
    const initialViewerIds = initialViewers.map(group => group.value);

    const initialStatus = get(document, 'status') as MasterData;
    const initialType = get(document, 'type') as MasterData;
    const initialGroup = get(
        document,
        'group',
        pathSelectedGroup || false
    ) as MasterData;

    const getSelectedGroupKey = (
        group: MasterDataSelectType
    ): string | number | null => {
        if (groups && group) {
            const selectedGroup = groups.filter(g => g.value === group.value);
            if (selectedGroup) {
                return selectedGroup[0].key;
            }
        }
        return null;
    };

    const handleCodeChange = (value: string, setFieldValue): void => {
        if (selectedTypeKey === MasterDataEnums.HelType) {
            const contentCode = value.match(/^hel\s([0-9]{3}\.[0-9]{4})$/im);
            if (contentCode && contentCode[1]) {
                taxonomyService
                    .fetchTaxonomyByKey(
                        `HEL ${contentCode[1].slice(0, -2)}00`,
                        ServiceEnums.Content,
                        'document_taxonomy',
                        `${ServiceEnums.Content}document_taxonomy`
                    )
                    .then(res => setFieldValue('taxonomy', res.data));
            }
        }
        if (selectedTypeKey === MasterDataEnums.MiteType) {
            const contentCode = value.match(
                /^mite\s(([0-9]{3}\.[0-9]{4})\.[0-9]{4})$/im
            );
            if (
                contentCode &&
                contentCode[1] &&
                contentCode[2] &&
                documentBuilderName
            ) {
                contentService
                    .fetchContentByCode(
                        `HEL ${contentCode[2]}`,
                        `${ServiceEnums.Content}document_taxonomy`
                    )
                    .then(res => {
                        setFieldValue('taxonomy', {
                            value: res.data.taxonomy.id,
                            key: res.data.taxonomy.id,
                            label: res.data.taxonomy.name
                        });
                        setFieldValue(documentBuilderName, {
                            value: res.data.id,
                            key: res.data.id,
                            label: res.data.title
                        });
                    });
            }
        }
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                title: get(document, 'title', ''),
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                type: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                owners: initialOwnerIds,
                viewers: initialViewerIds,
                group: initialGroup && {
                    key: initialGroup.id,
                    value: initialGroup.id,
                    label: initialGroup.label
                },
                taxonomy: {
                    key: get(document, 'taxonomy.id'),
                    value: get(document, 'taxonomy.id'),
                    label: get(document, 'taxonomy.name')
                },
                valid_from: get(document, 'valid_from', ''),
                valid_to: get(document, 'valid_to', ''),
                builder: get(document, 'builder', {}),
                builderArrayFieldNames: get(
                    document,
                    'builderArrayFieldNames',
                    {}
                )
            }}
        >
            {({
                setFieldValue,
                values,
                handleChange,
                handleBlur
            }): ReactElement => (
                <EditForm>
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectGroup')}
                        type="document_group"
                        name="group"
                        label={t('pages.content.fields.group')}
                        isFormik
                        disabled={!!initialGroup || isViewing}
                    />
                    {!isViewing && (
                        <>
                            {getSelectedGroupKey(values.group) ===
                                MasterDataEnums.ContentGroupSpecification && (
                                <MasterDataSelect
                                    required
                                    module={ServiceEnums.Content}
                                    placeholder={t(
                                        'pages.content.edit.selectType'
                                    )}
                                    type="document_specification_type"
                                    name="type"
                                    label={t('pages.content.fields.type')}
                                    isFormik
                                    getSelectedKey={(key): void => {
                                        setSelectedTypeKey(key);
                                    }}
                                />
                            )}
                            {getSelectedGroupKey(values.group) ===
                                MasterDataEnums.ContentGroupOther && (
                                <MasterDataSelect
                                    required
                                    module={ServiceEnums.Content}
                                    placeholder={t(
                                        'pages.content.edit.selectType'
                                    )}
                                    type="document_other_type"
                                    name="type"
                                    label={t('pages.content.fields.type')}
                                    isFormik
                                    getSelectedKey={(key): void => {
                                        setSelectedTypeKey(key);
                                    }}
                                />
                            )}
                        </>
                    )}
                    <FormBuilder
                        formKey="document_code"
                        area={ServiceEnums.Content}
                        onBlur={(e): void => {
                            handleCodeChange(e.target.value, setFieldValue);
                            handleBlur(e);
                        }}
                        onChange={handleChange}
                        disabled={isViewing}
                    />
                    <Form.Item
                        name="title"
                        label={t('pages.content.fields.title')}
                        required
                    >
                        <Input.TextArea
                            autoSize={{ minRows: 1, maxRows: 12 }}
                            name="title"
                            placeholder={t('pages.content.fields.title')}
                            disabled={isViewing}
                            onKeyDown={(event): void => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                    {!isViewing && (
                        <FormBuilder
                            formKey="document_keywords"
                            area={ServiceEnums.Content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isViewing}
                        />
                    )}
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type="document_status"
                        name="status"
                        label={t('pages.content.fields.status')}
                        disabled={isViewing}
                        isFormik
                    />
                    <TaxonomySelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('taxonomy.selectTaxonomy')}
                        type="document_taxonomy"
                        name="taxonomy"
                        label={t('pages.content.fields.category')}
                        isFormik
                        disabled={isViewing}
                    />
                    {getSelectedGroupKey(values.group) ===
                        MasterDataEnums.ContentGroupOther && (
                        <FormBuilder
                            formKey="document_specification"
                            area={ServiceEnums.Content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            getFields={(fields): void => {
                                setDocumentBuilderName(
                                    get(fields, '0.fieldName', null)
                                );
                            }}
                            disabled={isViewing}
                        />
                    )}
                    <FormBuilder
                        formKey="document_version"
                        area={ServiceEnums.Content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                    <OwnerSelector
                        name="owners"
                        label={t('pages.content.fields.owners')}
                        setFieldValue={setFieldValue}
                        initialValues={initialOwners}
                        data={values.owners}
                        disabled={isViewing}
                    />
                    {!isViewing && (
                        <>
                            <ViewerSelector
                                name="viewers"
                                label={t('pages.content.fields.viewers')}
                                setFieldValue={setFieldValue}
                                initialValues={initialViewers}
                                data={values.viewers}
                                disabled={isViewing}
                            />
                        </>
                    )}
                    <Form.Item
                        name="valid_from"
                        label={t('pages.content.fields.startOfValidity')}
                        required
                    >
                        <DatePickerConfigFormat
                            setFieldValue={setFieldValue}
                            initialValue={values.valid_from}
                            name="valid_from"
                            placeholder={t(
                                'pages.content.fields.startOfValidity'
                            )}
                            locale={selectedLanguage.datePicker}
                            disabled={isViewing}
                        />
                    </Form.Item>
                    <Form.Item
                        name="valid_to"
                        label={t('pages.content.fields.endOfValidity')}
                    >
                        <DatePickerConfigFormat
                            setFieldValue={setFieldValue}
                            initialValue={values.valid_to}
                            name="valid_to"
                            placeholder={t(
                                'pages.content.fields.endOfValidity'
                            )}
                            locale={selectedLanguage.datePicker}
                            disabled={isViewing}
                            disabledDate={(current): boolean => {
                                return (
                                    current &&
                                    current.valueOf() <
                                        Date.parse(values.valid_from)
                                );
                            }}
                        />
                    </Form.Item>
                    {getSelectedGroupKey(values.group) ===
                        MasterDataEnums.ContentGroupOther && (
                        <FormBuilder
                            formKey="document_standard"
                            area={ServiceEnums.Content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isViewing}
                        />
                    )}
                    <FormBuilderArray
                        formKey="document_other_links"
                        area={ServiceEnums.Content}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        addButtonTitle={t('pages.content.edit.addNewLink')}
                        removeConfirmLabel={t(
                            'pages.content.edit.removeLinkConfirmLabel'
                        )}
                    />
                    {getSelectedGroupKey(values.group) ===
                        MasterDataEnums.ContentGroupOther && (
                        <FormBuilder
                            formKey="document_other_files"
                            area={ServiceEnums.Content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isViewing}
                        />
                    )}
                    {getSelectedGroupKey(values.group) ===
                        MasterDataEnums.ContentGroupSpecification && (
                        <FormBuilder
                            formKey="document_specification_files"
                            area={ServiceEnums.Content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isViewing}
                        />
                    )}
                    {!isViewing && (
                        <Form.Item name="submit" {...buttonItemLayout}>
                            <SubmitButton type="primary" loading={loading}>
                                {t('basic.saveButton')}
                            </SubmitButton>
                        </Form.Item>
                    )}
                </EditForm>
            )}
        </Formik>
    );
};

export default DocumentEditForm;
