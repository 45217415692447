/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    title: Yup.string()
        .min(2)
        .max(100)
        .required('pages.content.validations.requiredTitle'),
    taxonomy: Yup.object().required(
        'pages.content.validations.requiredTaxonomy'
    ),
    type: Yup.object().required('pages.content.validations.requiredType'),
    status: Yup.object().required('pages.content.validations.requiredStatus'),
    group: Yup.object().required(),
    valid_from: Yup.string().required(
        'pages.content.validations.requiredValidFrom'
    )
});
