import React, { ReactNodeArray, useContext } from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { NavLink, useLocation } from 'react-router-dom';
import { AreaType, RouteInterface } from '~/types/shared/route-interface';
import { flattenPath, getRouteArrayByPath } from '~/shared/utils/route';
import UserService from '~/services/user-service';
import i18n from '~/shared/utils/i18n';
import { PermissionContext } from '~/shared/context/permission';

const hasPerSubs = (current): boolean => current === false;
const generateMenu = (
    routeList,
    userPermissionNames,
    userHasPermission
): ReactNodeArray =>
    routeList
        .filter(routes => routes.area)
        .map(route => {
            if (route.subRoutes && !route.isIndexPage) {
                const generatedMenu = generateMenu(
                    route.subRoutes,
                    userPermissionNames,
                    userHasPermission
                );
                return generatedMenu.every(hasPerSubs) ? null : (
                    <Menu.SubMenu title={i18n.t(route.label)} key={route.path}>
                        {generatedMenu}
                    </Menu.SubMenu>
                );
            }
            if (
                userHasPermission(
                    userPermissionNames,
                    route.routePermissionName
                ) ||
                !route.routePermissionName
            ) {
                return (
                    <Menu.Item key={route.path}>
                        <NavLink to={route.path}>{i18n.t(route.label)}</NavLink>
                    </Menu.Item>
                );
            }
            return false;
        });

interface CollapsibleMenuProps extends MenuProps {
    routes: RouteInterface[];
    area: AreaType;
    user?: UserService;
}
const CollapsibleMenu: React.FC<CollapsibleMenuProps> = ({
    area,
    routes,
    ...props
}) => {
    const location = useLocation();
    const routePath = flattenPath(
        getRouteArrayByPath(routes, location.pathname),
        'path'
    );

    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames = permissions.map(permission => permission.name);

    const renderItems = generateMenu(
        routes.filter(routeList => routeList.area === area),
        userPermissionNames,
        userHasPermission
    );

    const keyAttributes = {
        defaultOpenKeys: area === 'left' ? routePath : null,
        selectedKeys: routePath
    };

    return (
        <Menu
            {...props}
            {...keyAttributes}
            theme={area === 'top' ? 'dark' : 'light'}
        >
            {renderItems}
        </Menu>
    );
};

export default CollapsibleMenu;
