import React, { useState, useEffect, ReactText } from 'react';
import { Tree, Input } from 'antd';
import { useTranslation } from 'react-i18next';

export interface TreeInterface {
    title: string | React.ReactNode;
    planTitle: string;
    key: string | number;
    children?: TreeInterface[];
}

interface SearchableTreeProps {
    treeData: TreeInterface[];
}

const SearchableTree: React.FC<SearchableTreeProps> = ({ treeData }) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const [masterData, setMasterData] = useState<TreeInterface[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<ReactText[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);

    const handleSearch = (masterTreeData: TreeInterface[]): TreeInterface[] => {
        const searchedKeys: ReactText[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const search = (data: TreeInterface[]): any[] => {
            return data.map(item => {
                const index =
                    searchValue !== ''
                        ? item.planTitle
                              .toLowerCase()
                              .indexOf(searchValue.toLowerCase())
                        : -1;
                const title = (
                    <span style={index > -1 ? { color: 'Red' } : {}}>
                        {item.title}
                    </span>
                );

                searchedKeys.push(item.key);

                if (item.children) {
                    return {
                        planTitle: title,
                        title,
                        key: item.key,
                        children: search(item.children)
                    };
                }

                return {
                    planTitle: title,
                    title,
                    key: item.key
                };
            });
        };

        const searched = search(masterTreeData);

        if (searchedKeys.length !== 0) {
            setExpandedKeys(searchedKeys);
        }

        return searched;
    };

    useEffect(() => {
        setMasterData(handleSearch(treeData));
    }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (e): void => {
        setSearchValue(e.target.value);
    };

    const onExpand = (keys): void => {
        setExpandedKeys(keys);
        setAutoExpandParent(false);
    };

    const onSelect = (selectedKeys, { node }): void => {
        if (node.children && node.children.length !== 0) {
            const keys = expandedKeys.concat(selectedKeys);
            setExpandedKeys(keys);
            setAutoExpandParent(false);
        }
    };

    return (
        <>
            <Input.Search
                style={{ marginBottom: 8 }}
                placeholder={t('basic.search')}
                onChange={(e): void => {
                    onChange(e);
                }}
            />
            <Tree
                onSelect={onSelect}
                treeData={masterData}
                autoExpandParent={autoExpandParent}
                defaultExpandedKeys={expandedKeys}
                expandedKeys={expandedKeys}
                onExpand={onExpand}
            />
        </>
    );
};

export default SearchableTree;
