import { AxiosPromise } from 'axios';
import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { User } from '~/types/services/user-service';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Configuration } from '~/types/services/configurator-service';
import { Group } from '~/types/services/permission-service';

export default class UserService extends BaseService {
    constructor() {
        super(ServiceEnums.Auth);
    }

    fetchMe(): Promise<ApiResponse<User>> {
        return this.getRequest('me');
    }

    fetchUser(id: string | number): Promise<ApiResponse<User>> {
        return this.getRequest(`users/${id}`);
    }

    fetchConfigurations(): Promise<ApiResponse<Configuration[]>> {
        return this.getRequest('me/configurations');
    }

    setConfiguration(id, value): Promise<void> {
        return this.putRequest(`me/configurations/${id}`, {
            value
        });
    }

    fetchUsers(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<User>> {
        return this.getRequest(
            'users',
            {
                filter,
                sort,
                page
            },
            ServiceEnums.Auth
        );
    }

    exportUsers(filter: object, sort: object): void {
        this.download(
            'users/export',
            {
                filter,
                sort
            },
            ServiceEnums.Auth
        );
    }

    updateUser(id: string | number, data: User): Promise<void> {
        return this.putRequest(`users/${id}`, data);
    }

    updateMe(data: User): Promise<void> {
        return this.putRequest(`me`, data);
    }

    deleteProfile(data: User): Promise<void> {
        return this.deleteRequest(`me`, data);
    }

    updatePassword(data: User): Promise<void> {
        return this.putRequest(`me/password`, data);
    }

    changeEmail(data: User): Promise<void> {
        return this.putRequest(`me/email`, data);
    }

    changeLanguage(data: { lang: string }): Promise<void> {
        return this.putRequest(`me/language`, data);
    }

    inviteUser(data: User): Promise<void> {
        return this.postRequest(`users/invite`, data);
    }

    deleteUsers(userIds: string[] | number[]): Promise<void> {
        return this.deleteRequest('users', {
            users: userIds
        });
    }

    fetchUserGroups(userId): Promise<Group[]> {
        return this.getRequest(`users/${userId}/groups`);
    }

    requestPasswordReset(email: string): AxiosPromise {
        return this.getRequest('reset-password', {
            email
        });
    }
}
