import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18n from '~/shared/utils/i18n';

const filterInput = {
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm
    }): React.ReactNode => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={i18n.t('basic.search')}
                data-testid="table_filter_input"
                value={selectedKeys}
                onChange={(e): void =>
                    setSelectedKeys(e.target.value ? e.target.value : null)
                }
                onPressEnter={(): void => confirm()}
                onBlur={(): void => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                allowClear
            />
        </div>
    ),
    filterIcon: (filtered): React.ReactNode => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    )
};

export default filterInput;
