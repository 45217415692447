import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import EditForm from '~/components/EditForm';
import ChangeEmailFormSchema from './ChangeEmailForm.validation';

interface ChangeEmailFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    loading: boolean;
}

const ChangeEmailForm: React.FC<ChangeEmailFormProps> = ({
    onSubmit,
    loading
}) => {
    const { t } = useTranslation();
    const initialValue = {};

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return (
        <Formik
            validationSchema={ChangeEmailFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={initialValue}
        >
            <EditForm>
                <Form.Item
                    name="email"
                    label={t('pages.users.fields.newEmail')}
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder={t('pages.users.fields.newEmail')}
                    />
                </Form.Item>
                <Form.Item name="submit" {...buttonItemLayout}>
                    <SubmitButton type="primary" loading={loading}>
                        {t('pages.users.profile.changeEmailBtn')}
                    </SubmitButton>
                </Form.Item>
            </EditForm>
        </Formik>
    );
};

export default ChangeEmailForm;
