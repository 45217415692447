import { Form } from 'formik-antd';
import React from 'react';
import { LabeledValue, SelectValue } from 'antd/es/select';
import AjaxSelect from '~/components/AjaxSelect';
import PermissionService from '~/services/permission-service';
import i18n from '~/shared/utils/i18n';

const permissionService = new PermissionService();

const onSearch = (value): Promise<LabeledValue[]> =>
    permissionService.fetchRoles({ name: value }).then(data =>
        data.data.map(res => ({
            value: res.id,
            label: res.name
        }))
    );

interface RoleSelectorProps {
    name: string;
    label: string;
    setFieldValue: Function;
    data: LabeledValue[];
    initialValues: SelectValue;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({
    name,
    label,
    setFieldValue,
    data,
    initialValues
}) => (
    <Form.Item name={name} label={label}>
        <AjaxSelect
            id={name}
            initialValues={initialValues}
            placeholder={i18n.t('basic.selectWithLabel', { label })}
            onSearch={onSearch}
            onSelect={(res): void => {
                setFieldValue(name, [...data, res.value]);
            }}
            onDeselect={(res): void => {
                setFieldValue(
                    name,
                    data.filter(role => role !== res.value)
                );
            }}
        />
    </Form.Item>
);

export default RoleSelector;
