/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    isEmailLogin: Yup.boolean(),
    email: Yup.string()
        .email()
        .when('isEmailLogin', {
            is: true,
            then: Yup.string().required('basic.validations.requiredEmail')
        }),
    username: Yup.string().when('isEmailLogin', {
        is: false,
        then: Yup.string().required('basic.validations.requiredUsername')
    }),
    password: Yup.string().required('basic.validations.requiredPassword')
});
