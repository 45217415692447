import React, { useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Form, Select as FormikSelect, SelectProps } from 'formik-antd';
import { Spin, Select } from 'antd';
import MasterDataService from '~/services/masterdata-service';
import {
    MasterData,
    MasterDataSelectType,
    MasterDataSelectTypeWithKey
} from '~/types/services/masterdata-service';

const masterDataService = new MasterDataService();

interface MasterDataSelectProps {
    required?: boolean;
    value?: string;
    mode?: SelectProps['mode'];
    module: string;
    type: string | string[];
    isFormik: boolean;
    placeholder?: string;
    onSelect?: (value: string | number) => void;
    onChange?: (data: object) => void;
    getSelectedKey?: (key: string | number) => void;
    style?: object;
    label?: string;
    name?: string;
    disabled?: boolean;
    allowClear?: boolean;
}

const MasterDataSelect: React.FC<MasterDataSelectProps> = ({
    required,
    mode,
    module,
    type,
    name,
    label,
    placeholder,
    isFormik,
    onSelect,
    onChange,
    getSelectedKey,
    style,
    disabled,
    allowClear
}) => {
    let types = [];
    if (!Array.isArray(type)) {
        types.push(type);
    } else {
        types = type;
    }
    const { promiseInProgress } = usePromiseTracker({
        area: module + types[0]
    });

    const [masterData, setMasterData] = useState<MasterDataSelectType[]>();
    const [masterDataWithKey, setMasterDataWithKey] = useState<
        MasterDataSelectTypeWithKey[]
    >();

    const handleOpen = (): void => {
        if (!masterData) {
            const allRequest = types.map(t =>
                masterDataService.fetchMasterDatasByType(
                    module,
                    t,
                    module + types[0]
                )
            );
            Promise.all(allRequest).then(res => {
                const data: MasterDataSelectType[] = [];
                const dataWithKey: MasterDataSelectTypeWithKey[] = [];
                res.forEach(d =>
                    d.data.forEach((mData: MasterData) => {
                        data.push({
                            value: mData.id,
                            label: mData.label
                        });
                        dataWithKey.push({
                            key: mData.key,
                            value: mData.id,
                            label: mData.label
                        });
                    })
                );
                setMasterData(data);
                setMasterDataWithKey(dataWithKey);
            });
        }
    };

    const selectProps = {
        labelInValue: true,
        placeholder,
        options: masterData,
        loading: promiseInProgress,
        onClick: handleOpen,
        notFoundContent: promiseInProgress ? <Spin size="small" /> : null,
        disabled: !!disabled,
        allowClear: !!allowClear
    };

    const getKeyById = (id: number | string): string | number | null => {
        if (masterDataWithKey) {
            const selectedMasterData = masterDataWithKey.filter(
                g => g.value === id
            );
            if (selectedMasterData) {
                return selectedMasterData[0].key;
            }
        }
        return null;
    };

    const handleFormikSelect = (value: MasterDataSelectType): void => {
        if (getSelectedKey) {
            getSelectedKey(getKeyById(value.value));
        }
    };

    return (
        <>
            {isFormik && (
                <Form.Item name={name} label={label} required={required}>
                    <FormikSelect
                        data-testid={name}
                        name={name}
                        mode={mode}
                        onSelect={handleFormikSelect}
                        {...selectProps}
                    />
                </Form.Item>
            )}
            {!isFormik && (
                <Select
                    data-testid="filter_master_data"
                    {...selectProps}
                    onSelect={(value: MasterDataSelectType): void =>
                        onSelect(value.value)
                    }
                    onChange={(data): void => {
                        if (onChange) {
                            onChange(data);
                        }
                    }}
                    style={style}
                />
            )}
        </>
    );
};

export default MasterDataSelect;
