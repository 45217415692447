import { matchPath } from 'react-router-dom';
import get from 'lodash/get';
import { RouteInterface } from '~/types/shared/route-interface';

const findInSubRoutes = (route, path): RouteInterface =>
    get(route, 'subRoutes', []).find(subRoute => {
        const match = !!matchPath(path, subRoute);
        if (!match && subRoute.subRoutes) {
            return findInSubRoutes(subRoute, path);
        }
        return match;
    });

const reduceSubroutes = (route): RouteInterface[] =>
    route.subRoutes.reduce(
        (paths: RouteInterface[], current) => {
            if (current.subRoutes) {
                paths.push(...reduceSubroutes(current));
                return paths;
            }

            paths.push(current);
            return paths;
        },
        [route]
    );

export const flattenPath = (
    routePaths: RouteInterface[],
    key: string
): string[] => {
    return routePaths.map(route => route[key].toString());
};

export const getRouteArray = (routes: RouteInterface[]): RouteInterface[][] =>
    routes.map(route => (route.subRoutes ? reduceSubroutes(route) : [route]));

export const getRouteArrayByPath = (
    routes: RouteInterface[],
    path
): RouteInterface[] => {
    let selectedRoutePath = [];
    getRouteArray(routes).some((routePaths: RouteInterface[]) => {
        if (routePaths.find(routePath => !!matchPath(path, routePath))) {
            selectedRoutePath = routePaths;
            return true;
        }

        return false;
    });

    return selectedRoutePath.filter(route => {
        return !!matchPath(path, route) || findInSubRoutes(route, path);
    });
};
