import React, { useContext } from 'react';
import './Home.scss';
import { Redirect } from 'react-router-dom';
import { PermissionContext } from '~/shared/context/permission';
import Spinner from '~/components/Spinner';
import PermissionKeysEnums from '~/types/shared/permission-keys-enums';

const Home: React.FC = () => {
    const { permissions, permissionLoading } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    if (permissionLoading) return <Spinner />;
    const isEloirasPermission = (): boolean =>
        userPermissionNames.some(value =>
            [
                PermissionKeysEnums.KulsoTervezo,
                PermissionKeysEnums.Beszallitok,
                PermissionKeysEnums.EloirasMegtekinto
            ].includes(value as PermissionKeysEnums)
        );

    const isEgyebPermission = (): boolean =>
        userPermissionNames.some(value =>
            [
                PermissionKeysEnums.TmaView,
                PermissionKeysEnums.EgyebView
            ].includes(value as PermissionKeysEnums)
        );

    if (
        (userPermissionNames.includes('eloiras.manage') &&
            (!userPermissionNames.includes('documents.manage') ||
                !userPermissionNames.includes('access.full'))) ||
        isEloirasPermission()
    ) {
        return <Redirect to="/content/specifikacio" />;
    }

    if (
        (userPermissionNames.includes('egyeb.manage') &&
            (!userPermissionNames.includes('documents.manage') ||
                !userPermissionNames.includes('access.full'))) ||
        isEgyebPermission()
    ) {
        return <Redirect to="/content/egyeb" />;
    }

    return <Redirect to="/content/documents" />;
};

export default Home;
