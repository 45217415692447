enum MasterDataEnums {
    ContentStatusActive = 'content-status-active',
    ContentStatusArchived = 'content-status-archived',
    ContentGroupSpecification = 'specifikacio',
    ContentGroupOther = 'egyeb',
    HelType = 'halozati-elemek',
    MiteType = 'minositett-termekek',
    Mubizonylatok = 'mubizonylatok'
}

export default MasterDataEnums;
