import React, { useEffect, useState } from 'react';
import { Form, Card } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import FeatureGateService from '~/services/feature-service';
import { DetailedFeatures } from '~/types/services/feature-service';
import FeatureToggle from '~/pages/FeaturePage/components/FeatureToggle';
import ServiceEnums from '~/types/shared/service-enums';
import Spinner from '~/components/Spinner';
import Title from '~/components/Title';

const Service = new FeatureGateService();

const FeaturePage: React.FC = () => {
    const { t } = useTranslation();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.FeatureGate
    });
    const [features, setFeatures] = useState<DetailedFeatures>({});
    useEffect(() => {
        Service.fetchDetailedFeatures().then(res => setFeatures(res.data));
    }, []);

    const toggleFeature = (checked, id, area): Promise<void> => {
        return checked
            ? Service.enableFeature(id, area)
            : Service.disableFeature(id, area);
    };

    const featuresByModule = Object.keys(features).map(moduleName => ({
        name: moduleName,
        features: features[moduleName]
    }));

    return (
        <>
            <Title type="primary">{t('pages.features.title')}</Title>
            {promiseInProgress && <Spinner />}
            {featuresByModule.map(module => (
                <Card title={module.name} key={module.name}>
                    <Form layout="vertical">
                        {module.features.map(feature => (
                            <FeatureToggle
                                key={feature.id}
                                feature={feature}
                                toggleFeature={toggleFeature}
                            />
                        ))}
                    </Form>
                </Card>
            ))}
        </>
    );
};

export default FeaturePage;
