import React, { useContext } from 'react';
import { PermissionContext } from '~/shared/context/permission';

interface FeatureGateProps {
    permission: string | string[];
    permissionRoute: string | string[];
    inverse?: boolean;
}

const PermissionGate: React.FC<FeatureGateProps> = ({
    permission,
    permissionRoute,
    inverse,
    children
}) => {
    const { userHasPermission } = useContext(PermissionContext);

    if (!userHasPermission(permission, permissionRoute) && !inverse) {
        return null;
    }

    return <>{children}</>;
};

export default PermissionGate;
