const resources = {
    menu: {
        dashboard: 'Főoldal',
        content: 'Tudástár',
        documents: 'Minden dokumentum',
        editDocument: 'Dokumentum szerkesztése',
        viewDocument: 'Dokumentum megtekintése',
        administration: 'Adminisztráció',
        taxonomies: 'Kategóriák',
        masterDatas: 'Törzsadatok',
        groups: 'Csoportok',
        editGroup: 'Csoport szerkesztése',
        roles: 'Szerepkörök',
        editRole: 'Szerepkör szerkesztése',
        users: 'Felhasználók',
        editUser: 'Felhasználó szerkesztése',
        features: 'Funkciók',
        settings: 'Beállítások',
        profile: 'Profil',
        myProfile: 'Saját profil',
        preferences: 'Preferenciák',
        logout: 'Kijelentkezés',
        specificationDocument: 'Előírások',
        editSpecificationDocument: 'Előírás szerkesztése',
        otherDocument: 'Egyéb dokumentumok',
        editOtherDocument: 'Egyéb dokumentum szerkesztése'
    },
    notifications: {
        title: 'Értesítések'
    },
    pages: {
        noPermissions: {
            content:
                'Jelenleg nincsen hozzáférése az oldal megtekintéséhez. Kérjük vegye fel a kapcsolatot kollégánkkal.'
        },
        authentication: {
            cardTitle: 'Hálózati tudástár - Sztenderdnyilvántartó',
            login: {
                loginFailed: 'Hibás bejelentkezés',
                inactiveAccount:
                    'A regisztrációját munkatársunk még nem hagyta jóvá',
                incorrectLogin: 'Hibás bejelentkezési adatok',
                login: 'Bejelentkezés',
                backToLogin: 'Vissza a bejelentkezéshez',
                adfsLogin: 'ADFS bejelentkezés'
            },
            registration: {
                successTitle: 'Sikeres regisztráció',
                successMessage:
                    'A hozzáféréssel most már be lehet jelentkezni.',
                successSendEmailMessage:
                    'A regisztráció jóváhagyásához emailt küldtünk.',
                registrationFailed: 'Hibás regisztráció',
                verificationSuccess: 'Az email cím jóváhagyásra került',
                verificationError: 'Hibás vagy lejárt aktiválás',
                register: 'Regisztráció',
                firstName: 'Keresztnév',
                lastName: 'Vezetéknév',
                phoneNumber: 'Telefonszám',
                gdpr: 'Elolvastam és elfogadom a GDPR szabályzatot',
                termsAndCondition:
                    'Elolvastam és elfogadom a Felhasználási nyilatkozatot',
                validations: {
                    privacyPolicy:
                        'Az adatkezelési tájékoztató elfogadása kötelező!',
                    privacyStatement:
                        'A titoktartási nyilatkozat elfogadása kötelező!',
                    error: 'A regisztráció nincs megfelelően kitöltve!'
                }
            },
            lostPassword: {
                successTitle: 'Elfelejtett jelszó',
                successMessage:
                    'Az elfelejtett jelszó beállítááshoz emailt küldtünk',
                successSetTitle: 'A jelszó beállításra került',
                successSetMessage:
                    'A hozzáféréssel most már be lehet jelentkezni.',
                forgotPassword: 'Elfelejtett jelszó',
                resetMyPassword: 'Jelszó beállítása'
            },
            revert: {
                revertSuccess: 'A hozzáférés visszaállitásra került',
                revertExpired: 'Hibás vagy lejárt visszaállitás hivatkozás'
            },
            lostUsername: {
                forgotUsername: 'Elfelejtett felhasználónév',
                sendMyUsername: 'Felhasználónév küldése',
                successTitle: 'Elfelejtett felhasználónév',
                successMessage:
                    'Az email címedre elküldtük a felhasználónevedet'
            }
        },
        content: {
            fields: {
                code: 'Kód',
                group: 'Dokumentum csoport',
                keywords: 'Kulcsszavak',
                type: 'Típus',
                title: 'Dokumentum neve',
                category: 'Kategória',
                status: 'Státusz',
                owners: 'Adatlap gazda',
                viewers: 'Értesítést kap',
                endOfValidity: 'Érvényesség vége',
                startOfValidity: 'Érvényesség kezdete'
            },
            list: {
                title: 'Minden dokumentum',
                specificationTitle: 'Előírások',
                otherTitle: 'Egyéb dokumentumok',
                confirmDeleteTitle: 'Biztosan törli a dokumentumokat?',
                confirmArchivesTitle: 'Biztosan archiválja a dokumentumokat?',
                confirmDeleteMessage: 'A müvelet végleges',
                confirmArchivateTitle:
                    'Biztosan archiválja a kijelölt dokumentumot?',
                archivateButton: 'Archiválás'
            },
            edit: {
                notFound: 'A dokumentum nem található',
                successSave: 'A dokumentum mentésre került',
                failedSave: 'Hiba történt a dokumentum mentésekkor',
                editTitle: 'Dokumentum szerkesztése',
                viewTitle: 'Dokumentum megtekintése',
                createTitle: 'Dokumentum létrehozása',
                selectType: 'Tipus kiválasztása',
                selectStatus: 'Státusz kiválasztása',
                selectGroup: 'Csoport kiválasztása',
                noPermission:
                    'Nincs jogosultsága a dokumentum szerkesztéséhez/megtekintéséhez',
                noDeletePermission:
                    'A törlendő dokumentumok között található olyan dokumentum,' +
                    ' melyhez nincs törlési jogosultsága',
                addNewLink: 'További link hozzáadása',
                removeLinkConfirmLabel: 'Biztos törli a linket?',
                validationError: 'A dokumentum nem megfelelően van kitöltve!'
            },
            validations: {
                requiredTitle: 'A cím megadása kötelező',
                requiredTaxonomy: 'A kategória megadása kötekező',
                requiredType: 'A típus megadása kötekező',
                requiredStatus: 'A státusz megadása kötekező',
                requiredValidFrom:
                    'Az érvényesség kezdete mező megadása kötekező'
            }
        },
        features: {
            title: 'Funkciók'
        },
        masterData: {
            fields: {
                label: 'Név',
                key: 'Kulcs',
                value: 'Érték',
                description: 'Leírás'
            },
            list: {
                title: 'Törzsadatok'
            },
            edit: {
                title: 'Törzsadat szerkesztése',
                notFound: 'A törzsadat nem található',
                failedSave: 'A törzsadat mentése nem sikerült',
                successSave: 'A törzsadat mentése sikerült'
            },
            validations: {
                requiredLabel: 'A név megadása kötelező',
                requiredKey: 'A kulcs megadása kötelező',
                requiredValue: 'Az érték megadása kötelező'
            }
        },
        permissions: {
            groups: {
                fields: {
                    name: 'Csoport neve',
                    description: 'Leírás',
                    owners: 'Tulajdonosok',
                    members: 'Tagok',
                    roles: 'Szerepkörök'
                },
                edit: {
                    notFound: 'A csoport nem található',
                    successSave: 'A csoport mentésre került',
                    failedSave: 'A csoportot nem sikerült menteni',
                    editTitle: 'Csoport szerkesztése',
                    createTitle: 'Csoport létrehozása'
                },
                list: {
                    title: 'Csoportok',
                    confirmDeleteTitle: 'Biztosan törli a csoportot?',
                    confirmDeleteMessage: 'A müvelet végleges'
                },
                validations: {
                    requiredName: 'A csoport nevét kötelező megadni'
                }
            },
            roles: {
                fields: {
                    name: 'Szerepkör neve',
                    description: 'Leírás',
                    permissions: 'Jogosultság'
                },
                edit: {
                    notFound: 'A szerepkör nem található',
                    successSave: 'A szerepkör mentésre került',
                    failedSave: 'A szerepkört nem sikerült menteni',
                    editTitle: 'Szerepkör szerkesztése',
                    createTitle: 'Szerepkör létrehozása'
                },
                list: {
                    title: 'Szerepkörök',
                    confirmDeleteTitle: 'Biztosan törli a szerepkört?',
                    confirmDeleteMessage: 'A müvelet végleges'
                },
                validations: {
                    requiredName: 'A szerepkör nevének kitöltése kötelező'
                }
            }
        },
        settings: {
            successSave: 'A beállítások mentésre kerültek',
            failedSave: 'A beállításokat nem sikerült menteni',
            title: 'Beállítások',
            Preferences: 'Preferenciák'
        },
        taxonomy: {
            fields: {
                parent: 'Szűlő kategória',
                name: 'Név',
                identifier: 'Azonosító',
                description: 'Leírás'
            },
            edit: {
                notFound: 'A kategória nem található',
                successSave: 'A kategória mentésre került',
                failedSave: 'A kategóriát nem sikerült menteni'
            },
            list: {
                addNewBtn: 'Új kategória hozzáadása',
                editBtn: 'Kategória szerkesztése',
                deleteBtn: 'Kategória törlése',
                filterBtn: 'Dokumentum szűrés',
                confirmDeleteTitle: 'Biztosan törli a kategóriát?',
                confirmDeleteMessage: 'A müvelet végleges',
                createTitle: 'Kategória létrehozása',
                editTitle: 'Kategória szerkesztése',
                title: 'Kategóriák'
            },
            validations: {
                requiredName: 'A név megadása kötelező'
            }
        },
        users: {
            fields: {
                status: 'Státusz',
                createdAt: 'Regisztárció ideje',
                name: 'Név',
                firstName: 'Keresztnév',
                lastName: 'Vezetéknév',
                phone: 'Telefonszám',
                group: 'Csoport',
                newEmail: 'Új email cím',
                currentPassword: 'Aktuális jelszó',
                newPassword: 'Új jelszó',
                newPasswordConfirm: 'Új jelszó megerősítése',
                adfsUser: 'ADFS Felhasználó'
            },
            edit: {
                notFound: 'Felhasználó nem található',
                successSave: 'A felhasználó mentésre került',
                failedSave: 'A felhasználót nem sikerült menteni',
                title: 'Felhasználó szerkesztése',
                inviteBtn: 'Meghívó küldése',
                validationError: 'A profil nincs megfelelően kitöltve!'
            },
            list: {
                title: 'Felhasználók',
                revokeBnt: 'Visszavonás',
                statusActive: 'Aktív',
                statusInactive: 'Inaktív',
                statusWaiting: 'Várakozás az email jóváhagyásra',
                statusPending: 'Függőben',
                statusNew: 'Új',
                inviteBtn: 'Meghívás',
                confirmDeleteTitle: 'Biztosan törli a felhasználót?',
                confirmDeleteMessage: 'A müvelet végleges',
                mySelfDeleteTitle: 'Saját magadat nem törölheted',
                mySelfDeleteMessage: 'Töröld a kiválasztást a listából'
            },
            invite: {
                title: 'Felhasználó meghívása',
                successSave: 'A meghívás sikeresen megtörtént',
                failedSaveTitle: 'Hibás meghívás',
                failedSaveMessage: 'Hiba történt a meghíváskor'
            },
            profile: {
                successSave: 'Az adatok mentésre kerültek',
                failedSave: 'Hiba törént a mentés során',
                emailSendSuccess: 'Az új emailed aktiválásához emailt küldtünk',
                emailSendFailed: 'Hiba törént a mentés során',
                emailChangeSuccess: 'Az új email cím aktiválásra került',
                emailChangeFailed: 'Hibás vagy lejárt aktiváló kulcs',
                passwordChangeSuccess: 'A jelszó frissitésre került',
                passwordChangeFailed: 'Hiba törént a mentés során',
                profileDeleteSuccess: 'A profilod törlésre került',
                profileDeleteFailed: 'Hiba történt a törlés során',
                profileDeleteTitle: 'Profil törlése',
                pageTitle: 'Saját profil',
                emailChangeTitle: 'Email megváltoztatása',
                passwordChangeTitle: 'Jelszó megváltoztatása',
                changeEmailBtn: 'Új email beállítása',
                changePasswordBtn: 'Új jelszó beállítása',
                deleteConfirmPlaceholder:
                    'Jelszó megadása a törlés jóváhagyásához',
                validationError: 'A profil nincs megfelelően kitöltve!'
            },
            validations: {
                firstNameRequired: 'A keresztnév kitöltése kötelező',
                lastNameRequired: 'A vezetéknév kitöltése kötelező',
                currentPasswordRequired: 'Az aktuális jelszó megadása kötelező'
            }
        }
    },
    taxonomy: {
        selectTaxonomy: 'Kategória kiválasztása'
    },
    upload: {
        successUpload: 'Sikeres fájl feltöltés',
        failedUpload: 'Hiba a fájl feltöltése közben',
        tooLargeFile: 'A fájl mérete túl nagy (Maximum: {{size}} MB)',
        uploadButton: 'Feltöltés'
    },
    basic: {
        title: 'Hálózati tudástár - Sztenderdnyilvántartó',
        xhrError: 'Hiba történt',
        search: 'Keresés',
        pleaseSelect: 'Válassz',
        selectedXCount: '{{count}} elem kiválasztva',
        okText: 'Igen',
        archiveDocumentOkText: 'Korábbi dokumentum archiválása',
        archiveDocumentConfirmText:
            'Szeretné archiválni a korábbi dokumentumot?',
        cancelText: 'Mégsem',
        confirmText: 'Jóváhagyás',
        deleteSelectedButton: 'Kijelöltek törlése',
        archiveSelectedButton: 'Kijelöltek archiválása',
        exportButton: 'Exportálás',
        addNewButton: 'Új létrehozása',
        removeFilterButton: 'Szűrések eltávolítása',
        deleteButton: 'Törlés',
        editButton: 'Szerkesztés',
        viewButton: 'Megtekintés',
        creationDate: 'Létrehozás ideje',
        action: 'Eszközök',
        selectWithLabel: '{{label}} kiválasztása',
        saveButton: 'Mentés',
        view: 'Megtekintés',
        fields: {
            username: 'Felhasználónév',
            email: 'Email',
            password: 'Jelszó',
            passwordConfirmation: 'Jelszó megerősítése'
        },
        validations: {
            requiredEmail: 'Az email kitöltése kötelező',
            requiredUsername: 'Az felhasználónév kitöltése kötelező',
            requiredPassword: 'A jelszó kitöltése kötelező',
            passwordFormat:
                'A jelszónak minimum 8 karakternek kell lenie, és tartalmaznia kell legalább egy kis és nagybetűt és egy számot',
            passwordMustMatch: 'A jelszavaknak egyezniük kell'
        }
    },
    validations: {
        mixed: {
            default: 'A mező érvénytelen',
            required: 'A mező kitöltése kötelező',
            oneOf:
                'A mezőnek a következő értékek egyikének kell lennie: {{param}}',
            notOneOf:
                'A mezőnek nem szabad a következő értékek egyikének lennie: {{param}}',
            defined: 'A mezőt meg kell határozni'
        },
        string: {
            length: 'A mezőnek pontosan {{param}} karakternek kell lennie',
            min: 'A mezőnek legalább {{param}} karakternek kell lennie',
            max: 'A mező legfeljebb {{param}} karakter lehet',
            matches: 'A mezőnek meg kell egyeznie a következővel: {{param}}',
            email: 'A mezőnek érvényes e-mail címnek kell lennie',
            url: 'A mezőnek érvényes URL-nek kell lennie',
            uuid: 'A mezőnek érvényes UUID-nek kell lennie',
            trim: 'Field must be a trimmed string',
            lowercase: 'A mezőnek kisbetűsnek kell lennie',
            uppercase: 'A mezőnek nagybetűs karakterláncnak kell lennie'
        },
        number: {
            min: 'A mező nem lehet kisebb, mint {{param}}',
            max: 'A mező nem lehet nagyobb, mint {{param}}',
            lessThan: 'A mezőnek kevesebbnek kell lennie: {{param}}',
            moreThan: 'A mezőnek nagyobbnak kell lennie, mint {{param}}',
            notEqual: 'A mezőnek nem szabad egyenlőnek lennie: {{param}}',
            positive: 'A mezőnek pozitív számnak kell lennie',
            negative: 'A mezőnek negatív számnak kell lennie',
            integer: 'A mezőnek egész számnak kell lennie'
        },
        date: {
            min: 'A mezőnek későbbinek kell lennie, mint {{param}}',
            max: 'A mezőnek korábbinak kell lennie, mint {{param}}'
        },
        object: {
            noUnknown: 'A mezőnek nincs meghatározva kulcsai: {{param}}'
        },
        array: {
            min: 'A mezőnek legalább {{param}} elemmel kell rendelkeznie',
            max: 'A mezőnek legfeljebb {{param}} elemnek kell lennie'
        }
    }
};

export default resources;
