import { AxiosPromise } from 'axios';
import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { DBSelectData, FormBuilderForm } from '~/types/services/form-service';

export default class FormService extends BaseService {
    constructor() {
        super(ServiceEnums.FormBuilder);
    }

    fetchForm(key: string, area?: string): Promise<FormBuilderForm> {
        return this.getRequest(key, {}, area);
    }

    fetchDbSelectValues(
        entity: string,
        labelColumn: [],
        valueColumn: string,
        area?: string
    ): AxiosPromise<DBSelectData[]> {
        return this.postRequest(
            'dbselect',
            { entity, labelColumn, valueColumn },
            area
        );
    }
}
