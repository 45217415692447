import React from 'react';
import MasterDataSelect from '~/components/MasterDataSelect';
import i18n from '~/shared/utils/i18n';

const filterMasterData = (module: string, key: string | string[]): object => {
    return {
        filterDropdown: ({ setSelectedKeys, confirm }): React.ReactNode => (
            <div style={{ padding: 8 }}>
                <MasterDataSelect
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    placeholder={i18n.t('basic.pleaseSelect')}
                    name="master_data"
                    type={key}
                    module={module}
                    onSelect={(value): void => {
                        setSelectedKeys([value] || null);
                        confirm();
                    }}
                    onChange={(data): void => {
                        if (!data) {
                            setSelectedKeys(null);
                            confirm();
                        }
                    }}
                    isFormik={false}
                    allowClear
                />
            </div>
        )
    };
};

export default filterMasterData;
