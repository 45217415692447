import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languages from '~/types/shared/languages';

const resources = {};
languages.forEach(language => {
    resources[language.key] = {
        translation: language.i18n
    };
});

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lang') || process.env.DEFAULT_LANGUAGE,
        fallbackLng: process.env.DEFAULT_LANGUAGE,

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
