import React from 'react';
import { Select } from 'antd';
import i18n from '~/shared/utils/i18n';

export interface FilterDataInterface {
    value: string | number;
    text: string;
}

export const filterDropdown = (data: FilterDataInterface[]): object => {
    return {
        filterDropdown: ({ setSelectedKeys, confirm }): React.ReactNode => (
            <div style={{ padding: 8 }}>
                <Select
                    placeholder={i18n.t('basic.pleaseSelect')}
                    defaultValue=""
                    onChange={(value): void => {
                        setSelectedKeys([value] || null);
                        confirm();
                    }}
                    data-testid="table_filter_dropdown"
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    allowClear
                >
                    {data.map(
                        (select: FilterDataInterface): React.ReactNode => (
                            <Select.Option
                                key={select.value}
                                value={select.value}
                            >
                                {select.text}
                            </Select.Option>
                        )
                    )}
                </Select>
            </div>
        )
    };
};
