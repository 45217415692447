import React, { ReactNodeArray } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from '~/components/Route';
import routes from '~/routes';
import Sentry from '~/containers/Sentry';
import AppLayout from '~/components/Layout';
import { RouteInterface } from '~/types/shared/route-interface';
import CombinedContext from '~/shared/context/combined-context';

const renderRoutes = (routeList: RouteInterface[]): ReactNodeArray =>
    routeList.map(route => {
        if (route.subRoutes && route.isIndexPage)
            return [
                <Route key={route.path} {...route} />,
                renderRoutes(route.subRoutes)
            ];
        if (route.subRoutes) return renderRoutes(route.subRoutes);
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Route key={route.path} {...route} />
        );
    });

const App: React.FC = () => (
    <div className="app">
        <Sentry>
            <Router>
                <CombinedContext>
                    <AppLayout>
                        <Switch>{renderRoutes(routes)}</Switch>
                    </AppLayout>
                </CombinedContext>
            </Router>
        </Sentry>
    </div>
);

export default hot(App);
