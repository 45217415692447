import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useLocation } from 'react-router-dom';
import PermissionService from '~/services/permission-service';
import { AuthContext } from '~/shared/context/auth';
import { Permission } from '~/types/services/permission-service';

interface ContextProps {
    permissions: Permission[];
    userHasPermission: (
        permissionName: string | string[],
        routePermissionName: string | string[]
    ) => boolean;
    permissionLoading: boolean;
}

const Service = new PermissionService();

export const PermissionContext = createContext<ContextProps>({
    permissions: [],
    userHasPermission: () => false,
    permissionLoading: false
} as ContextProps);

const PermissionStoreProvider: React.FC = ({ children }) => {
    const { jwt } = useContext(AuthContext);
    const [permissions, setPermissions] = useState<Permission[]>();
    const [permissionLoading, setPermissionLoading] = useState(true);
    const location = useLocation().pathname;

    useEffect(() => {
        if (jwt) {
            Service.fetchUserPermissions().then(res => {
                setPermissions(res.data);
                setPermissionLoading(false);
            });
        }
        if (!jwt) {
            setPermissions([]);
            setPermissionLoading(true);
        }
    }, [jwt, location]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const userHasPermission = (
        permissionName,
        routePermissionName
    ): boolean => {
        if (permissions && routePermissionName) {
            if (
                permissions.some(
                    permission => permission.name === 'access.full'
                )
            ) {
                return true;
            }
            if (typeof routePermissionName === 'string') {
                return permissionName.includes(routePermissionName);
            }

            let routeHasPermission = false;
            routePermissionName.forEach(rPermissionName => {
                if (permissionName.includes(rPermissionName)) {
                    routeHasPermission = true;
                }
            });
            if (routeHasPermission) {
                return true;
            }
        }
        return false;
    };

    const store = useMemo<ContextProps>(
        () => ({ permissions, userHasPermission, permissionLoading }),
        [permissions, userHasPermission] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <PermissionContext.Provider value={store}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionStoreProvider;
