import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '~/shared/pictures/favicon-32x32.png';
import i18n from '~/shared/utils/i18n';

const options = {
    titleTemplate: `%s | ${i18n.t('basic.title')}`,
    defaultTitle: i18n.t('basic.title')
};

const Meta: React.FC = () => (
    <Helmet {...options}>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    </Helmet>
);

export default Meta;
