import React, { useEffect, useState } from 'react';
import { Typography, Drawer, Tooltip } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { FormOutlined, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import MasterDataService from '~/services/masterdata-service';
import ServiceEnums from '~/types/shared/service-enums';
import Spinner from '~/components/Spinner';
import {
    MasterData,
    MasterDataList
} from '~/types/services/masterdata-service';
import SearchableTree, { TreeInterface } from '~/components/SearchableTree';
import MasterDataEditPage from '~/pages/MasterData/Edit';
import i18n from '~/shared/utils/i18n';

const masterDataService = new MasterDataService();

const masterDataExport = (group): void => {
    const filter = { master_data_id: group.id };
    masterDataService.masterDataExport(filter);
};

const masterDataExportButton = (group): JSX.Element => {
    return (
        <span>
            <span>{group.label}</span>
            <Tooltip title={i18n.t('basic.exportButton')}>
                <ExportOutlined
                    onClick={(): void => {
                        masterDataExport(group);
                    }}
                    style={{
                        cursor: 'pointer',
                        padding: '0px 5px'
                    }}
                />
            </Tooltip>
        </span>
    );
};

const transformMasterDataForTree = (
    data: MasterDataList,
    handleEdit: (masterDataId: number) => void
): TreeInterface[] => {
    const tree: TreeInterface[] = [];
    // eslint-disable-next-line array-callback-return
    data.groups.map(group => {
        if (
            tree[tree.length - 1] &&
            tree[tree.length - 1].key === group.module
        ) {
            tree[tree.length - 1].children.push({
                title: masterDataExportButton(group),
                planTitle: group.label,
                key: group.type,
                children: []
            });
        } else {
            const treeItem = {
                title: group.module_label,
                planTitle: group.module_label,
                key: group.module,
                children: [
                    {
                        title: masterDataExportButton(group),
                        planTitle: group.label,
                        key: group.type,
                        children: []
                    }
                ]
            };
            tree.push(treeItem);
        }
        data.data
            .filter((item: MasterData) => item.master_data_id === group.id)
            // eslint-disable-next-line array-callback-return
            .map((masterData: MasterData) => {
                const lastTreeItem = tree[tree.length - 1];
                lastTreeItem.children[
                    lastTreeItem.children.length - 1
                ].children.push({
                    planTitle: masterData.label,
                    title: (
                        <span>
                            <span>{masterData.label}</span>
                            <Tooltip
                                title={i18n.t('pages.masterData.edit.title')}
                            >
                                <FormOutlined
                                    onClick={(): void => {
                                        handleEdit(masterData.id);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: '0px 5px'
                                    }}
                                />
                            </Tooltip>
                        </span>
                    ),
                    key: masterData.id
                });
            });
    });
    return tree;
};

const MasterDataListPage: React.FC = () => {
    const { t } = useTranslation();
    const [showMasterData, setShowMasterData] = useState(0);

    const handleEdit = (selectedKeys): void => {
        setShowMasterData(selectedKeys);
    };

    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.MasterData
    });

    const [masterData, setMasterData] = useState<TreeInterface[]>();

    useEffect(() => {
        if (showMasterData !== 0) return;
        masterDataService.fetchMasterDatas().then(res => {
            setMasterData(null);
            setMasterData(transformMasterDataForTree(res.data, handleEdit));
        });
    }, [showMasterData]);

    return (
        <>
            <Typography.Title level={1}>
                {t('pages.masterData.list.title')}
            </Typography.Title>
            {promiseInProgress && <Spinner />}
            {!promiseInProgress && masterData && (
                <SearchableTree treeData={masterData} />
            )}
            <Drawer
                title={t('pages.masterData.edit.title')}
                placement="right"
                closable
                onClose={(): void => setShowMasterData(0)}
                visible={!!showMasterData}
                width={320}
            >
                <MasterDataEditPage
                    masterDataId={showMasterData}
                    handleClose={(): void => setShowMasterData(0)}
                />
            </Drawer>
        </>
    );
};

export default MasterDataListPage;
