import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LabeledValue, SelectValue } from 'antd/es/select';

const { Option } = Select;

interface AjaxSelectProps {
    id?: string;
    disabled?: boolean;
    multiple?: boolean;
    loading?: boolean;
    placeholder?: string;
    onSearch: (value) => Promise<LabeledValue[]>;
    onSelect?: Function;
    onDeselect?: Function;
    onChange?: Function;
    initialValues?: SelectValue;
    style?: object;
    allowClear?: boolean;
}

const AjaxSelect: React.FC<AjaxSelectProps> = ({
    id,
    disabled,
    multiple = true,
    loading,
    placeholder,
    onSearch,
    onDeselect,
    onSelect,
    onChange,
    initialValues,
    style,
    allowClear
}) => {
    const { promiseInProgress } = usePromiseTracker();
    const [data, setData] = useState<LabeledValue[]>([]);
    const fetchData = (inputVal = ''): void => {
        trackPromise(
            onSearch(inputVal).then(result => {
                setData(result);
            })
        );
    };
    const delayedFetchData = useCallback(
        debounce(inputVal => fetchData(inputVal), 800),
        []
    );

    useEffect(() => {
        return (): void => {
            delayedFetchData.cancel();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (selectedValues): void => {
        if (!onChange) return;
        onChange(selectedValues);
    };

    const handleSelect = (selectedValue): void => {
        if (!onSelect) return;
        onSelect(selectedValue);
    };

    const handleDeselect = (selectedValue): void => {
        if (!onDeselect) return;
        onDeselect(selectedValue);
    };

    const mode = multiple ? 'multiple' : null;

    return (
        <Select
            disabled={disabled}
            virtual={false}
            dropdownClassName={`${id}-dropdown`}
            removeIcon={null}
            loading={loading || promiseInProgress}
            data-testid={id}
            mode={mode}
            labelInValue
            showSearch
            placeholder={placeholder}
            defaultValue={initialValues}
            notFoundContent={
                loading || promiseInProgress ? <Spin size="small" /> : null
            }
            filterOption={false}
            onSearch={(inputVal): void => delayedFetchData(inputVal)}
            onBlur={(): void => setData([])}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onChange={handleChange}
            onFocus={(): void => fetchData()}
            style={style}
            allowClear={!!allowClear}
        >
            {data.map(d => (
                <Option value={d.value} key={d.value}>
                    {d.label}
                </Option>
            ))}
        </Select>
    );
};

export default AjaxSelect;
