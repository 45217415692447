import { Form, Select } from 'formik-antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserStatusEnums from '~/types/shared/user-status-enums';
import { User } from '~/types/services/user-service';
import { FeatureContext } from '~/shared/context/feature';

interface StatusSelectorProps {
    user: User;
}

const StatusSelector: React.FC<StatusSelectorProps> = ({ user }) => {
    const { t } = useTranslation();

    const { isFeatureEnabled } = useContext(FeatureContext);

    return (
        <Form.Item name="status" label={t('pages.users.fields.status')}>
            <Select
                name="status"
                placeholder={t('basic.pleaseSelect')}
                defaultValue={[0]}
            >
                <Select.Option value={UserStatusEnums.Active}>
                    {t('pages.users.list.statusActive')}
                </Select.Option>
                <Select.Option value={UserStatusEnums.Inactive}>
                    {t('pages.users.list.statusInactive')}
                </Select.Option>
                {(!isFeatureEnabled('auth.invite-only') ||
                    (user && user.status === UserStatusEnums.New)) && (
                    <Select.Option
                        value={UserStatusEnums.New}
                        disabled={!!user}
                    >
                        {t('pages.users.list.statusNew')}
                    </Select.Option>
                )}
                {(!isFeatureEnabled('auth.invite-only') ||
                    (user && user.status === UserStatusEnums.Waiting)) && (
                    <Select.Option
                        value={UserStatusEnums.Waiting}
                        disabled={!!user}
                    >
                        {t('pages.users.list.statusWaiting')}
                    </Select.Option>
                )}
                {(isFeatureEnabled('auth.invite-only') ||
                    (user && user.status === UserStatusEnums.Pending)) && (
                    <Select.Option
                        value={UserStatusEnums.Pending}
                        disabled={!!user}
                    >
                        {t('pages.users.list.statusPending')}
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

export default StatusSelector;
