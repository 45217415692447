import { usePromiseTracker } from 'react-promise-tracker';
import { Form, Switch } from 'antd';
import { AxiosPromise } from 'axios';
import React from 'react';
import { DetailedFeature } from '~/types/services/feature-service';

interface FeatureToggleProps {
    feature: DetailedFeature;
    toggleFeature: Function;
}

const FeatureToggle: React.FC<FeatureToggleProps> = ({
    feature,
    toggleFeature
}) => {
    const { promiseInProgress } = usePromiseTracker({
        area: `feature-toggle-${feature.id}`
    });

    return (
        <Form.Item
            label={feature.title}
            extra={feature.description}
            key={feature.id}
        >
            <Switch
                data-testid={`${feature.id}-switch`}
                defaultChecked={feature.active}
                loading={promiseInProgress}
                onChange={(checked): AxiosPromise =>
                    toggleFeature(
                        checked,
                        feature.id,
                        `feature-toggle-${feature.id}`
                    )
                }
            />
        </Form.Item>
    );
};

export default FeatureToggle;
