import React from 'react';
import { Form, Input } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField, TextAreaMeta } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderTextProps {
    field: FormBuilderField;
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderTextArea: React.FC<BuilderTextProps> = ({
    field,
    onChange,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);

    const meta = field.meta as TextAreaMeta;
    const size = { minRows: meta.minRows || 4, maxRows: meta.maxRows || 12 };
    return (
        <Form.Item
            htmlFor={fieldName}
            name={fieldName}
            label={field.label}
            required={field.required}
            key={field.id}
        >
            <Input.TextArea
                id={fieldName}
                autoSize={size}
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={get(getFieldMeta(fieldName), 'value')}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                onKeyDown={(event): void => {
                    if (!meta.allowedEnter && event.key === 'Enter') {
                        event.preventDefault();
                    }
                }}
            />
        </Form.Item>
    );
};

export default BuilderTextArea;
